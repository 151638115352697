import React from 'react';
import qs from 'qs';
import config from 'config';
import { Helmet } from 'react-helmet';
import MetaTags from 'react-meta-tags';
import { withToastManager } from 'react-toast-notifications';
import { Flex, Box, Button } from 'rebass';
import { Label, Input } from '@rebass/forms';
import styled from 'styled-components';
import services from 'services/services';
import studies_service from 'services/studies';
import people_service from 'services/people';
import { FiPrinter, FiFileText } from 'react-icons/fi';
import helpers from 'utils/helpers';
import helpersScreener from 'utils/helpers-screener.js';
import NiceModal from 'components/NiceModal';

import { MarketingPageWrapper, MarketingPageSection } from 'components/Marketing';
import { ReactFormGenerator } from 'components/ReactFormBuilder';

import LoadingWrapper from 'components/LoadingIndicator/LoadingWrapper';
import LoadingIndicator from 'components/LoadingIndicator';

import PublicScreenPageAdminTools from 'containers/PublicScreenPage/components/PublicScreenPageAdminTools';
import PublicScreenPageFormWrapper from 'containers/PublicScreenPage/components/PublicScreenPageFormWrapper';
import PublicScreenPagePreviewIndicator from 'containers/PublicScreenPage/components/PublicScreenPagePreviewIndicator';

import { isMobile } from 'react-device-detect';
import store from 'store';
import { Beforeunload } from 'react-beforeunload';

import { SCREENER_INTERNAL_ONLY, SCREENER_STUDY_ONLY, SCREENER_ANYONE } from 'utils/constants';

const CONSTANT_BROWSER_IDENTIFIER = 'pfxbrind';

const PublicScreenPageWrapper = styled(Box)`
    width: 100%;
    min-height: 100vh;
    background: #f6f6f6;
`;

class PublicScreenPage extends React.Component {
    constructor(props) {
        // create browser token to identify person
        let respondent_browser_identifier;

        try {
            respondent_browser_identifier = store.get(CONSTANT_BROWSER_IDENTIFIER);
            if (!respondent_browser_identifier) {
                respondent_browser_identifier = helpers.generateUUID();
                store.set(CONSTANT_BROWSER_IDENTIFIER, respondent_browser_identifier);
            }
            //console.log('respondent_browser_identifier', respondent_browser_identifier)
        } catch (e) {
            helpers.trackError(e);
        }

        let screen_type;
        if (
            props.match.path == '/panel-profile/:account_hash/:panel_form_id' ||
            props.match.path == '/p/:panel_signup_custom_slug'
        ) {
            screen_type = 'panel';
        } else if (props.match.path == '/screen/:hash') {
            screen_type = 'study';
        } else if (props.match.path == '/study-screen/:screen_uuid' || props.match.path == '/s/:screen_uuid') {
            screen_type = 'study-screen';
        }

        super(props);

        const queryParams = qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true
        });

        let is_preview = parseInt(queryParams.is_preview) ? 1 : 0;
        let is_preview_start_page = 1;
        if (is_preview) {
            const url_is_preview_start_page = parseInt(queryParams.is_preview_start_page);
            if (url_is_preview_start_page) {
                is_preview_start_page = url_is_preview_start_page;
            }
        }

        //console.log('is_preview', is_preview)

        let view_as_one_page = parseInt(queryParams.view_as_one_page) ? 1 : 0;
        //console.log('queryParams', queryParams, view_as_one_page)

        this.state = {
            account: {},
            screener: [],
            screener_translation: {},
            participant_schedule: null,
            study_person_id: new URLSearchParams(this.props.location.search).get('study_person_id'),
            sp_uuid: new URLSearchParams(this.props.location.search).get('sp_uuid'),
            is_preview: is_preview,
            view_as_one_page: view_as_one_page,
            is_preview_start_page: is_preview_start_page,
            result_text: null,
            isLoadingScreener: true,
            screen_type: screen_type,
            is_submitting: false,
            study_person_response: {},
            study_person: {},
            participant_external: null,
            page_logic: [],
            verify_email: false,
            allow_view_screener: true,
            ui_should_verify_email: false,
            study_person_email: null,
            public_title: '',
            public_description: '',
            qualtrics_survey_id: null,
            account_hash: this.props.match.params.account_hash,
            panel_form_id: this.props.match.params.panel_form_id,
            showConsentFormModal: false,
            is_submitting_consent: false,
            consentForm: {}
        };

        this.onSubmitForm = this.onSubmitForm.bind(this);
        this.onChangeForm = this.onChangeForm.bind(this);
        this.onChangeWithTimeout = this.onChangeWithTimeout.bind(this);
        this.isAdminView = this.isAdminView.bind(this);
        this.authUserIsPartOfAccount = this.authUserIsPartOfAccount.bind(this);
        this.getAnswerData = this.getAnswerData.bind(this);
        this.screenerAnswersExist = this.screenerAnswersExist.bind(this);
        this.onSubmitForm_consent = this.onSubmitForm_consent.bind(this);
        this.handleCloseConsentForm = this.handleCloseConsentForm.bind(this);
    }

    screenerAnswersExist() {
        const spr = this.state.study_person_response;
        if (spr && spr.id && spr.answers) {
            return true;
        }
        return false;
    }

    authUserIsPartOfAccount(account) {
        return this.props.auth &&
            this.props.auth.user &&
            this.props.auth.user.accounts &&
            this.props.auth.user.accounts.find(a => a.uuid == account.uuid)
            ? true
            : false;
    }

    getAnswerData() {
        let answer_data = {};
        //console.log('getAnswerData')
        if (this.authUserIsPartOfAccount(this.state.account)) {
            //console.log('getAnswerData - PART OF ACCT')
            const spr = this.state.study_person_response;
            if (spr && spr.id && spr.answers) {
                answer_data = JSON.parse(spr.answers);
                //console.log('answer_data', answer_data)
            }
        }
        return answer_data;
    }

    loadAddpipe() {
        console.log('loading addpipe!!!');

        // Create new script element
        const script = document.createElement('script');
        script.src = 'https://cdn.addpipe.com/2.0/pipe.js';
        document.head.appendChild(script);

        const css = document.createElement('link');
        css.rel = 'stylesheet';
        css.type = 'text/css';
        css.href = 'https://cdn.addpipe.com/2.0/pipe.css';
        document.head.appendChild(css);
    }

    shouldLoadAddpipe(screener_pages) {
        //console.log('aaaaddpipe', screener_pages);
        let should_load = false;
        if (screener_pages) {
            screener_pages.forEach(page_questions => {
                if (page_questions) {
                    page_questions.forEach(question => {
                        if (question && question.element == 'AddPipeInput') {
                            should_load = true;
                        }
                    });
                }
            });
        }

        if (should_load) {
            console.log('should load addpipe');
            this.loadAddpipe();
        }
    }

    componentDidMount() {
        const { toastManager } = this.props;

        if (this.state.screen_type == 'study') {
            /*
                    THIS IS ALMOST NEVER USED - ITS AND OLD FLOW
            */
            services
                .getScreener(this.props.match.params.hash, this.state.is_preview)
                .then(res_data => {
                    const { account, screener, screener_translation, participant_schedule } = res_data;

                    this.setState({
                        account,
                        screener,
                        screener_translation,
                        participant_schedule,
                        isLoadingScreener: false
                    });

                    // set title
                })
                .catch(error => {
                    const errorText = services.parseAndTrackXhrErrors(error);

                    this.setState({ isLoadingScreener: false });

                    toastManager.add(errorText, {
                        appearance: 'error',
                        autoDismiss: false
                    });
                });
        } else if (this.state.screen_type == 'study-screen') {
            studies_service
                .getScreenerPublic(this.props.match.params.screen_uuid, this.state.is_preview, this.state.sp_uuid)
                .then(res_data => {
                    const {
                        account,
                        form,
                        screener_translation,
                        participant_schedule,
                        study_person_response,
                        study_person,
                        participant_external,
                        page_logic,
                        verify_email,
                        public_title,
                        public_description,
                        gtag_id,
                        qualtrics_survey_id
                    } = res_data;

                    const screener = form;

                    this.setState(
                        {
                            account,
                            account_hash: account.uuid,
                            screener,
                            screener_translation,
                            participant_schedule,
                            study_person_response,
                            isLoadingScreener: false,
                            study_person,
                            participant_external,
                            page_logic,
                            verify_email,
                            public_title,
                            public_description,
                            qualtrics_survey_id
                        },
                        () => {
                            if (res_data.error) {
                                this.setState({ result_text: res_data.error });
                            }

                            // there was an answer to the screener already
                            if (study_person_response && study_person_response.id) {
                                // if logged in person is an admin, and the "view answers" flag is turned on
                                //console.log(this.props.auth.user.accounts);
                                if (this.authUserIsPartOfAccount(account)) {
                                    //console.log('OKKKK!')
                                } else {
                                    if (study_person_response.incomplete == 1) {
                                        // allow this
                                    } else {
                                        this.setState({ result_text: 'You have already taken this screener.' });
                                    }
                                }
                            }

                            // check if screener contains AddPipe element, and load file
                            this.shouldLoadAddpipe(screener);

                            // if this screener has not yet been answered
                            try {
                                if (!this.screenerAnswersExist()) {
                                    // this is the section that checks if we should ask respondent to "verify email"
                                    if (this.checkIfShouldVerifyEmail()) {
                                        let study_person_email = helpers
                                            .personGetEmail(this.state.study_person.person)
                                            .toLowerCase();

                                        if (study_person_email) {
                                            this.setState({
                                                ui_should_verify_email: true,
                                                study_person_email: study_person_email,
                                                allow_view_screener: false
                                            });
                                        }
                                    }
                                }
                            } catch (e) {
                                helpers.trackError(e);
                            }

                            /*if (Array.isArray(custom_js) && !!custom_js[0] && custom_js[0].constructor === Object) {
                            this.customJsRunner(custom_js[0].execute);
                        }*/
                            if (gtag_id) {
                                this.insertGoogleAnalyticsTag(gtag_id);
                            }
                        }
                    );
                })
                .catch(error => {
                    const errorText = services.parseAndTrackXhrErrors(error);

                    //console.log(error.account);

                    // set account if it's sent back, for page brannding
                    if (
                        error &&
                        error.response &&
                        error.response.data &&
                        error.response.data.account &&
                        error.response.data.account.uuid
                    ) {
                        this.setState({ account: error.response.data.account });
                    }

                    this.setState({
                        isLoadingScreener: false,
                        result_text: errorText
                    });
                });
        } else if (this.state.screen_type == 'panel') {
            let psXHR;
            let usingSignupSlug = false;
            if (this.props.match.params.panel_signup_custom_slug) {
                usingSignupSlug = true;
                psXHR = people_service.getPanelSignupPublicWithSlug(
                    this.props.match.params.panel_signup_custom_slug,
                    this.state.is_preview
                );
            } else {
                psXHR = people_service.getPanelSignupPublic(
                    this.state.account_hash,
                    this.state.panel_form_id,
                    this.state.is_preview
                );
            }

            psXHR
                .then(res_data => {
                    const { account, screener, screener_translation, page_logic, id, gtag_id } = res_data;

                    this.setState({
                        account,
                        screener,
                        screener_translation,
                        page_logic,
                        isLoadingScreener: false
                    });

                    if (usingSignupSlug) {
                        this.setState({
                            account_hash: account.uuid,
                            panel_form_id: id
                        });
                    }

                    /*if (Array.isArray(custom_js) && !!custom_js[0] && custom_js[0].constructor === Object) {
                        this.customJsRunner(custom_js[0].execute);
                    }*/
                    if (gtag_id) {
                        this.insertGoogleAnalyticsTag(gtag_id);
                    }
                })
                .catch(error => {
                    const errorText = services.parseAndTrackXhrErrors(error);

                    this.setState({ isLoadingScreener: false, result_text: errorText });
                });
        }
    }

    displayQualification(sp_uuid) {
        const { toastManager } = this.props;

        const dq_text = this.state.screener_translation.confirmation_page_text
            ? this.state.screener_translation.confirmation_page_text
            : 'Thank you!';

        this.setState({ result_text: dq_text }, () => {
            if (this.state.participant_schedule == 'automatic') {
                // check if user can automatically schedule themselves
                if (this.state.screen_type == 'study') {
                    services
                        .getStudyAvailabilityLink(this.props.match.params.hash, sp_uuid)
                        .then(link => {
                            window.location = link + (this.state.is_preview ? '&preview=1' : '');
                        })
                        .catch(error => {
                            const errorText = services.parseAndTrackXhrErrors(error);

                            toastManager.add(errorText, {
                                appearance: 'error',
                                autoDismiss: true
                            });
                        });
                } else if (this.state.screen_type == 'study-screen') {
                    studies_service
                        .getStudyAvailabilityLinkViaScreener(this.props.match.params.screen_uuid, sp_uuid)
                        .then(link => {
                            window.location = link + (this.state.is_preview ? '&is_preview=1' : '');
                        })
                        .catch(error => {
                            const errorText = services.parseAndTrackXhrErrors(error);

                            toastManager.add(errorText, {
                                appearance: 'error',
                                autoDismiss: true
                            });
                        });
                }
            } else {
                //console.log("qualification redirect?", this.state.screener_translation);
                if (
                    this.state.screener_translation.confirmation_redirect &&
                    this.state.screener_translation.confirmation_redirect_url &&
                    helpers.isValidUrl(this.state.screener_translation.confirmation_redirect_url)
                ) {
                    helpers.redirectAfterPause(this.state.screener_translation.confirmation_redirect_url);
                }
            }
        });
    }

    displayDisqualification() {
        const q_text = this.state.screener_translation.disqualification_page_text
            ? this.state.screener_translation.disqualification_page_text
            : 'Thank you.';
        this.setState({ result_text: q_text });

        //console.log("dis-qualification redirect?", this.state.screener_translation);
        if (
            this.state.screener_translation.disqualification_redirect &&
            this.state.screener_translation.disqualification_redirect_url &&
            helpers.isValidUrl(this.state.screener_translation.disqualification_redirect_url)
        ) {
            helpers.redirectAfterPause(this.state.screener_translation.disqualification_redirect_url);
        }
    }

    displayCustomResultText(custom_text) {
        this.setState({ result_text: custom_text });
    }

    onChangeForm(child_state) {
        //console.log('onchange form');
    }

    // ui_action = change, next
    onChangeWithTimeout(child_state, formAnswers, ui_action) {
        //console.log('onChangeWithTimeout', child_state, formAnswers);
        const isQualified = 'unknown';

        if (this.state.screen_type == 'study-screen' && !this.state.is_preview) {
            const is_partial = 1;
            services
                .submitScreenerForm(
                    this.state.screen_type,
                    this.props.match.params.screen_uuid,
                    this.state.study_person_id,
                    formAnswers,
                    isQualified,
                    this.state.sp_uuid,
                    is_partial,
                    ui_action
                )
                .then(partialResponse => {
                    console.log('saved partial', partialResponse);
                })
                .catch(error => {
                    if (error && error.response && error.response.status == 400) {
                        // skip
                        console.log('400 http in partial');
                        console.log('should dq???');
                        if (ui_action == 'next' && error.response.data.dq) {
                            console.log('should dq - yes');
                            // display DQ
                            const errorText = services.parseAndTrackXhrErrors(error);
                            this.displayCustomResultText(errorText);
                            // early return so the toast doesnt show
                            return;
                        }
                    } else {
                        helpers.trackError(error, { details: 'error in partial' });
                    }
                });
        }
    }

    showConsentForm(consentForm) {
        this.setState({
            is_submitting_consent: false,
            showConsentFormModal: true,
            consentForm: consentForm
        });
    }

    handleCloseConsentForm() {
        this.setState({
            is_submitting: false,
            showConsentFormModal: false
        });
    }

    onSubmitForm_consent(formAnswers, isQualified) {
        console.log('onSubmitForm_consent', formAnswers, isQualified);
        this.setState({
            is_submitting_consent: true
        });

        const { toastManager } = this.props;

        services
            // note: there are parameters missing from the method call below (on purpose)
            .submitScreenerForm('panel', this.state.account_hash, this.state.consentForm.id, formAnswers, isQualified)
            .then(person => {
                this.setState({
                    is_submitting_consent: false,
                    showConsentFormModal: false
                });

                this.onSubmitForm(this.state.formAnswers, this.state.isQualified);

                if (isQualified) {
                    //this.displayQualification();
                } else {
                    //this.displayDisqualification();
                }
            })
            .catch(error => {
                helpers.trackError(error);

                this.setState({ is_submitting: false });

                const errorText = services.parseAndTrackXhrErrors(error);
                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    onSubmitForm(formAnswers, isQualified) {
        //console.log('isQualified', isQualified)
        if (this.state.is_preview) {
            //console.log('formAnswers', formAnswers)
            if (isQualified) {
                this.displayQualification();
            } else {
                this.displayDisqualification();
            }
        } else {
            const { toastManager } = this.props;

            this.setState({
                is_submitting: true,
                formAnswers,
                isQualified
            });

            if (this.state.screen_type == 'study') {
                services
                    // note: there are parameters missing from the method call below (on purpose)
                    .submitScreenerForm(
                        this.state.screen_type,
                        this.props.match.params.hash,
                        this.state.study_person_id,
                        formAnswers,
                        isQualified
                    )
                    .then(studyPerson => {
                        if (isQualified) {
                            let { sp_uuid } = this.state;
                            if (!sp_uuid) {
                                sp_uuid = studyPerson.uuid;
                            }
                            this.displayQualification(sp_uuid);
                        } else {
                            this.displayDisqualification();
                        }
                    })
                    .catch(error => {
                        helpers.trackError(error);

                        this.setState({ is_submitting: false });

                        const errorText = services.parseAndTrackXhrErrors(error);
                        toastManager.add(errorText, {
                            appearance: 'error',
                            autoDismiss: true
                        });
                    });
            } else if (this.state.screen_type == 'study-screen') {
                services
                    // note: there are parameters missing from the method call below (on purpose)
                    .submitScreenerForm(
                        this.state.screen_type,
                        this.props.match.params.screen_uuid,
                        this.state.study_person_id,
                        formAnswers,
                        isQualified,
                        this.state.sp_uuid
                    )
                    .then(studyPerson => {
                        if (isQualified) {
                            let { sp_uuid } = this.state;
                            if (!sp_uuid) {
                                sp_uuid = studyPerson.uuid;
                            }
                            this.displayQualification(sp_uuid);
                        } else {
                            this.displayDisqualification();
                        }
                    })
                    .catch(error => {
                        //console.log(error.message);

                        if (error.response.data && error.response.data.missing_consent_forms) {
                            this.showConsentForm(error.response.data.missing_consent_forms[0]);
                            return;
                        }

                        const errorText = services.parseAndTrackXhrErrors(error);

                        if (error && error.response && error.response.data) {
                            if (error.response.status == 400) {
                                if (error.response.data.dq) {
                                    // display DQ
                                    this.displayCustomResultText(errorText);
                                    // early return so the toast doesnt show
                                    return;
                                } else {
                                    // skip this
                                    console.log('skipping 400');
                                }
                            } else {
                                const api_response = JSON.stringify(error.response.data);
                                helpers.trackError(api_response);
                            }
                        } else {
                            helpers.trackError(error);
                        }

                        this.setState({ is_submitting: false });

                        toastManager.add(errorText, {
                            appearance: 'error',
                            autoDismiss: true
                        });
                    });
            } else if (this.state.screen_type == 'panel') {
                services
                    // note: there are parameters missing from the method call below (on purpose)
                    .submitScreenerForm(
                        this.state.screen_type,
                        this.state.account_hash,
                        this.state.panel_form_id,
                        formAnswers,
                        isQualified
                    )
                    .then(person => {
                        if (isQualified) {
                            this.displayQualification();
                        } else {
                            this.displayDisqualification();
                        }
                    })
                    .catch(error => {
                        if (error.response.data && error.response.data.missing_consent_forms) {
                            this.showConsentForm(error.response.data.missing_consent_forms[0]);
                            return;
                        }

                        helpers.trackError(error);

                        this.setState({ is_submitting: false });

                        const errorText = services.parseAndTrackXhrErrors(error);
                        toastManager.add(errorText, {
                            appearance: 'error',
                            autoDismiss: true
                        });
                    });
            }
        }
    }

    isAdminView() {
        return this.state.participant_external !== null && this.state.participant_external == SCREENER_INTERNAL_ONLY;
    }

    checkIfShouldVerifyEmail() {
        // if "anyone" or "participant only"
        // if verify_email == 1
        // if study person id is set

        if (
            [SCREENER_ANYONE, SCREENER_STUDY_ONLY].includes(this.state.participant_external) &&
            this.state.verify_email == true &&
            this.state.study_person &&
            this.state.study_person.person
        ) {
            return true;
        }

        return false;
    }

    getPageTitle() {
        // try to get screener title
        if (this.state.public_title) {
            return this.state.public_title;
        } else {
            return this.state.account && this.state.account.title ? this.state.account.title : 'Survey';
        }
    }

    getPageDescription() {
        // try to get screener title
        if (this.state.public_description) {
            return this.state.public_description;
        } else {
            return 'Please take this survey.';
        }
    }

    getPageImage() {
        return this.state.account && this.state.account.logo ? this.state.account.logo : null;
    }

    componentDidCatch(error, errorInfo) {
        helpers.trackError(error);
    }

    shouldDisplayAsOnePage() {
        if (this.state.view_as_one_page == true) {
            return true;
        } else {
            false;
        }
    }

    getLinkToDisplayAsOnePage() {
        var url = new URL(location.href);
        url.searchParams.set('view_as_one_page', 1);
        return url.href;
    }

    getLinkToDisplayAsMultiplePages() {
        var url = new URL(location.href);
        url.searchParams.set('view_as_one_page', 0);
        return url.href;
    }

    insertGoogleAnalyticsTag(gtag_id) {
        try {
            if (gtag_id) {
                console.log('Panelfox - loading GA Tag');
                let newScript = document.createElement('script');
                newScript.type = 'text/javascript';
                newScript.setAttribute('async', 'true');
                newScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=' + gtag_id);
                document.documentElement.firstChild.appendChild(newScript);

                window.dataLayer = window.dataLayer || [];
                // eslint-disable-next-line no-inner-declarations
                function gtag() {
                    dataLayer.push(arguments);
                }
                gtag('js', new Date());
                gtag('config', gtag_id);
            }
        } catch (e) {
            helpers.trackError(e, { details: 'Panelfox - error executing custom javascript.' });
        }
    }

    customJsRunner(obj) {
        try {
            if (obj) {
                console.log('Panelfox - executing custom javascript');
                return eval(`
                    const customHtml = document.createRange().createContextualFragment(\`${obj}\`);
                    document.body.append(customHtml);
                `);
            }
            console.log('Panelfox - custom javascript empty');
        } catch (e) {
            if (this.state.is_preview === 1) {
                helpers.trackError(e, {
                    details: 'Panelfox - error executing custom javascript. See stack trace below.'
                });
            } else {
                helpers.trackError(e, { details: 'Panelfox - error executing custom javascript.' });
            }
        }
    }

    render() {
        if (this.state.qualtrics_survey_id) {
            // redirect to external qualtrics survey
            const redirectUrl =
                this.state.study_person_response && this.state.study_person_response.id
                    ? helpersScreener.getExternalScreenerResponsesLink(this.state)
                    : helpersScreener.getScreenerCustomLink(this.state, this.state.account);
            if (redirectUrl) {
                window.location.href = redirectUrl;
                return <></>;
            }
        }

        // check if screener has any data
        let screener_has_data = false;
        if (this.state.screener && this.state.screener.length) {
            this.state.screener.forEach(page => {
                page.forEach(() => {
                    screener_has_data = true;
                });
            });
        }

        return (
            <PublicScreenPageWrapper>
                <Box px={2}>
                    <Helmet titleTemplate="%s">
                        <title>{this.getPageTitle()}</title>
                        {helpers.renderFavicon(this.state.account)}
                    </Helmet>
                    <MetaTags>
                        <meta property="og:site_name" content={helpers.getAccountTitleForOgTag(this.state.account)} />
                        <meta property="og:title" content={this.getPageTitle()} />
                        <meta property="og:description" content={this.getPageDescription()} />
                        <meta property="og:url" content={location.href} />
                        {this.getPageImage() != null && <meta property="og:image" content={this.getPageImage()} />}
                    </MetaTags>

                    {this.state.isLoadingScreener ? (
                        <></>
                    ) : (
                        <>
                            {/*<Beforeunload onBeforeunload={() => {
                                if (this.state.is_preview == 1) {
                                    return false;
                                } else {
                                    return 'Are you sure you want to exit? The information you’ve entered was not saved.';
                                }
                            }} />*/}

                            {this.state.is_preview == 1 && this.state.screener && this.state.screener.length > 0 && (
                                <PublicScreenPagePreviewIndicator />
                            )}

                            {this.state.account.logo ? (
                                <Box textAlign="center" my="40px">
                                    <img
                                        src={this.state.account.logo}
                                        style={{
                                            maxWidth: '100%',
                                            maxHeight: helpers.getPanelistLogoHeight(this.state.account)
                                        }}
                                        alt="Account Logo"
                                    />
                                </Box>
                            ) : (
                                <Box mb="40px" />
                            )}

                            {this.authUserIsPartOfAccount(this.state.account) && (
                                <PublicScreenPageAdminTools>
                                    {(this.screenerAnswersExist() || this.shouldDisplayAsOnePage()) && (
                                        <Button
                                            type="button"
                                            variant="secondary-gray"
                                            onClick={e => window.print()}
                                            mr={0}
                                        >
                                            <FiPrinter /> Print
                                        </Button>
                                    )}
                                    {!this.screenerAnswersExist() && (
                                        <>
                                            {this.shouldDisplayAsOnePage() ? (
                                                <Box mt={3} className="fs-body-14 color-text-secondary">
                                                    Displaying as printable page.{' '}
                                                    <a
                                                        href="#"
                                                        onClick={e =>
                                                            (window.location = this.getLinkToDisplayAsMultiplePages())
                                                        }
                                                    >
                                                        Reset
                                                    </a>
                                                </Box>
                                            ) : (
                                                <Button
                                                    type="button"
                                                    variant="secondary-gray"
                                                    onClick={e => (window.location = this.getLinkToDisplayAsOnePage())}
                                                    mr={0}
                                                >
                                                    <FiFileText /> View Printable Page
                                                </Button>
                                            )}
                                        </>
                                    )}
                                </PublicScreenPageAdminTools>
                            )}

                            <PublicScreenPageFormWrapper screener_bg={this.state.account.screener_bg}>
                                {this.isAdminView() && (
                                    <Box
                                        sx={{ padding: '20px', margin: '20px 0 10px 0', borderRadius: '8px' }}
                                        className="fs-title-14 color-warning-dark color-bg-warning-light"
                                    >
                                        This is an internal-only (or admin) view.
                                    </Box>
                                )}

                                {this.state.ui_should_verify_email && !this.state.allow_view_screener && (
                                    <Box>
                                        <br />
                                        <br />
                                        <Label fontSize={2} sx={{ color: '#000' }} mb={2}>
                                            Please verify your email to proceed (
                                            {helpers.obscureEmail(this.state.study_person_email)})
                                        </Label>
                                        <Input
                                            value={this.state.user_input_email}
                                            placeholder="Email"
                                            autoFocus
                                            onChange={e => {
                                                let val = e.target.value;
                                                val = val.toLowerCase();
                                                this.setState({ user_input_email: val });
                                                if (val == this.state.study_person_email) {
                                                    this.setState({ allow_view_screener: true });
                                                }
                                            }}
                                        />
                                        {SCREENER_ANYONE == this.state.participant_external && (
                                            <Box className="fs-13" mt={4}>
                                                This is not me,{' '}
                                                <a
                                                    href={location.protocol + '//' + location.host + location.pathname}
                                                    className="muted"
                                                >
                                                    <u>start fresh</u>
                                                </a>
                                                .
                                            </Box>
                                        )}
                                    </Box>
                                )}

                                {this.state.screener && this.state.allow_view_screener && !this.state.result_text && (
                                    <>
                                        <ReactFormGenerator
                                            // validateForCorrectness={true}
                                            // form_method="POST"
                                            // task_id={12} // Used to submit a hidden variable with the id to the form from the database.
                                            // authenticity_token={AUTH_TOKEN} // If using Rails and need an auth token to submit form.
                                            answer_data={this.getAnswerData()}
                                            read_only={
                                                this.authUserIsPartOfAccount(this.state.account) &&
                                                (this.screenerAnswersExist() || this.shouldDisplayAsOnePage())
                                            }
                                            hide_actions={
                                                this.authUserIsPartOfAccount(this.state.account) &&
                                                (this.screenerAnswersExist() || this.shouldDisplayAsOnePage())
                                            }
                                            is_preview={this.state.is_preview}
                                            is_preview_start_page={this.state.is_preview_start_page}
                                            data={this.state.screener} // Question data
                                            translation={this.state.screener_translation}
                                            branding={this.state.account.branding}
                                            addpipe_token={this.state.account.addpipe_token}
                                            addpipe_eid={this.state.account.addpipe_eid}
                                            page_logic={this.state.page_logic}
                                            person={this.state.study_person ? this.state.study_person.person : null} // Question data
                                            person_defaults={
                                                this.state.study_person && this.state.study_person.person
                                                    ? this.state.study_person.person.custom_data_values2
                                                    : null
                                            } // Question data
                                            action_name={
                                                this.state.screener_translation &&
                                                this.state.screener_translation.submit_button_text
                                                    ? this.state.screener_translation.submit_button_text
                                                    : ''
                                            }
                                            next_name={
                                                this.state.screener_translation &&
                                                this.state.screener_translation.next_button_text
                                                    ? this.state.screener_translation.next_button_text
                                                    : ''
                                            }
                                            back_name={
                                                this.state.screener_translation &&
                                                this.state.screener_translation.cancel_button_text
                                                    ? this.state.screener_translation.cancel_button_text
                                                    : ''
                                            }
                                            is_required_name={
                                                this.state.screener_translation &&
                                                this.state.screener_translation.is_required_text
                                                    ? this.state.screener_translation.is_required_text
                                                    : ''
                                            }
                                            dismiss_name={
                                                this.state.screener_translation &&
                                                this.state.screener_translation.dismiss_text
                                                    ? this.state.screener_translation.dismiss_text
                                                    : ''
                                            }
                                            onSubmit={this.onSubmitForm}
                                            onChange={this.onChangeForm}
                                            onChangeWithTimeout={this.onChangeWithTimeout}
                                            is_submitting={this.state.is_submitting}
                                            show_qualification={this.isAdminView()}
                                        />
                                    </>
                                )}

                                {this.state.result_text && (
                                    <Box
                                        py={3}
                                        className="fs-title-16 color-text-primary quill-render"
                                        dangerouslySetInnerHTML={{ __html: this.state.result_text }}
                                    />
                                )}
                            </PublicScreenPageFormWrapper>
                            <Box textAlign="center">{helpers.renderScreenerFooter(this.state.account)}</Box>
                            {helpers.renderPoweredByPanelfox(this.state.account)}

                            <NiceModal
                                isOpen={this.state.showConsentFormModal}
                                shouldCloseOnOverlayClick={false}
                                onRequestClose={this.handleCloseConsentForm}
                                //title="Consent Forms"
                                style={{ content: { width: '640px' } }}
                            >
                                <ReactFormGenerator
                                    is_preview={this.state.is_preview}
                                    //is_preview_start_page={this.state.is_preview_start_page}
                                    //answer_data={{}}
                                    //read_only={false}
                                    //hide_actions={false}
                                    data={this.state.consentForm.form} // Question data
                                    translation={this.state.consentForm.screener_translation}
                                    branding={this.state.account.branding}
                                    //addpipe_token={this.state.account.addpipe_token}
                                    //addpipe_eid={this.state.account.addpipe_eid}
                                    page_logic={this.state.consentForm.page_logic}
                                    person={this.state.study_person ? this.state.study_person.person : null} // Question data
                                    person_defaults={
                                        this.state.study_person && this.state.study_person.person
                                            ? this.state.study_person.person.custom_data_values2
                                            : null
                                    } // Question data
                                    action_name={
                                        this.state.consentForm.screener_translation &&
                                        this.state.consentForm.screener_translation.submit_button_text
                                            ? this.state.consentForm.screener_translation.submit_button_text
                                            : ''
                                    }
                                    next_name={
                                        this.state.consentForm.screener_translation &&
                                        this.state.consentForm.screener_translation.next_button_text
                                            ? this.state.consentForm.screener_translation.next_button_text
                                            : ''
                                    }
                                    back_name={
                                        this.state.consentForm.screener_translation &&
                                        this.state.consentForm.screener_translation.cancel_button_text
                                            ? this.state.consentForm.screener_translation.cancel_button_text
                                            : ''
                                    }
                                    is_required_name={
                                        this.state.consentForm.screener_translation &&
                                        this.state.consentForm.screener_translation.is_required_text
                                            ? this.state.consentForm.screener_translation.is_required_text
                                            : ''
                                    }
                                    dismiss_name={
                                        this.state.consentForm.screener_translation &&
                                        this.state.consentForm.screener_translation.dismiss_text
                                            ? this.state.consentForm.screener_translation.dismiss_text
                                            : ''
                                    }
                                    onSubmit={this.onSubmitForm_consent}
                                    //onChange={this.onChangeForm}
                                    //onChangeWithTimeout={this.onChangeWithTimeout}
                                    is_submitting={this.state.is_submitting_consent}
                                    show_qualification={this.isAdminView()}
                                    isPopup
                                />
                            </NiceModal>
                        </>
                    )}
                </Box>
            </PublicScreenPageWrapper>
        );
    }
}

export default withToastManager(PublicScreenPage);
