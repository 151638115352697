import React from 'react';
import { Helmet } from 'react-helmet';
import ReactModal from 'react-modal';
// import Board from 'components/Board';
import 'styles/react-kanban.css';
import { withToastManager } from 'react-toast-notifications';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import services from 'services/services';
import service_studies from 'services/studies';
import service_accounts from 'services/accounts';
import service_people from 'services/people';
import styled from 'styled-components';
import UploadParticipants from 'components/Participant/UploadParticipants';
import config from 'config';
import auth from 'services/auth';
import { withTheme } from 'emotion-theming';
import store from 'store';
import DatePicker from 'react-datepicker';
import ReactModalActions from 'components/ReactModalActions';
import DataViz from 'components/DataViz';
import SelectModal from 'components/SelectModal';
import analytics from 'utils/analytics';
import ReactCanvasConfetti from 'react-canvas-confetti';
import ReactTooltip from 'react-tooltip';
import TextAreaAutosize from 'react-textarea-autosize';
import InputSearch from 'components/InputSearch';
import xhr from 'xhr.js';
const env = process.env.NODE_ENV;
import cloneDeep from 'lodash/cloneDeep';
import BreadcrumbBack, { BreadcrumbTitle } from 'components/BreadcrumbBack';

import {
    FiXCircle,
    FiChevronLeft,
    FiTrash2,
    FiCalendar,
    FiDollarSign,
    FiPlus,
    FiSun,
    FiSend,
    FiFileText,
    FiArrowDown,
    FiArrowUp,
    FiColumns,
    FiServer,
    FiSettings,
    FiSave,
    FiArrowRight,
    FiArrowLeft,
    FiX,
    FiCheckCircle,
    FiDownloadCloud,
    FiCopy,
    FiChevronDown,
    FiCheckSquare,
    FiMinus,
    FiCheck,
    FiPlayCircle,
    FiEdit,
    FiFilter,
    FiSliders,
    FiMinusCircle,
    FiMoreVertical,
    FiUserPlus,
    FiUser,
    FiPaperclip,
    FiMenu,
    FiPieChart,
    FiMinusSquare,
    FiFileMinus,
    FiUploadCloud,
    FiExternalLink,
    FiInfo,
    FiLayers
} from 'react-icons/fi';

import StudyFilters from 'components/Study/StudyFilters';
import AddCandidates from 'components/Study/AddCandidates';
import VariablesHelper from 'components/EmailTools/VariablesHelper';
import ListWrapper from 'components/ListWrapper';
import { ReactFormGenerator } from 'components/ReactFormBuilder';
import NiceWrapper from 'components/NiceWrapper';
import helpers from 'utils/helpers';
import helpersStudy from 'utils/helpers-study';
import helpersScreener from 'utils/helpers-screener';
import { Link } from 'react-router-dom';
import H1 from 'components/H1';
import { Flex, Box, Button } from 'rebass';
import { Label, Input, Select, Textarea, Switch } from '@rebass/forms';
import StudyScript from 'components/Study/StudyScript';
import ParticipantNotes from 'components/Participant/ParticipantNotes';
import ParticipantPay from 'components/Participant/ParticipantPay';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingIndicator/LoadingWrapper';
import EmailEditor from 'components/EmailTools/EmailEditor';

import PaymentModal from 'components/PaymentModal';
import StudyParticipantsList from 'components/Study/StudyParticipantsList';
import AppPageWrapper from 'components/AppPageWrapper';
import AppPageWrapperSectionHeader from 'components/AppPageWrapper/AppPageWrapperSectionHeader';
import AppPageWrapperSectionBody from 'components/AppPageWrapper/AppPageWrapperSectionBody';
import AppPageWrapperSectionSubHeader from 'components/AppPageWrapper/AppPageWrapperSectionSubHeader';
import StudyPageSubNav from 'components/Study/StudyPageSubNav';
import StyledParticipantItem from 'components/Participant/StyledParticipantItem';
// import CardAdder from 'components/Board/components/Column/components/CardAdder';
import ActionPopup from 'components/ActionPopup';
import NiceDropdown from 'components/NiceDropdown';
import CreateParticipant from 'components/Participant/CreateParticipant';
import { HeaderSubnavButton } from 'components/Header/HeaderSubnavButton';
import SideOverlay from 'components/SideOverlay';
import NiceModal from 'components/NiceModal';
import UpgradeButton from 'components/UpgradeButton';
import Badge from 'components/Badge';
import {
    SP_STATUS_KEY_TITLE,
    COMPARE_FILTERS,
    SCREENER_STUDY_ONLY,
    CURRENCY_SYMBOL,
    SP_PARTICIPANT_STATUS,
    SP_PARTICIPANT_SESSION,
    SP_PARTICIPANT_LOCATION,
    STUDY_TABLE_COLUMN_ASSIGNED,
    STUDY_TABLE_COLUMN_SPOT,
    STUDY_TABLE_COLUMN_CONFIRMED,
    STUDY_TABLE_COLUMN_LOCATION,
    STUDY_TABLE_COLUMN_PAID,
    STUDY_TABLE_COLUMN_LAST_CONTACTED,
    MAX_EMAIL_ATTACHMENTS_SIZE,
    MOMENT_HUMAN_DATE,
    INTEGRATION_DSCOUT,
    PAID_FEATURE,
    EMAIL_TEMPLATE_TYPE,
    INCENTIVE_PAYMENT_METHOD,
    INCENTIVE_PAYMENT_METHOD_LABEL,
    NOTIFICATION_TITLE_DISABLED_EMAILING,
    NOTIFICATION_TITLE_HTML_NOT_ALLOWED,
    NOTIFICATION_CONTENT_DISABLED_EMAILING,
    NOTIFICATION_CONTENT_HTML_NOT_ALLOWED
} from 'utils/constants';
import NotificationCard from 'components/NotificationCard';
import cache from 'utils/cache';
import ShareStudyPeopleModal from 'components/ShareStudyPeopleModal';
import PaymentToastMessage from 'components/PaymentToastMessage';
import { SessionDatepickerModal } from 'components/SessionDatepickerModal';

const STORE_PREFIX_SEGMENT = 'pfx_study_segment:';
const STORE_PREFIX_CSV_EXPORT_COLUMNS = 'pfx_study_csv_export:';
const STORE_PREFIX_CSV_EXPORT_PANEL_COLUMNS = 'pfx_study_csv_export_panel:';
const STORE_PREFIX_STUDY_COLUMNS_HIDDEN = 'pfx_study_columns_hidden:';
const STORE_PREFIX_STUDY_COLUMNS_ORDER = 'pfx_study_columns_order:';
const STORE_STUDY_PAGINATION_LIMIT = 'pfx_study_pagination_limit';
const DEFAULT_COLUMNS_HIDDEN = [];
const DEFAULT_COLUMNS_ORDER = {};

const CardMetadata = styled.div`
    position: relative;
    margin: 16px 0 0 0;
    color: #aaa;

    div {
        color: #555;
    }
`;

const dscout_mission_delimiter = '___';

class StudyPage extends React.Component {
    constructor(props) {
        super(props);

        this.isAnimationEnabled = false;
        this.animationInstance = null;
        this.nextTickAnimation = this.nextTickAnimation.bind(this);
        this.getInstance = this.getInstance.bind(this);

        this.numHeaders = 6;

        let senderSignatureFromEmail = false;
        this.props.auth.user.sender_signatures.forEach(ss => {
            senderSignatureFromEmail = ss.email;
        });

        const cached_export_csv_checkboxes_exclude = this.getCachedCsvExportScreenerColumns();
        const cached_export_csv_custom_columns_include = this.getCachedCsvExportPanelColumns();

        const tableColumns_hidden = this.getColumnsHidden();
        const tableColumns_order = this.getColumnsOrder();

        let pagination_limit = 20;
        let pagination_limit_cached = store.get(this.getPaginationLimitCacheKey());
        if ([20, 50, 100].includes(pagination_limit_cached)) {
            pagination_limit = pagination_limit_cached;
        }
        console.log(pagination_limit, pagination_limit_cached);
        const studyId = this.props.match.params.id;
        const study = cache.get(`study.${studyId}`);

        this.state = {
            pushEventChannel: null,
            search_keyword: '',
            showModalSessionDatepicker: false,
            account_users: [],
            bulk_pay_people: [],
            showScreenerAnswersColumns: false,
            segment: '', // will be set in getStudy()
            study_loading: !study,
            study_people_loading: true,
            study_people_loading_more: false,
            showModalSendCampaign_type: null,
            study_availability_array: [],
            study: {
                title: '',
                description: '',
                screener: [],
                people_counts: {},
                screeners: [],
                study_states: [],
                integrations: [],
                ...study
            },
            users_viewing: [],
            checkAllOrVisibleStatus: 'none', // none, all, or visible, custom_count
            checkAll_custom_count_number: null,
            checkAll_randomize: false,
            study_people: {
                // with pagination
                data: []
            },
            p_checked: [],
            shareStudyPeopleTitle: this.generateShareStudyPeopleTitle(),
            study_states: [],
            board: {
                columns: []
            },
            shareStudyPeopleFilterObject: null,
            sender_profiles: [],
            panels: [],
            showModalBulkEditSp: false,
            showModalStudyAttributeIdSettings: false,
            showModalParticipantImport: false,
            showModalStudyIntercept: false,
            showModalStudyParticipant: false,
            showModalParticipantPay: false,
            showModalSendCampaign: false,
            showModalDataViz: false,
            showModalDscoutMissions: false,
            dscoutMissions: [],
            datavizScreenerId: null,
            email_attachments: [],
            email_attachment_isUploading: false,
            showModalScreener: false,
            selectedStudyPerson: {},
            showModalSendCampaign_editMode: false,
            allowSenderSignatureFromEmail: !!senderSignatureFromEmail,
            sendCampaign: {
                study_state_id: null,
                subject: '',
                content: '',
                templateType: EMAIL_TEMPLATE_TYPE.BLOCKS,
                from_email: senderSignatureFromEmail || '',
                from_name: this.props.auth.user.name,
                sender_profile_id: null,
                study_people: [],
                study_states: [],
                screener_id: null,
                send_when: null
            },
            participantsView: 'list', // list, board
            studyPeopleStates: {
                new: [],
                emailed: [],
                screened: [],
                scheduled: [],
                interviewed: [],
                paid: []
            },
            emailCampaignPreview: null,
            emailCampaignPreview_email_to: null,
            emailCampaignPreview_loading: true,
            showStudyOnboarding: !helpers.isOnboardingTaskComplete(this.props.auth.user, 'study'),
            foundNewStudyState: false, // this just prevents folks from playing around with old study types
            panel_columns: [],
            showModalExportCsv: false,
            showModalAssignUser: false,
            showModalEmailCampaignSuccess: false,
            showModalEmailCampaignSuccess_showlink: false,
            showModalEmailCampaignSuccess_message: null,
            export_csv_checkboxes_exclude: cached_export_csv_checkboxes_exclude
                ? cached_export_csv_checkboxes_exclude
                : {},
            export_csv_custom_columns_include: cached_export_csv_custom_columns_include
                ? cached_export_csv_custom_columns_include
                : {},
            screenerFilters: [],
            sort: {},
            pagination_limit: pagination_limit,
            showScreenerFilters: false,
            bulkPayVariables: {
                type: '',
                amount: ''
            },
            bulk_edit_field: {},
            bulk_edit_value: null,
            isSendingCampaign: false,
            isSendingSmsCampaign: false,
            tableColumns: [],
            tableColumns_hidden: tableColumns_hidden,
            tableColumns_order: tableColumns_order,
            tableColumns_all: [
                /*{
                    label: true,
                    title: 'Study Participation'
                },*/ {
                    id: STUDY_TABLE_COLUMN_ASSIGNED.id,
                    title: STUDY_TABLE_COLUMN_ASSIGNED.title,
                    checked: this.getIsColShowing(tableColumns_hidden, STUDY_TABLE_COLUMN_ASSIGNED.id),
                    type: 'study'
                },
                {
                    id: STUDY_TABLE_COLUMN_SPOT.id,
                    title: STUDY_TABLE_COLUMN_SPOT.title,
                    checked: this.getIsColShowing(tableColumns_hidden, STUDY_TABLE_COLUMN_SPOT.id),
                    type: 'study'
                },
                {
                    id: STUDY_TABLE_COLUMN_CONFIRMED.id,
                    title: STUDY_TABLE_COLUMN_CONFIRMED.title,
                    checked: this.getIsColShowing(tableColumns_hidden, STUDY_TABLE_COLUMN_CONFIRMED.id),
                    type: 'study'
                },
                {
                    id: STUDY_TABLE_COLUMN_LOCATION.id,
                    title: STUDY_TABLE_COLUMN_LOCATION.title,
                    checked: this.getIsColShowing(tableColumns_hidden, STUDY_TABLE_COLUMN_LOCATION.id),
                    type: 'study'
                },
                {
                    id: STUDY_TABLE_COLUMN_PAID.id,
                    title: STUDY_TABLE_COLUMN_PAID.title,
                    checked: this.getIsColShowing(tableColumns_hidden, STUDY_TABLE_COLUMN_PAID.id),
                    type: 'study'
                },
                {
                    id: STUDY_TABLE_COLUMN_LAST_CONTACTED.id,
                    title: STUDY_TABLE_COLUMN_LAST_CONTACTED.title,
                    checked: this.getIsColShowing(tableColumns_hidden, STUDY_TABLE_COLUMN_LAST_CONTACTED.id),
                    type: 'study'
                }
            ],
            showFilterTabSettings: false,
            filterTabForSettings: {},
            spot: ''
        };

        this.contentEditableTitle = React.createRef();
        this.handleSPLockChange = this.handleSPLockChange.bind(this);

        this.onBulkPayConfirm = this.onBulkPayConfirm.bind(this);
        this.getSegmentDefault = this.getSegmentDefault.bind(this);
        this.onTabChange = this.onTabChange.bind(this);
        this.onTabDelete = this.onTabDelete.bind(this);
        this.handleCheckAllDropdownChange = this.handleCheckAllDropdownChange.bind(this);
        this.getSenderProfilesXHR = this.getSenderProfilesXHR.bind(this);
        this.displaySelectedSenderProfile = this.displaySelectedSenderProfile.bind(this);
        this.toggleCheckbox = this.toggleCheckbox.bind(this);
        this.clickSendEmail = this.clickSendEmail.bind(this);
        this.loadMoreStudyPeople = this.loadMoreStudyPeople.bind(this);
        this.deselectAll = this.deselectAll.bind(this);
        this.setSegment = this.setSegment.bind(this);
        this.getStudy = this.getStudy.bind(this);
        this.onBoardCardRemove = this.onBoardCardRemove.bind(this);
        this.onBoardCardReorder = this.onBoardCardReorder.bind(this);
        this.updateStudyStates = this.updateStudyStates.bind(this);
        this.onBoardColumnReorder = this.onBoardColumnReorder.bind(this);
        this.onBoardColumnToggle = this.onBoardColumnToggle.bind(this);
        this.onBoardColumnCheck = this.onBoardColumnCheck.bind(this);
        this.onBoardColumnRename = this.onBoardColumnRename.bind(this);
        this.onBoardColumnRemove = this.onBoardColumnRemove.bind(this);
        this.onBoardColumnNew = this.onBoardColumnNew.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
        this.onBoardDeselectAllCards = this.onBoardDeselectAllCards.bind(this);
        this.performMassActionMove = this.performMassActionMove.bind(this);
        this.handleMassActionClick = this.handleMassActionClick.bind(this);
        this.handleCloseModalSMSMessage = this.handleCloseModalSMSMessage.bind(this);
        this.resetTableColumns = this.resetTableColumns.bind(this);

        this.handleDisplayedTableColumns = this.handleDisplayedTableColumns.bind(this);
        this.emailAttachmentUploadButton = this.emailAttachmentUploadButton.bind(this);
        this.onChangePaginationLimit = this.onChangePaginationLimit.bind(this);

        this.addParticipant = this.addParticipant.bind(this);
        this.onTitleChange = this.onTitleChange.bind(this);
        this.handleOnboardingClickAddParticipant = this.handleOnboardingClickAddParticipant.bind(this);
        this.checkIfStudyStateToggled = this.checkIfStudyStateToggled.bind(this);

        this.modalParticipantNotesCallbackFunction = this.modalParticipantNotesCallbackFunction.bind(this);
        this.modalParticipantPayCallbackFunction = this.modalParticipantPayCallbackFunction.bind(this);
        this.handleCloseModalSendCampaign = this.handleCloseModalSendCampaign.bind(this);
        this.handleOpenModalSendCampaign = this.handleOpenModalSendCampaign.bind(this);
        this.handleModalSendCampaign_review = this.handleModalSendCampaign_review.bind(this);
        this.handleModalSendCampaign_stateUpdate = this.handleModalSendCampaign_stateUpdate.bind(this);

        this.handleOpenModalParticipantCreate = this.handleOpenModalParticipantCreate.bind(this);
        this.handleCloseModalParticipantCreate = this.handleCloseModalParticipantCreate.bind(this);
        this.handleOpenModalParticipantImport = this.handleOpenModalParticipantImport.bind(this);
        this.handleCloseModalParticipantImport = this.handleCloseModalParticipantImport.bind(this);

        this.handleOpenModalStudyIntercept = this.handleOpenModalStudyIntercept.bind(this);
        this.handleCloseModalStudyIntercept = this.handleCloseModalStudyIntercept.bind(this);
        this.handleOpenModalStudyParticipant = this.handleOpenModalStudyParticipant.bind(this);
        this.handleCloseModalStudyParticipant = this.handleCloseModalStudyParticipant.bind(this);
        this.handleOpenModalParticipantPay = this.handleOpenModalParticipantPay.bind(this);
        this.handleCloseModalParticipantPay = this.handleCloseModalParticipantPay.bind(this);
        this.handleOpenModalScreener = this.handleOpenModalScreener.bind(this);
        this.handleCloseModalScreener = this.handleCloseModalScreener.bind(this);
        this.buildBoardAddColumn = this.buildBoardAddColumn.bind(this);
        this.getColumnById = this.getColumnById.bind(this);

        this.bulkDownloadFiles = this.bulkDownloadFiles.bind(this);
        this.renderBulkDownload = this.renderBulkDownload.bind(this);

        this.previewIntercept = this.previewIntercept.bind(this);

        this.onNewPerson = this.onNewPerson.bind(this);
        this.onUpdatePanels = this.onUpdatePanels.bind(this);

        this.handleCardClick = this.handleCardClick.bind(this);
        this.handleCardCheck = this.handleCardCheck.bind(this);
        this.handleSubmenuClickPay = this.handleSubmenuClickPay.bind(this);
        this.renderEmailCampaignPreview = this.renderEmailCampaignPreview.bind(this);
        this.ColumnAdder = this.ColumnAdder.bind(this);

        this.onChangeEditor = this.onChangeEditor.bind(this);

        this.onSearchKeywordChange = this.onSearchKeywordChange.bind(this);
        this.toggleShowScreenerAnswers = this.toggleShowScreenerAnswers.bind(this);

        this.handleCloseModalStudyAttributeSettings = this.handleCloseModalStudyAttributeSettings.bind(this);
        this.onBulkEditParticipantStatusValueChange = this.onBulkEditParticipantStatusValueChange.bind(this);
        this.onProcessBulkEdit = this.onProcessBulkEdit.bind(this);
        this.renderBulkEditValueChanger = this.renderBulkEditValueChanger.bind(this);
        this.customAttributeChanged = this.customAttributeChanged.bind(this);
        this.onParticipantUnmask = this.onParticipantUnmask.bind(this);
    }

    getIsColShowing(tableColumns_hidden, col_id) {
        if (tableColumns_hidden && tableColumns_hidden.includes(col_id)) {
            return false;
        } else {
            return true;
        }
    }

    setColumnsShowHide(columnId, isChecked) {
        let { tableColumns_hidden } = this.state;

        // not checked, that means hide the column; that means add to array
        if (isChecked == false) {
            tableColumns_hidden.push(columnId);
        } else {
            const indexOf_columnId = tableColumns_hidden.indexOf(columnId);
            if (indexOf_columnId != -1) {
                tableColumns_hidden.splice(indexOf_columnId, 1);
            }
        }
        this.saveColumnsShowHide(tableColumns_hidden);
    }

    saveColumnsShowHide(tableColumns_hidden) {
        const study_id = this.props.match.params.id;
        store.set(STORE_PREFIX_STUDY_COLUMNS_HIDDEN + study_id, tableColumns_hidden);
        this.setState({ tableColumns_hidden });
    }

    getColumnsHidden() {
        const study_id = this.props.match.params.id;
        let cols_show_hide = store.get(STORE_PREFIX_STUDY_COLUMNS_HIDDEN + study_id);

        if (!cols_show_hide || !Array.isArray(cols_show_hide)) {
            cols_show_hide = DEFAULT_COLUMNS_HIDDEN;
        }

        return cols_show_hide;
    }

    getColumnsOrder() {
        const study_id = this.props.match.params.id;
        let cols_order = store.get(STORE_PREFIX_STUDY_COLUMNS_ORDER + study_id);

        if (!cols_order) {
            cols_order = DEFAULT_COLUMNS_ORDER;
        }

        return cols_order;
    }

    saveColumnsOrder(cols_order) {
        const study_id = this.props.match.params.id;
        store.set(STORE_PREFIX_STUDY_COLUMNS_ORDER + study_id, cols_order);
        this.setState({ tableColumns_order: cols_order });
    }

    checkIfAnyoneChecked() {
        if (!this.state.p_checked.length) {
            alert('Please "checkbox" at least one person');
            return;
        } else {
            return true;
        }
    }

    onTabChange(e, study_state) {
        // console.log(e.target.name);
        console.log(study_state.title);
        if (e.target.name == 'tab_title') {
            study_state.title = e.target.value;
        } else if (e.target.name == 'tab_order') {
            study_state.order = e.target.value;
        }

        const { study } = this.state;
        const found_ss = study.study_states.find(ss => ss.id == study_state.id);
        const index = study.study_states.indexOf(found_ss);
        if (index > -1) {
            study.study_states[index] = study_state;
            this.setState({ study }, () => {
                this.updateStudyState(study_state.id);
            });
        }
    }

    onTabDelete(study_state_id) {
        const { toastManager } = this.props;
        const that = this;

        const study_state = this.state.study.study_states.find(ss => ss.id == study_state_id);
        if (study_state) {
            console.log(study_state);

            if (confirm(`Are you sure you want to delete "${study_state.title}"?`)) {
                const { study } = this.state;
                const found_ss = study.study_states.find(ss => ss.id == study_state.id);
                const index = study.study_states.indexOf(found_ss);
                if (index > -1) {
                    study.study_states.splice(index, 1);
                    this.setState({ study, showFilterTabSettings: false });
                }

                services
                    .deleteStudyState(study_state.study_id, study_state)
                    .then(() => {
                        const segment_id_all = that.getSegmentDefault(this.state.study);
                        that.setSegment(segment_id_all);

                        toastManager.add('Successfully deleted', {
                            appearance: 'success',
                            autoDismiss: true
                        });
                    })
                    .catch(error => {
                        const errorText = services.parseAndTrackXhrErrors(error);
                        toastManager.add(errorText, {
                            appearance: 'error',
                            autoDismiss: true
                        });
                    });
                console.log('deleting');
            } else {
                console.log('NOT deleting');
            }
        }
    }

    toggleShowScreenerAnswers(e) {
        const { showScreenerAnswersColumns } = this.state;
        console.log(showScreenerAnswersColumns);
        this.setState({ showScreenerAnswersColumns: !showScreenerAnswersColumns });
    }

    onSearchKeywordChange(e) {
        console.log(e);
        const that = this;
        this.setState({ search_keyword: e.target.value }, () => {
            if (that.onSearchKeywordChange_delay) {
                clearTimeout(that.onSearchKeywordChange_delay);
            }

            that.onSearchKeywordChange_delay = setTimeout(() => {
                that.getStudyPeople(that.state.segment, 1);
            }, 500);
        });
    }

    onParticipantUnmask(participant) {
        const study_people = this.state.study_people;
        const participant_idx = study_people.data.findIndex(p => p.id == participant.id);
        if (participant_idx >= 0) {
            study_people.data[participant_idx] = participant;
            this.setState({ study_people });
        }
    }

    onChangeEditor(editorData, editorType) {
        const { sendCampaign } = this.state;
        sendCampaign.content = editorData;
        sendCampaign.templateType = editorType;
        this.setState({ sendCampaign });
    }

    /**
     * Update email data with new body and subject when user selects a template
     *
     * @param {{ body: any; subject: string; type: number; id: number }} template
     */
    onTemplate(template) {
        this.setState(({ sendCampaign }) => {
            sendCampaign.content = template.body;
            sendCampaign.subject = template.subject;
            sendCampaign.templateType = template.type;
            return { sendCampaign };
        });
    }

    handleOpenModalStudyAttributeSettings() {
        this.setState({ showModalStudyAttributeIdSettings: true });
    }

    handleCloseModalStudyAttributeSettings() {
        this.setState({ showModalStudyAttributeIdSettings: false });
    }

    handleOpenModalParticipantImport() {
        this.setState({ showModalParticipantImport: true });
    }

    handleCloseModalParticipantImport() {
        this.setState({ showModalParticipantImport: false });
    }

    handleOpenModalParticipantCreate() {
        this.setState({ showModalParticipantCreate: true });
    }

    handleCloseModalParticipantCreate() {
        this.setState({ showModalParticipantCreate: false });
    }

    ColumnAdder() {
        return (
            <Button
                variant="link"
                sx={{ margin: '8px 72px 0 40px', padding: '8px', fontWeight: 500 }}
                onClick={this.onBoardColumnNew}
            >
                <FiPlus />
                Add Column
            </Button>
        );
    }

    handleCardCheck(study_state_id, study_person_id, isChecked) {
        // loop over study_states to find the study_person
        const { study_states } = this.state;
        const ss_found = study_states.find(ss => ss.id == study_state_id);
        if (ss_found) {
            const ss_index = study_states.indexOf(ss_found);
            if (ss_index > -1) {
                const sp_found = ss_found.study_people.find(sp => sp.id == study_person_id);
                if (sp_found) {
                    const sp_index = ss_found.study_people.indexOf(sp_found);
                    if (sp_index > -1) {
                        // update study person within study state
                        study_states[ss_index].study_people[sp_index].checked = isChecked;

                        this.renderNewStudyStates(study_states);
                    }
                }
            }
        }
    }

    handleCardClick(study_person_id) {
        analytics.track('study-sp-click', { study_id: this.state.study.id });
        const { toastManager } = this.props;
        // load the modal so user gets instant response
        this.handleOpenModalStudyParticipant(study_person_id);
        //console.log('handleCardClick')
        const lock_action = 'lock';
        service_studies
            .getStudyPerson(this.state.study.id, study_person_id, lock_action)
            .then(selectedStudyPerson => {
                if (selectedStudyPerson) {
                    this.setState({ selectedStudyPerson });
                    this.updateStudyPersonInGrid(selectedStudyPerson);
                }
            })
            .catch(error => {
                //console.log('NOT FOUND....')
                this.handleCloseModalStudyParticipant();

                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    handleSubmenuClickPay(participant_id) {
        // @todo
        throw new Error('this.state.study.study_people fix this');
        // console.log('clicked submenu');
        const selectedStudyPerson = this.state.study.study_people.find(sp => sp.person_id == participant_id);
        if (selectedStudyPerson) {
            this.setState({ selectedStudyPerson });
            this.handleOpenModalParticipantPay();
        }
    }

    handleOpenModalScreener(e, studyPerson) {
        this.setState({
            showModalScreener: true,
            selectedStudyPerson: studyPerson
        });
        // e.preventDefault();
        e.stopPropagation();
    }

    handleCloseModalScreener() {
        this.setState({ showModalScreener: false });
    }

    handleOpenModalSendCampaign(automation_type, studyPeople_checked, options) {
        // console.log('running sendcampaign', automation_type, studyPeople);

        const { sendCampaign } = this.state;
        sendCampaign.study_people = studyPeople_checked;
        sendCampaign.study_states = null;
        if (automation_type == 'screen') {
            if (options.screener_id) {
                // find screener
                const screenerFound = this.state.study.screeners.find(s => s.id == options.screener_id);
                if (screenerFound) {
                    sendCampaign.screener_id = options.screener_id;
                    sendCampaign.subject = screenerFound.email_screener_subject;
                    sendCampaign.content = screenerFound.email_screener_body;
                    sendCampaign.templateType = screenerFound.email_screener_type;
                } else {
                    alert('Screener not found - please try again.');
                    return;
                }
            } else {
                sendCampaign.subject = this.state.study.email_screener_subject;
                sendCampaign.content = this.state.study.email_screener_body;
                sendCampaign.templateType = this.state.study.email_screener_type;
            }
        } else if (automation_type == 'schedule') {
            sendCampaign.subject = this.state.study.email_scheduling_subject;
            sendCampaign.content = this.state.study.email_scheduling_body;
            sendCampaign.templateType = this.state.study.email_scheduling_type;
        } else if (automation_type == 'confirmation') {
            sendCampaign.subject = this.state.study.email_confirmation_subject;
            sendCampaign.content = this.state.study.email_confirmation_body;
            sendCampaign.templateType = this.state.study.email_confirmation_type;
        } else if (automation_type == 'custom') {
            sendCampaign.subject = '';
            sendCampaign.content = '';
            sendCampaign.templateType = EMAIL_TEMPLATE_TYPE.BLOCKS;
        } else if (automation_type == 'dscout') {
            sendCampaign.subject = options.subject ? options.subject : '';
            sendCampaign.content = options.content ? options.content : '';
            sendCampaign.templateType = options.templateType ? options.templateType : EMAIL_TEMPLATE_TYPE.BLOCKS;
            sendCampaign.study_integration_id = options.study_integration_id;
        }

        // clear the screener_id if not needed
        if (['dscout', 'custom', 'schedule', 'confirmation'].includes(automation_type)) {
            sendCampaign.screener_id = null;
        }

        this.setState({
            showModalSendCampaign: true,
            showModalSendCampaign_type: automation_type,
            sendCampaign,
            showModalSendCampaign_editMode: ['dscout', 'custom'].includes(automation_type) ? true : false
        });

        const studyAutomationOnboardingTask = `study-automation-${this.state.study.id}`;
        if (!helpers.isOnboardingTaskComplete(this.props.auth.user, studyAutomationOnboardingTask)) {
            services.completeOnboardingTask(studyAutomationOnboardingTask);
        }
    }

    isEmailingFeatureDisabled() {
        return !helpers.hasFeatureEnabled(this.props.auth.account, PAID_FEATURE.EMAILING);
    }

    isEmailSendingDisabled() {
        return (
            this.isEmailingFeatureDisabled() ||
            (this.state.sendCampaign.templateType == EMAIL_TEMPLATE_TYPE.HTML &&
                !helpers.hasVerifiedDomain(this.props.auth.account))
        );
    }

    // @todo this is what should move the cards, and refresh the page
    handleCloseModalSendCampaign(move_sp_back) {
        const { study_states } = this.state;
        this.setState({ showModalSendCampaign: false });

        const next_study_state_id = this.state.sendCampaign.study_state_id;

        // move back == dont move anything :D
        if (move_sp_back) {
            // new study state should be "study_state_id_previous"
            // @todo only for manual moves?
        }
        // move it to the next column
        else {
            // move cards
            // @todo-move

            try {
                this.performMassActionMove(next_study_state_id);
            } catch (e) {
                helpers.trackError(e);
            }

            // this.getStudy();

            /*
            next_study_state_id = sp.study_state_id_future;

            sp.checked = false;
            sp.study_state_id = sp.study_state_id_future;

            // find which column this SP is
            console.log(sp);
            const studyStateMoveFrom = study_states.find(ss => ss.id == sp.study_state_id_previous);
            const studyStateMoveFrom_index = study_states.indexOf(studyStateMoveFrom);
            const studyStateMoveFrom_sp_index = studyStateMoveFrom['study_people'].indexOf(sp);

            // take it out of study_state_id_previous
            study_states[studyStateMoveFrom_index]['study_people'].splice(studyStateMoveFrom_sp_index, 1);

            // put it study_state_id_future
            const studyStateMoveTo = study_states.find(ss => ss.id == sp.study_state_id_future);
            const studyStateMoveTo_index = study_states.indexOf(studyStateMoveTo);
            study_states[studyStateMoveTo_index]['study_people'].push(sp);


            this.renderNewStudyStates(study_states);

            // @todo add _studyStates
            // this.state.sendCampaign.study_people & this.state.sendCampaign.study_states
            this.xhrMoveStudyPeople(this.state.showModalSendCampaign_studyPeople, [], next_study_state_id);

            */
        }
    }

    // TODO: here is important
    handleModalSendCampaign_stateUpdate(key, val) {
        const that = this;
        const { sendCampaign } = this.state;
        sendCampaign[key] = val;
        this.setState({ sendCampaign }, () => {
            // don't do this every keyup
            if (that.handleModalSendCampaign_stateUpdate_TIMEOUT) {
                // console.log('not gonna update!');
                clearTimeout(that.handleModalSendCampaign_stateUpdate_TIMEOUT);
            }
            that.handleModalSendCampaign_stateUpdate_TIMEOUT = setTimeout(function() {
                // console.log('...gonna update!');
                const email_action = 'preview';
                that.handleModalSendCampaign_review(email_action);
            }, 1000);
        });
    }

    /*
        email_action: null, preview, test
            null = actually send the email
            preview = just return the html
            test = ask for 1 email to send to
    */

    handleModalSendCampaign_review(email_action) {
        let test_email_address = null;
        if (email_action == 'preview') {
            this.setState({ emailCampaignPreview_loading: true });
        } else if (email_action == 'test') {
            test_email_address = prompt('Email to send test to:', this.props.auth.user.email);

            if (!test_email_address) {
                return;
            }
        } else if (email_action == 'send') {
            if (!confirm('Are you sure you want to send this email?')) {
                return false;
            } else {
                this.setState({ isSendingCampaign: true });
            }
        } else {
            alert('We did not get a email_action. Please email support@panelfox.io');
            return;
        }

        let people = [];
        let filters = null;
        let search_keyword = null;
        let custom_count_number = null;
        const { toastManager } = this.props;

        if (this.state.participantsView == 'board') {
            this.state.sendCampaign.study_people.forEach(sp => {
                people.push({ id: sp.person_id });
            });
        } else {
            // console.log(this.state.checkAllOrVisibleStatus)
            if (this.state.checkAllOrVisibleStatus === 'all') {
                filters = this.state.screenerFilters;
                search_keyword = this.state.search_keyword;
            } else if (this.state.checkAllOrVisibleStatus === 'custom_count') {
                filters = this.state.screenerFilters;
                search_keyword = this.state.search_keyword;
                custom_count_number = this.state.checkAll_custom_count_number;
            } else {
                people = this.state.p_checked;
            }
        }

        /*

            **** REMINDER ****


            consider editing the mass sms method as well


            **** REMINDER ****

            */

        services
            .sendEmailCampaign(
                this.state.study.id,
                people, // be careful, this sends a Person model, not Study Person :)
                this.state.sendCampaign.study_states,
                filters,
                this.state.sendCampaign.subject,
                this.state.sendCampaign.content,
                this.state.sendCampaign.templateType,
                email_action,
                this.state.sendCampaign.from_email,
                this.state.sendCampaign.from_name,
                test_email_address,
                this.state.sendCampaign.screener_id,
                search_keyword,
                custom_count_number,
                this.state.sendCampaign.send_when,
                JSON.stringify(this.state.sort),
                this.state.email_attachments,
                this.state.checkAll_randomize,
                this.state.sendCampaign.study_integration_id
            )
            .then(emailCampaignResponse => {
                // console.log('emailCampaign', emailCampaign);

                this.setState({ isSendingCampaign: false });

                if (email_action == 'preview') {
                    this.setState({
                        emailCampaignPreview: emailCampaignResponse,
                        emailCampaignPreview_loading: false
                    });
                } else if (email_action == 'test') {
                    toastManager.add('Test email successfully sent', {
                        appearance: 'success',
                        autoDismiss: true
                    });
                } else {
                    const success_msg = this.state.sendCampaign.send_when
                        ? 'Email successfully scheduled'
                        : 'Email successfully sent';

                    toastManager.add(success_msg, {
                        appearance: 'success',
                        autoDismiss: true
                    });

                    if (this.state.participantsView == 'board') {
                        this.handleCloseModalSendCampaign();
                    } else {
                        this.getStudy();
                        this.deselectAll();

                        let { sendCampaign } = this.state;
                        sendCampaign.send_when = null;

                        this.setState({
                            showModalSendCampaign: false,
                            showModalScheduleSend: false,
                            sendCampaign
                        });

                        if (this.state.sendCampaign.screener_id) {
                            const found_screener = this.state.study.screeners.find(
                                s => s.id == this.state.sendCampaign.screener_id
                            );
                            console.log('found_screener', found_screener);
                            let found_screener_message = '';
                            if (found_screener) {
                                //console.log(found_screener.participant_external, SCREENER_STUDY_ONLY)
                                if (found_screener.participant_external == SCREENER_STUDY_ONLY) {
                                    found_screener_message = (
                                        <Box mb={3} className="warning-box">
                                            The e-mailed screener is <strong>limited to Study Participants</strong>,
                                            please update Screener Settings to allow anyone to complete it.
                                        </Box>
                                    );
                                }
                            }

                            //console.log('found_screener_message', found_screener_message);

                            const success_message = (
                                <Box mt={2}>
                                    {found_screener_message}

                                    <Box mt={3} fontSize={1}>
                                        Share on social media,
                                    </Box>
                                    <Box fontSize={1} className="ellipsis">
                                        <a
                                            href={`${config.getAccountPrefixUrl(this.props.auth.account)}/emails/${
                                                emailCampaignResponse.data.email_campaign_uuid
                                            }`}
                                            target="_blank"
                                        >
                                            {config.getAccountPrefixUrl(this.props.auth.account)}/emails/
                                            {emailCampaignResponse.data.email_campaign_uuid}
                                        </a>
                                    </Box>
                                </Box>
                            );

                            this.setState({
                                showModalEmailCampaignSuccess: true,
                                showModalEmailCampaignSuccess_message: success_message
                            });

                            const count_recipients = this.getNumberPeopleSelected();
                            if (count_recipients >= 100) {
                                let animation_duration = 3;
                                if (count_recipients >= 5000) {
                                    animation_duration = 9;
                                } else if (count_recipients >= 1000) {
                                    animation_duration = 6;
                                }

                                this.runCelebration(animation_duration);
                            }
                        }
                    }
                }
            })
            .catch(error => {
                this.setState({ isSendingCampaign: false });
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: false
                });
            });
    }

    handleCloseModalSMSMessage() {
        this.setState({ showModalSMSMessage: false });
    }

    /*
        email_action: null, preview, test
            null = actually send the email
            preview = just return the html
            test = ask for 1 email to send to
    */

    handleModalSendSms(action) {
        if (!this.state.p_checked.length) {
            alert('Please "checkbox" at least one study participant');
            return;
        }

        const content = this.state.smsCampaign_content.trim();
        if (!content.length) {
            alert('Message body can not be empty');
            return;
        }

        let test_phone_number = null;
        if (action == 'test') {
            test_phone_number = prompt('Phone number to send test to:', this.props.auth.user.phone_number);

            if (!test_phone_number) {
                return;
            }
        } else if (action == 'send') {
            if (!confirm('Are you sure you want to send this SMS?')) {
                return false;
            } else {
                this.setState({ isSendingSmsCampaign: true });
            }
        } else {
            alert('We did not get a action. Please email support@panelfox.io');
            return;
        }

        let people = [];
        let filters = null;
        let search_keyword = null;
        let custom_count_number = null;
        const { toastManager } = this.props;

        // console.log(this.state.checkAllOrVisibleStatus)
        if (this.state.checkAllOrVisibleStatus === 'all') {
            filters = this.state.screenerFilters;
            search_keyword = this.state.search_keyword;
        } else if (this.state.checkAllOrVisibleStatus === 'custom_count') {
            filters = this.state.screenerFilters;
            search_keyword = this.state.search_keyword;
            custom_count_number = this.state.checkAll_custom_count_number;
        } else {
            people = this.state.p_checked;
        }

        /*

            **** REMINDER ****


            consider editing the mass email method as well


            **** REMINDER ****

            */

        service_studies
            .sendSmsCampaign(
                this.state.study.id,
                people, // be careful, this sends a Person model, not Study Person :)
                this.state.sendCampaign.study_states,
                filters,
                content,
                action,
                test_phone_number,
                search_keyword,
                custom_count_number,
                JSON.stringify(this.state.sort),
                this.state.checkAll_randomize
            )
            .then(smsCampaignResponse => {
                // console.log('emailCampaign', emailCampaign);

                this.setState({ isSendingSmsCampaign: false });

                if (action == 'test') {
                    toastManager.add('Test email successfully sent', {
                        appearance: 'success',
                        autoDismiss: true
                    });
                } else {
                    const success_msg = 'SMS successfully sent';

                    toastManager.add(success_msg, {
                        appearance: 'success',
                        autoDismiss: true
                    });

                    this.getStudy();
                    this.deselectAll();

                    this.handleCloseModalSMSMessage();
                }
            })
            .catch(error => {
                this.setState({ isSendingSmsCampaign: false });
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: false
                });
            });
    }

    handleRemoveScreenerResponse(screener) {
        if (!this.state.p_checked.length) {
            alert('Please "checkbox" at least one study participant');
            return;
        }

        let people = [];
        let filters = null;
        let search_keyword = null;
        const { toastManager } = this.props;
        let custom_count_number = null;

        // console.log(this.state.checkAllOrVisibleStatus)
        if (this.state.checkAllOrVisibleStatus === 'all') {
            filters = this.state.screenerFilters;
            search_keyword = this.state.search_keyword;
        } else if (this.state.checkAllOrVisibleStatus === 'custom_count') {
            filters = this.state.screenerFilters;
            search_keyword = this.state.search_keyword;
            custom_count_number = this.state.checkAll_custom_count_number;
        } else {
            people = this.state.p_checked;
        }

        if (!confirm(`Are you sure you want to delete these screener responses?`)) {
            return;
        }

        toastManager.add('Starting to remove screener responses', {
            appearance: 'success',
            autoDismiss: true
        });

        service_studies
            .studyRemoveStudyPeopleScreenerResponse(
                this.state.study.id,
                people, // be careful, this sends a Person model, not Study Person :)
                filters,
                search_keyword,
                JSON.stringify(this.state.sort),
                custom_count_number,
                this.state.checkAll_randomize,
                screener.id
            )
            .then(() => {
                toastManager.add('Successfully removed screener responses', {
                    appearance: 'success',
                    autoDismiss: true
                });
                this.deselectAll();
                this.getStudy();
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }
    handleRemoveStudyPeople() {
        if (!this.state.p_checked.length) {
            alert('Please "checkbox" at least one study participant');
            return;
        }

        let people = [];
        let filters = null;
        let search_keyword = null;
        const { toastManager } = this.props;
        let custom_count_number = null;

        // console.log(this.state.checkAllOrVisibleStatus)
        if (this.state.checkAllOrVisibleStatus === 'all') {
            filters = this.state.screenerFilters;
            search_keyword = this.state.search_keyword;
        } else if (this.state.checkAllOrVisibleStatus === 'custom_count') {
            filters = this.state.screenerFilters;
            search_keyword = this.state.search_keyword;
            custom_count_number = this.state.checkAll_custom_count_number;
        } else {
            people = this.state.p_checked;
        }

        if (!confirm(`Are you sure you want to remove these participants?`)) {
            return;
        }

        toastManager.add('Starting to remove participants', {
            appearance: 'success',
            autoDismiss: true
        });

        service_studies
            .studyRemoveStudyPeople(
                this.state.study.id,
                people, // be careful, this sends a Person model, not Study Person :)
                filters,
                search_keyword,
                JSON.stringify(this.state.sort),
                custom_count_number,
                this.state.checkAll_randomize
            )
            .then(() => {
                toastManager.add('Successfully removed participants', {
                    appearance: 'success',
                    autoDismiss: true
                });
                this.deselectAll();
                this.getStudy();
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    async onBulkPayConfirm({ amount, paymentType, paymentDate }) {
        const { toastManager } = this.props;

        const peopleIds = this.state.bulk_pay_people.map(p => p.id);

        try {
            await service_studies.bulkPayAccept(this.state.study.id, peopleIds, paymentType, amount, paymentDate);

            toastManager.add('Payment process initiated', {
                appearance: 'success',
                autoDismiss: true
            });

            this.setState({
                bulk_pay_people: [],
                showModalBulkPay: false
            });

            this.deselectAll();
            this.getStudyPeople(this.state.segment);
        } catch (error) {
            const errorText = services.parseAndTrackXhrErrors(error);
            const studyPersonId = error.response && error.response.data && error.response.data.study_person_id;
            toastManager.add(
                <PaymentToastMessage studyId={this.state.study.id} message={errorText} studyPersonId={studyPersonId} />,
                {
                    appearance: 'error',
                    autoDismiss: false
                }
            );
        }
    }

    /**
     * Generate the title for the share study people modal
     *
     * @param {number} count Number of people selected
     */
    generateShareStudyPeopleTitle(count = 0) {
        const formattedCount = helpers.numberFormat(count);

        if (count === 0) return 'Put panelists in study';

        if (count === 1) return 'Put 1 panelist in study';

        return `Put ${formattedCount} panelists in study`;
    }

    openShareStudyPeopleModal() {
        if (!this.state.p_checked.length) {
            return this.props.toastManager.add('Please "checkbox" at least one study participant', {
                appearance: 'info',
                autoDismiss: true
            });
        }

        const count = this.getNumberPeopleSelected();
        const shareStudyPeopleTitle = this.generateShareStudyPeopleTitle(+count);

        this.setState({ shareStudyPeopleFilterObject: this.createFilterQuery(), shareStudyPeopleTitle });
    }

    bulkPayXHR() {
        if (!this.state.p_checked.length) {
            alert('Please "checkbox" at least one study participant');
            return;
        }

        let people = [];
        let filters = null;
        let search_keyword = null;
        const { toastManager } = this.props;
        let custom_count_number = null;

        // console.log(this.state.checkAllOrVisibleStatus)
        if (this.state.checkAllOrVisibleStatus === 'all') {
            filters = this.state.screenerFilters;
            search_keyword = this.state.search_keyword;
        } else if (this.state.checkAllOrVisibleStatus === 'custom_count') {
            filters = this.state.screenerFilters;
            search_keyword = this.state.search_keyword;
            custom_count_number = this.state.checkAll_custom_count_number;
        } else {
            people = this.state.p_checked;
        }

        service_studies
            .bulkPayVerify(
                this.state.study.id,
                people, // be careful, this sends a Person model, not Study Person :)
                filters,
                search_keyword,
                JSON.stringify(this.state.sort),
                custom_count_number,
                this.state.checkAll_randomize
            )
            .then(people => {
                this.setState({
                    bulk_pay_people: people,
                    showModalBulkPay: true
                });
                //alert(JSON.stringify(people_ids));
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                const studyPersonId = error.response && error.response.data && error.response.data.study_person_id;
                toastManager.add(
                    <PaymentToastMessage
                        studyId={this.state.study.id}
                        message={errorText}
                        studyPersonId={studyPersonId}
                    />,
                    {
                        appearance: 'error',
                        autoDismiss: false
                    }
                );
            });
    }

    handleOpenModalStudyIntercept() {
        this.setState({ showModalStudyIntercept: true });
    }

    handleCloseModalStudyIntercept() {
        this.setState({ showModalStudyIntercept: false });
    }

    handleOpenModalStudyParticipant(study_person_id) {
        console.log('handleOpenModalStudyParticipant');
        window.history.pushState(null, null, `/studies/${this.state.study.id}/participants/${study_person_id}`); // not passing a title or URL
        this.setState({ showModalStudyParticipant: true });
    }

    handleCloseModalStudyParticipant() {
        const { toastManager } = this.props;

        // un-lock the SP
        const lock_action = 'unlock';
        service_studies
            .getStudyPerson(this.state.study.id, this.state.selectedStudyPerson.id, lock_action)
            .then(study_person => {
                if (study_person) {
                    this.updateStudyPersonInGrid(study_person);
                }
            })
            .catch(error => {
                //this.handleCloseModalStudyParticipant();

                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });

        window.history.pushState(null, null, `/studies/${this.state.study.id}`); // not passing a title or URL
        this.setState({ showModalStudyParticipant: false, selectedStudyPerson: {} });
    }

    handleOpenModalParticipantPay() {
        this.setState({ showModalParticipantPay: true });
    }

    handleCloseModalParticipantPay() {
        this.setState({ showModalParticipantPay: false });
    }

    onNewPerson(study_person) {
        const { toastManager } = this.props;

        toastManager.add('Successfully created a new candidate', {
            appearance: 'success',
            autoDismiss: true
        });

        if (this.state.participantsView == 'board') {
            const { study_states } = this.state;
            const ss_candidates = study_states.find(ss => ss.type == 'candidates');
            const ss_candidates_index = study_states.indexOf(ss_candidates);
            study_states[ss_candidates_index].study_people.push(study_person);
            this.renderNewStudyStates(study_states);
        } else {
            this.getStudy();
        }
    }

    addParticipant(board, column, study_person) {
        board.columns.forEach((c, key) => {
            if (c.id == column.id) {
                // console.log('adding card...');
                const newColumn = this.addCard(c, study_person);
                board.columns[key] = newColumn;
            }
        });

        return board;
    }

    addCard(column, study_person) {
        column.cards.push(this.makeCard(study_person));
        return column;
    }

    makeCard(study_person) {
        return {
            id: study_person.id,
            study_state_id: study_person.study_state_id,
            title: (
                <Box sx={{ whiteSpace: 'nowrap', width: '100%' }}>
                    <div style={{ fontSize: '14px', fontWeight: 500, paddingRight: '36px' }} className="ellipsis">
                        {helpers.personGetCustomValue(study_person.person, 'First Name') &&
                        helpers.personGetCustomValue(study_person.person, 'First Name').length ? (
                            helpers.personGetCustomValue(study_person.person, 'First Name')
                        ) : (
                            <span style={{ color: 'rgba(255,0,0,.5)' }}>First name</span>
                        )}{' '}
                        {helpers.personGetCustomValue(study_person.person, 'Last Name') &&
                        helpers.personGetCustomValue(study_person.person, 'Last Name').length ? (
                            helpers.personGetCustomValue(study_person.person, 'Last Name')
                        ) : (
                            <span style={{ color: 'rgba(255,0,0,.5)' }}>Last name</span>
                        )}
                    </div>
                    <div
                        style={{
                            fontSize: '13px',
                            color: '#888',
                            fontWeight: 400,
                            marginTop: '4px',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden'
                        }}
                    >
                        {helpers.personGetCustomValue(study_person.person, 'Email') &&
                        helpers.personGetCustomValue(study_person.person, 'Email').length ? (
                            helpers.personGetCustomValue(study_person.person, 'Email')
                        ) : (
                            <span style={{ color: 'rgba(255,0,0,.5)' }}>
                                <em>Missing email</em>
                            </span>
                        )}
                    </div>
                </Box>
            ),
            description: (
                <div style={{ fontSize: '12px' }}>
                    {study_person.incentive_status == 'paid' ? (
                        <CardMetadata>
                            <FiDollarSign /> Incentive
                            <div>Paid ${study_person.incentive_total_paid}</div>
                        </CardMetadata>
                    ) : study_person.spot ? (
                        <CardMetadata>
                            <FiCalendar /> Session
                            <div>
                                {moment
                                    .utc(study_person.spot)
                                    .local()
                                    .format('ddd, MMM Do [at] h:mm a')}
                            </div>
                        </CardMetadata>
                    ) : study_person.screener_answers.length ? (
                        <CardMetadata>
                            <div>
                                <FiFileText /> Screener
                                <div>
                                    Answered
                                    {/*study_person.screener_answers.is_qualified ? 'Qualified' : 'Disqualified'*/}
                                </div>
                            </div>
                        </CardMetadata>
                    ) : (
                        study_person.emails_sent.length > 0 && (
                            <CardMetadata>
                                <FiSend /> Last Email
                                <div>
                                    {moment
                                        .utc(study_person.emails_sent[study_person.emails_sent.length - 1].created_at)
                                        .tz(this.props.auth.user.timezone)
                                        .format('ddd, MMM Do [at] h:mm a')}
                                </div>
                            </CardMetadata>
                        )
                    )}

                    {helpers.personIsOptedOut(study_person.person) && (
                        <CardMetadata style={{ color: 'red' }}>Opted out</CardMetadata>
                    )}
                </div>
            ),
            checked: study_person.checked
        };
    }

    renderNewStudyStates(study_states) {
        const that = this;

        const study_people = study_states.reduce((accumulator, ss) => accumulator.concat(ss.study_people), []);

        this.setState({ study_states }, () => {
            const newBoard = that.buildBoard_with_studyStates(this.state.study, study_states);
            that.setState({ board: newBoard });
        });
    }

    updateStudyPeople(study_people) {
        const { toastManager } = this.props;

        services
            .updateStudyPeople(this.state.study.id, study_people)
            .then(study_people_xhr => {
                // this.getStudy();
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    xhrMoveStudyPeople(study_people, study_states, new_study_state_id, cb) {
        const { toastManager } = this.props;

        service_studies
            .studyMoveStudyPeople(this.state.study.id, study_people, study_states, new_study_state_id)
            .then(study_people_xhr => {
                toastManager.add('Successfuly moved people', {
                    appearance: 'success',
                    autoDismiss: true
                });
                cb();
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    buildBoardAddColumn(board, studyState, studyPeople) {
        const that = this;
        const ss = {
            id: studyState.id,
            title: studyState.title,
            lock: studyState.lock,
            toggle: studyState.toggle,
            type: studyState.type,
            cards: [],
            checked: studyState.checked,
            numStudyPeople: studyState.study_people_pagination ? studyState.study_people_pagination.total : 0,
            onLoadMoreCards: () => {
                const next_page = studyState.study_people_pagination
                    ? studyState.study_people_pagination.current_page + 1
                    : 1;

                service_studies
                    .getStudyPeopleByStudyState(studyState.study_id, studyState.id, next_page)
                    .then(study_state_sp_pagination => {
                        const { study_states } = this.state;
                        study_states.forEach((ss, key) => {
                            if (ss.id == studyState.id) {
                                ss.study_people = ss.study_people.concat(study_state_sp_pagination.data);
                                ss.study_people_pagination = that.formatStudyStatePagination(study_state_sp_pagination);
                            }
                        });

                        that.renderNewStudyStates(study_states);
                    });
            }
        };

        if (studyState.type == 'candidates') {
            // console.log(board, studyState);
            ss.cardAdderElement = (
                <CardAdder
                    numStudyPeople={this.state.study.people_counts.all}
                    onCreatePerson={this.handleOpenModalParticipantCreate}
                    onShowUploadParticipants={this.handleOpenModalParticipantImport}
                />
            );
        }

        board.columns.push(ss);

        return board;
    }

    // returns new Board
    buildBoard_with_studyStates(study, study_states) {
        let newBoard = {
            columns: []
        };

        let someStudyPeopleChecked = false;

        if (study_states && study_states.length) {
            study_states.forEach(studyState => {
                newBoard = this.buildBoardAddColumn(newBoard, studyState, study.study_people);

                studyState.study_people.forEach(sp => {
                    newBoard = this.addParticipant(newBoard, studyState, sp);
                    if (sp.checked == true) {
                        someStudyPeopleChecked = true;
                    }
                });
            });

            if (someStudyPeopleChecked) {
                this.setState({ massActionChecked: true });
            } else {
                this.setState({ massActionChecked: false });
            }
        } else {
            newBoard.columns = [];
        }

        // console.log(newBoard);

        return newBoard;
    }

    checkIfStudyStateToggled(study_state_type) {
        let isToggled = false;
        this.state.study.study_states.forEach((ss, index) => {
            if (ss.type == study_state_type && ss.toggle) {
                isToggled = true;
            }
        });
        return isToggled;
    }

    updateStudyIntegrations(integration, callback) {
        const { toastManager } = this.props;
        const study_id = this.props.match.params.id;

        xhr.post(
            `/studies/${study_id}/integrations?account_id=${this.props.auth.account.id}`,
            { ...integration },
            { withCredentials: true }
        )
            .then(xhrResponse => {
                const studyIntegration = xhrResponse.data;
                callback(studyIntegration.id);
                toastManager.add('Added study integration', {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    getCachedSegmentChoice() {
        const study_id = this.props.match.params.id;
        return store.get(STORE_PREFIX_SEGMENT + study_id);
    }

    setCachedSegmentChoice(segment_id) {
        const study_id = this.props.match.params.id;
        return store.set(STORE_PREFIX_SEGMENT + study_id, segment_id);
    }

    getCachedCsvExportScreenerColumns() {
        const study_id = this.props.match.params.id;
        return store.get(STORE_PREFIX_CSV_EXPORT_COLUMNS + study_id);
    }

    setCachedCsvExportScreenerColumns(data) {
        const study_id = this.props.match.params.id;
        return store.set(STORE_PREFIX_CSV_EXPORT_COLUMNS + study_id, data);
    }

    getCachedCsvExportPanelColumns() {
        const study_id = this.props.match.params.id;
        return store.get(STORE_PREFIX_CSV_EXPORT_PANEL_COLUMNS + study_id);
    }

    setCachedCsvExportPanelColumns(data) {
        const study_id = this.props.match.params.id;
        return store.set(STORE_PREFIX_CSV_EXPORT_PANEL_COLUMNS + study_id, data);
    }

    getSegmentDefault(study) {
        let segment = null;
        const segment_all = study.study_states.find(ss => ss.title == 'All');

        if (segment_all) {
            segment = segment_all.id;
        } else {
            segment = study.study_states[0].id;
        }

        return segment;
    }

    getStudy(dontGetNewStudyPeople) {
        const { toastManager } = this.props;

        this.setState({ study_loading: true });

        if (!dontGetNewStudyPeople) {
            this.setState({ study_people_loading: true });
        }

        const studyId = this.props.match.params.id;
        services
            .getStudy(studyId)
            .then(study => {
                const that = this;
                cache.set(`study.${studyId}`, study);

                console.log('users viewing', study.users_viewing);

                this.setState(
                    {
                        study,
                        study_loading: false,
                        users_viewing: study.users_viewing
                    },
                    () => {
                        const url_study_person_id = this.props.match.params.study_person_id;
                        if (url_study_person_id > 0) {
                            this.handleCardClick(url_study_person_id);
                        }

                        this.getAvailabilityXHR();
                    }
                );

                let segment = null;
                if (!this.state.segment) {
                    // look for cached segment
                    const cached_segment_id = this.getCachedSegmentChoice();
                    if (cached_segment_id) {
                        // verify its found
                        const cached_segment_found = study.study_states.find(ss => ss.id == cached_segment_id);
                        if (cached_segment_found) {
                            segment = cached_segment_id;
                        }
                    }

                    // if no cached segment found, get the default
                    if (!segment) {
                        segment = this.getSegmentDefault(study);
                    }

                    this.setState({ segment });
                } else {
                    segment = this.state.segment;
                }

                let { tableColumns_all } = this.state;

                if (study.custom_sp_attributes && study.custom_sp_attributes.length) {
                    /*tableColumns_all.push({
                        label: true,
                        title: 'Custom Attributes'
                    });*/
                    study.custom_sp_attributes.forEach(cspa => {
                        const cspa_col_id = helpersStudy.getCspaTableColumnId(cspa);
                        tableColumns_all.push({
                            id: cspa_col_id,
                            title: cspa.title,
                            checked: this.getIsColShowing(this.state.tableColumns_hidden, cspa_col_id),
                            type: 'study'
                        });
                    });
                }

                if (study.screeners) {
                    study.screeners.forEach(screener => {
                        /*tableColumns_all.push({
                            label: true,
                            title: 'Screener: ' + screener.title
                        });*/

                        const screener_answered_id = helpersStudy.getScreenerAnsweredTableColumnId(screener.id);
                        tableColumns_all.push({
                            id: screener_answered_id,
                            title: 'Answered: ' + screener.title,
                            checked: this.getIsColShowing(this.state.tableColumns_hidden, screener_answered_id),
                            type: 'screener'
                        });

                        const questions = helpersStudy.getScreenerQuestions(screener);
                        if (questions) {
                            questions.forEach(question => {
                                if (!question.static) {
                                    const screener_q_id = helpersStudy.getScreenerQuestionTableColumnId(
                                        question.field_name
                                    );
                                    tableColumns_all.push({
                                        id: screener_q_id,
                                        title: helpers.removeHtml(question.label),
                                        checked: this.getIsColShowing(this.state.tableColumns_hidden, screener_q_id),
                                        type: 'screener'
                                    });
                                }
                            });
                        }

                        const screener_cv_id = helpersStudy.getScreenerCustomVariabledTableColumnId(screener.id);
                        tableColumns_all.push({
                            id: screener_cv_id,
                            title: 'Custom Variables: ' + screener.title,
                            checked: this.getIsColShowing(this.state.tableColumns_hidden, screener_cv_id),
                            type: 'screener'
                        });
                    });
                }

                this.setState({ tableColumns_all });

                let { bulkPayVariables } = this.state;
                bulkPayVariables.amount = study.incentive;
                this.setState({ bulkPayVariables });

                if (dontGetNewStudyPeople) {
                    // just ignore :)
                } else {
                    // this also gets study people
                    this.setSegment(segment);
                }

                // return;
                // check if study is old, and doesn't have any automation
                let foundNewStudyState = false;
                const studyStateIds = [];
                study.study_states.forEach(ss => {
                    if (ss.type == 'screen') {
                        foundNewStudyState = true;
                    }
                });
                this.setState({ foundNewStudyState });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    formatStudyStatePagination(study_state_sp_pagination) {
        return {
            total: study_state_sp_pagination.total,
            per_page: study_state_sp_pagination.per_page,
            current_page: study_state_sp_pagination.current_page
        };
    }

    getPaginationLimitCacheKey() {
        return `${STORE_STUDY_PAGINATION_LIMIT}:${this.props.match.params.id}`;
    }

    onChangePaginationLimit(new_limit) {
        let set_limit = 20;
        new_limit = parseInt(new_limit);
        if ([20, 50, 100].includes(new_limit)) {
            set_limit = new_limit;
        }
        store.set(this.getPaginationLimitCacheKey(), set_limit);
        this.setState({ pagination_limit: set_limit }, () => {
            this.getStudyPeople(this.state.segment, 1);
        });
    }

    loadMoreStudyPeople() {
        this.setState({ study_people_loading_more: true });
        const next_page = this.state.study_people.current_page + 1;
        this.getStudyPeople(this.state.segment, next_page);
    }

    getStudyPeople(segment, page, export_csv) {
        const { toastManager } = this.props;
        const that = this;

        let request_options = {
            study_id: this.props.match.params.id,
            segment,
            page,
            search_keyword: this.state.search_keyword,
            filters: this.state.screenerFilters, //FILTERS ARE PASSED IN AS JSON, NOT A STRING
            export_csv,
            sort: JSON.stringify(this.state.sort),
            pagination_limit: this.state.pagination_limit
        };

        const currentRequest = service_studies.getStudyPeoplePOST(request_options);

        if (export_csv) {
            // checked people.. if set
            let people_ids = [];
            let people;
            let custom_count_number = '';

            // see which "questions" the user selected
            const query_checkboxes_exclude = [];
            const export_csv_checkboxes_exclude = document.getElementsByName('export_csv_checkboxes_exclude');
            //console.log(export_csv_checkboxes_exclude);
            for (let i = 0, n = export_csv_checkboxes_exclude.length; i < n; i++) {
                //console.log(export_csv_checkboxes_exclude[i].checked, export_csv_checkboxes_exclude[i].dataset.question_field_name);
                if (!export_csv_checkboxes_exclude[i].checked) {
                    if (export_csv_checkboxes_exclude[i].dataset.question_field_name) {
                        query_checkboxes_exclude.push(export_csv_checkboxes_exclude[i].dataset.question_field_name);
                    }
                }
            }

            const query_checkboxes_custom_columns_include = [];
            const export_csv_custom_columns_include = document.getElementsByName('export_csv_custom_columns_include');
            //console.log(export_csv_custom_columns_include);
            for (let i = 0, n = export_csv_custom_columns_include.length; i < n; i++) {
                if (export_csv_custom_columns_include[i].checked) {
                    if (export_csv_custom_columns_include[i].dataset.custom_column_id) {
                        query_checkboxes_custom_columns_include.push(
                            export_csv_custom_columns_include[i].dataset.custom_column_id
                        );
                    }
                }
            }

            //console.log(query_checkboxes_exclude, query_checkboxes_custom_columns_include);return;

            // see if checkboxes are checked
            if (this.state.checkAllOrVisibleStatus === 'all') {
                // skip b/c this is ALL
            } else if (this.state.checkAllOrVisibleStatus === 'custom_count') {
                // only set the custom count
                custom_count_number = this.state.checkAll_custom_count_number;
            } else {
                people = this.state.p_checked;
                people.forEach(p => {
                    people_ids.push(p.id);
                });
            }

            const formParams = {
                filters: JSON.stringify(request_options.filters),
                csv_fields_exclude: JSON.stringify(query_checkboxes_exclude),
                csv_panel_fields_include: JSON.stringify(query_checkboxes_custom_columns_include),
                people_ids: people_ids,
                custom_count_number: custom_count_number,
                randomize: this.state.checkAll_randomize ? 1 : 0
            };

            // we get a URL back, and then mimic a POST request
            // (so that we're not limited by the request payload size)
            currentRequest.then(url => {
                const export_csv_url = `${config.API_URL}${url}`;
                toastManager.add('Starting CSV export. A large data set could take up to a minute.', {
                    appearance: 'success',
                    autoDismiss: true
                });
                analytics.track('study-export_csv', { study_id: this.state.study.id });

                const form = document.createElement('form');
                form.method = 'POST';
                form.action = export_csv_url;

                for (const key in formParams) {
                    if (formParams.hasOwnProperty(key)) {
                        const hiddenField = document.createElement('input');
                        hiddenField.type = 'hidden';
                        hiddenField.name = key;
                        hiddenField.value = formParams[key];

                        form.appendChild(hiddenField);
                    }
                }

                document.body.appendChild(form);
                form.submit();
            });
        } else {
            this.setState({ study_people_loading: true });
            this.getStudyPeople_lastRequest = currentRequest;

            currentRequest
                .then(study_people_xhr => {
                    if (that.getStudyPeople_lastRequest === currentRequest) {
                        // if this is page 2, 3, 4, etc then lets add the response to the current dataset
                        // if its page 1, then just render this data
                        if (page > 1) {
                            const { study_people } = this.state;
                            const new_data = study_people_xhr.data;
                            study_people_xhr.data = study_people.data.concat(new_data);
                        }

                        this.setState({
                            study_people: study_people_xhr,
                            study_people_loading: false,
                            study_people_loading_more: false
                        });
                    } else {
                        console.log('IGNORING OLD');
                    }
                })
                .catch(error => {
                    const errorText = services.parseAndTrackXhrErrors(error);

                    toastManager.add(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                });
        }
    }

    getPushEventNameViewChange() {
        const study_id = this.props.match.params.id;
        const prefix = config.is_eu_server ? 'eu-' : '';
        return `${prefix}study-${study_id}-sp-view-change`;
    }

    getStudyChangeEventName() {
        const study_id = this.props.match.params.id;
        const prefix = config.is_eu_server ? 'eu-' : '';
        return `${prefix}study-${study_id}-change`;
    }

    getAccountUsersXHR() {
        const { toastManager } = this.props;
        services
            .getAccountUsers()
            .then(account_users_xhr => {
                this.setState({ account_users: account_users_xhr });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    getAvailabilityXHR() {
        const { toastManager } = this.props;
        const show_taken_spots = 1;
        service_studies
            .getStudyAvailability(this.props.match.params.id, show_taken_spots)
            .then(study_availability => {
                let study_availability_array = helpersStudy.generateStudyAvailabilityArray(
                    study_availability,
                    this.state.study.timezone,
                    this.state.study.timezone
                );
                this.setState({
                    study_availability_array
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    getPanelsXHR() {
        const { toastManager } = this.props;

        service_people
            .getPanels()
            .then(panels_response => {
                this.setState({
                    panels: panels_response
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    onUpdatePanels(new_panels) {
        this.setState({ panels: new_panels });
    }

    setUserViewingStudy() {
        service_studies
            .setUserViewingStudy(this.props.match.params.id, this.props.auth.user.id)
            .then(ret => {
                //console.log(ret);
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
            });
    }

    removeUserViewingStudy() {
        service_studies
            .removeUserViewingStudy(this.props.match.params.id, this.props.auth.user.id)
            .then(ret => {
                console.log(ret);
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
            });
    }

    handleSPLockChange(message) {
        try {
            if (message.type == 'lock') {
                console.log('LOCK', message.body);
                if (message.body && message.body.study_person_id) {
                    const { study_people } = this.state;
                    const sp_index_found = study_people.data.findIndex(sp => sp.id == message.body.study_person_id);
                    if (sp_index_found != -1) {
                        study_people.data[sp_index_found].users_viewing = message.body;
                        this.setState({ study_people });
                    }
                }
            } else if (message.type == 'unlock') {
                console.log('UNLOCK', message.body);
                if (message.body && message.body.study_person_id) {
                    const { study_people } = this.state;
                    const sp_index_found = study_people.data.findIndex(sp => sp.id == message.body.study_person_id);
                    if (sp_index_found != -1) {
                        study_people.data[sp_index_found].users_viewing = {};
                        this.setState({ study_people });
                    }
                }
            }
        } catch (e) {
            helpers.trackError(e);
        }
    }

    handleWindowClose(sendBeacon) {
        try {
            let shouldRemoveSPViewer = false;
            console.log('this.state.showModalStudyParticipant', this.state.showModalStudyParticipant);
            if (this.state.showModalStudyParticipant) {
                shouldRemoveSPViewer = true;
            }

            if (this.state.pushEventChannel) {
                this.state.pushEventChannel.unbind(this.getPushEventNameViewChange());
                this.state.pushEventChannel.unbind(this.getStudyChangeEventName());
                if (sendBeacon) {
                    console.log('handleWindowClose - BEACON');
                    const URL1 = service_studies.getRemoveUserViewingStudy(
                        this.props.match.params.id,
                        this.props.auth.user.id
                    );
                    navigator.sendBeacon(URL1);

                    if (shouldRemoveSPViewer) {
                        console.log('....shouldRemoveSPViewer', shouldRemoveSPViewer);
                        const URL2 = service_studies.getRemoveUserViewingStudyPerson(
                            this.props.match.params.id,
                            this.state.selectedStudyPerson.id
                        );
                        navigator.sendBeacon(URL2);
                    }
                } else {
                    console.log('handleWindowClose - REGULAR');
                    this.removeUserViewingStudy();
                    if (shouldRemoveSPViewer) {
                        this.handleCloseModalStudyParticipant();
                    }
                }
            }
        } catch (e) {
            helpers.trackError(e);
        }
    }

    componentDidMount() {
        analytics.track('study-view', { study_id: this.props.match.params.id });
        this.getStudy();
        this.getSenderProfilesXHR();
        this.getCustomDataColumnsXHR();
        this.getAccountUsersXHR();
        this.getDscoutMissions();
        this.getPanelsXHR();

        this.setUserViewingStudy();
        this.setUserViewingStudy_interval = setInterval(() => {
            this.setUserViewingStudy();
        }, 30 * 1000);

        window.addEventListener('unload', () => {
            //if (document.visibilityState == 'hidden') {
            const sendBeacon = true;
            this.handleWindowClose(sendBeacon);
            //}
        });

        try {
            const that = this;
            if (window.pusher) {
                const channel = window.pusher.subscribe('panelfox-ws');
                this.setState({ pushEventChannel: channel }, () => {
                    this.state.pushEventChannel.bind(this.getPushEventNameViewChange(), message => {
                        //console.log('PUSHER message', message)
                        if (message) {
                            that.handleSPLockChange(message);
                        }
                    });

                    this.state.pushEventChannel.bind(this.getStudyChangeEventName(), message => {
                        console.log('PUSHER message UPDATE', message);
                        if (message) {
                            if (message.type == 'sp-update') {
                                //that.handleSPLockChange(message);
                                //this.getStudyPeople(this.state.segment);
                                const sp_id_to_update = message.body;
                                //console.log('update..?', sp_id_to_update)
                                service_studies.getStudyPerson(this.state.study.id, sp_id_to_update).then(found => {
                                    if (found) {
                                        let { study_people } = this.state;
                                        const sp_to_update_index = study_people.data.findIndex(
                                            element => element.id == sp_id_to_update
                                        );
                                        if (sp_to_update_index >= 0) {
                                            study_people.data[sp_to_update_index] = found;
                                            this.setState({ study_people });
                                        } else {
                                            console.log('sp_to_update_index not found for webhooks update');
                                        }
                                    }
                                });
                            } else if (message.type == 'study-viewers') {
                                console.log('update study vieweres!!!');
                                let uv_verified = [];
                                if (message.body) {
                                    message.body.forEach(uv => {
                                        if (uv && uv.user_id) {
                                            uv_verified.push(uv);
                                        }
                                    });
                                }
                                this.setState({ users_viewing: uv_verified });
                            } else {
                                console.log('type not found for event ' + this.getStudyChangeEventName());
                            }
                        }
                    });
                });
            }
        } catch (e) {
            helpers.trackError(e);
        }
    }

    componentWillUnmount() {
        this.isAnimationEnabled = false;
        this.handleWindowClose();

        if (this.setUserViewingStudy_interval) {
            clearInterval(this.setUserViewingStudy_interval);
        }
    }

    getCustomDataColumnsXHR() {
        services
            .getCustomDataColumnsXHR()
            .then(columns_xhr => {
                //console.log('GOT NEW COLUMNS', columns_xhr);
                this.setState({ panel_columns: columns_xhr });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
            });
    }

    onFormChange(e) {
        // console.log(e.target.name);

        const study = { ...this.state.study };
        study[e.target.name] = e.target.value;
        this.setState({ study });

        this.saveStudy(study);
    }

    onTitleChange(e) {
        // console.log(e);

        const study = { ...this.state.study };
        const newTitle = e.target.value;
        if (newTitle == study.title) {
            return;
        }

        study.title = newTitle;
        this.setState({ study });

        this.saveStudy(study);
    }

    onBoardCardReorder(card_study_person, { fromPosition, fromColumnId }, { toPosition, toColumnId }) {
        // @todo
        alert('Drag & Drop temporarily disabled. Please use the card CHECKBOXES to move cards.');
        return;
    }

    getColumnById(columnId) {
        return this.state.study_states.find(ss => ss.id == columnId);
    }

    toggleCheckbox(person_id) {
        const { p_checked } = this.state;

        // if person_id is in the array, remove it
        const foundPerson = p_checked.find(person => person.id == person_id);
        if (foundPerson) {
            const foundPerson_index = p_checked.indexOf(foundPerson);
            p_checked.splice(foundPerson_index, 1);
        } else {
            p_checked.push({ id: person_id });
        }

        const checkAllOrVisibleStatus = 'visible';

        this.setState({ p_checked, checkAllOrVisibleStatus });
    }

    clickSendEmail(type, options) {
        if (!this.state.p_checked.length) {
            alert('Please "checkbox" at least one study participant');
            return;
        }
        if (type == 'screen') {
            this.handleOpenModalSendCampaign('screen', this.state.p_checked, options);
        } else if (type == 'schedule') {
            this.handleOpenModalSendCampaign('schedule', this.state.p_checked);
        } else if (type == 'confirmation') {
            this.handleOpenModalSendCampaign('confirmation', this.state.p_checked);
        } else if (type == 'custom') {
            this.handleOpenModalSendCampaign('custom', this.state.p_checked);
        } else if (type == 'dscout') {
            this.handleOpenModalSendCampaign('dscout', this.state.p_checked, options);
        }
        const email_action = 'preview';
        this.handleModalSendCampaign_review(email_action);
    }

    // performMassActionMove

    // handleMassActionClick
    handleMassActionClick(move_to_study_state_id) {
        const { study_states } = this.state;
        const moveToStudyState = study_states.find(ss => ss.id == move_to_study_state_id);
        const moveToStudyState_index = study_states.indexOf(moveToStudyState);
        const isAutomationColumn = ['screen', 'schedule'].includes(moveToStudyState.type);

        const sp_moved_array = [];

        const sp_checked = [];
        const ss_checked = [];
        study_states.forEach(ss => {
            // an entire column is checked
            if (ss.checked) {
                ss_checked.push(ss);
            }
            // entire column is not checked, lets find individual people
            else {
                ss.study_people.forEach(sp => {
                    if (sp.checked) {
                        sp_checked.push(sp);
                    }
                });
            }
        });

        // for simplicity, and assuming there is pagination in the Study States
        // only do the quick ui update if no "Select All" are checked.
        // which means only if individual cards are checked
        if (!ss_checked.length) {
            // this updates the UI to reflect latest board/column/card positions, no API calls here
            sp_checked.forEach((sp, key) => {
                const currentStudyState = study_states.find(ss => ss.id == sp.study_state_id);
                const currentStudyState_index = study_states.indexOf(currentStudyState);
                const currentStudyState_sp_index = currentStudyState.study_people.indexOf(sp);

                // UPDATE CURRENT SS.SP
                if (isAutomationColumn) {
                    sp.study_state_id_previous = sp.study_state_id;
                    sp.study_state_id_future = move_to_study_state_id;
                    study_states[currentStudyState_index].study_people[currentStudyState_sp_index] = sp;
                } else {
                    // remove SP from a single column
                    study_states[currentStudyState_index].study_people.splice(currentStudyState_sp_index, 1);

                    // add SP to a new single column
                    sp.study_state_id = move_to_study_state_id;
                    sp.checked = false;
                    study_states[moveToStudyState_index].study_people.push(sp);
                }
            });
        }

        // it is an automation column
        if (isAutomationColumn) {
            if (moveToStudyState.type == 'screen') {
                this.handleModalSendCampaign_stateUpdate('study_state_id', moveToStudyState.id);
                //this.handleOpenModalSendCampaign('screen', sp_checked, ss_checked);
            } else if (moveToStudyState.type == 'schedule') {
                // alert('running schedule automation');
                this.handleModalSendCampaign_stateUpdate('study_state_id', moveToStudyState.id);
                //this.handleOpenModalSendCampaign('schedule', sp_checked, ss_checked);
            }
        }
        // save board, unless we move to an automation column,
        // in which scenario hold the cards back until the automation completes
        else {
            // again, if no Columns have "Select All" we don't need to re-fetch the study
            const shouldRefetchStudy = !!ss_checked.length;

            // update the study states via API, and execute the callback
            this.xhrMoveStudyPeople(sp_checked, ss_checked, move_to_study_state_id, () => {
                if (shouldRefetchStudy) {
                    this.getStudy();
                }
            });

            // update UI, only if no API call is made
            if (!shouldRefetchStudy) {
                this.renderNewStudyStates(study_states);
            }
        }
    }

    performMassActionMove(move_to_study_state_id) {
        const { study_states } = this.state;
        const moveToStudyState = study_states.find(ss => ss.id == move_to_study_state_id);
        const moveToStudyState_index = study_states.indexOf(moveToStudyState);
        const isAutomationColumn = ['screen', 'schedule'].includes(moveToStudyState.type);

        // get checked cards or columns
        const sp_checked = [];
        const ss_checked = [];
        study_states.forEach(ss => {
            // an entire column is checked
            if (ss.checked) {
                ss_checked.push(ss);
            }
            // entire column is not checked, lets find individual people
            else {
                ss.study_people.forEach(sp => {
                    if (sp.checked) {
                        sp_checked.push(sp);
                    }
                });
            }
        });

        // for simplicity, and assuming there is pagination in the Study States
        // only do the quick ui update if no "Select All" are checked.
        // which means only if individual cards are checked
        if (!ss_checked.length) {
            // this updates the UI to reflect latest board/column/card positions, no API calls here
            sp_checked.forEach((sp, key) => {
                const currentStudyState = study_states.find(ss => ss.id == sp.study_state_id);
                const currentStudyState_index = study_states.indexOf(currentStudyState);
                const currentStudyState_sp_index = currentStudyState.study_people.indexOf(sp);

                // remove SP from a single column
                study_states[currentStudyState_index].study_people.splice(currentStudyState_sp_index, 1);

                // add SP to a new single column
                sp.study_state_id = move_to_study_state_id;
                sp.checked = false;
                study_states[moveToStudyState_index].study_people.push(sp);
            });
        }

        // again, if no Columns have "Select All" we don't need to re-fetch the study
        const shouldRefetchStudy = !!ss_checked.length;

        // update the study states via API, and execute the callback
        this.xhrMoveStudyPeople(sp_checked, ss_checked, move_to_study_state_id, () => {
            if (shouldRefetchStudy) {
                this.getStudy();
            }
        });

        // update UI, only if no API call is made
        if (!shouldRefetchStudy) {
            this.renderNewStudyStates(study_states);
        }
    }

    onBoardCardRemove(card_study_person) {
        const { toastManager } = this.props;
        const { study_states } = this.state;

        if (!confirm('Are you sure you want to remove this person?')) {
            return;
        }

        // find study_state
        const ss = study_states.find(ss => ss.id == card_study_person.study_state_id);
        const ss_index = study_states.indexOf(ss);

        // remove sp from ss
        const sp = ss.study_people.find(sp => sp.id === card_study_person.id);
        const sp_index = ss.study_people.indexOf(sp);
        study_states[ss_index].study_people.splice(sp_index, 1);

        // update board, don't save thru board
        this.renderNewStudyStates(study_states);

        // remove through api
        service_studies
            .studyRemoveStudyPerson(this.state.study.id, card_study_person.id)
            .then(() => {
                console.log('deleted');
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    onBoardColumnToggle(column) {
        const { study_states } = this.state.study;

        const ss_found = study_states.find(ss => ss.id === column.id);
        const index = study_states.indexOf(ss_found);
        // console.log('index ', index);
        if (index > -1) {
            study_states[index].toggle = !study_states[index].toggle;
        }
        this.updateStudyStates(study_states);
    }

    onBoardColumnCheck(column, isChecked) {
        const { study_states } = this.state.study;

        const ss_found = study_states.find(ss => ss.id === column.id);
        const index = study_states.indexOf(ss_found);
        if (index > -1) {
            study_states[index].checked = true;

            // loop through study_people for that column and update .checked
            study_states[index].study_people.forEach((sp, sp_key) => {
                study_states[index].study_people[sp_key].checked = isChecked;
            });

            const do_not_save_board = true;
            this.updateStudyStates(study_states, do_not_save_board);
        }
    }

    onBoardDeselectAllCards(column, isChecked) {
        const { study_states } = this.state.study;

        study_states.forEach((ss, ss_index) => {
            study_states[ss_index].checked = false;
            ss.study_people.forEach((sp, sp_index) => {
                study_states[ss_index].study_people[sp_index].checked = false;
            });
        });

        this.renderNewStudyStates(study_states);
    }

    onBoardColumnRemove(column) {
        const { study_states } = this.state;

        const ss_found = study_states.find(ss => ss.id === column.id);
        const index = study_states.indexOf(ss_found);
        if (index > -1) {
            if (ss_found.study_people.length > 0) {
                alert('You are not allowed to delete a column with people inside. Delete the people first.');
                return;
            }

            if (!confirm('Are you sure you want to remove this column?')) {
                return;
            }

            study_states.splice(index, 1);
        }
        this.updateStudyStates(study_states);
    }

    onBoardColumnRename(column, new_title) {
        const { study_states } = this.state;

        const ss_found = study_states.find(ss => ss.id === column.id);
        const index = study_states.indexOf(ss_found);
        // console.log('index ', index);
        if (index > -1) {
            study_states[index].title = new_title;
        }
        this.updateStudyStates(study_states);
    }

    onBoardColumnReorder(column, { fromPosition }, { toPosition }) {
        const { study_states } = this.state;
        helpers.moveInArray(study_states, fromPosition, toPosition);
        this.updateStudyStates(study_states);
    }

    onBoardColumnNew(sf) {
        const { toastManager } = this.props;
        const { study_states } = this.state;
        const title = prompt('What would you like to title this tab?');

        if (title) {
            service_studies
                .studyAddStudyState(this.state.study.id, title, sf)
                .then(ss_new => {
                    const { study } = this.state;
                    study.study_states.push(ss_new);
                    this.setState({ study }, () => {
                        this.setSegment(ss_new.id);
                    });

                    // this.renderNewStudyStates(study_states);
                })
                .catch(error => {
                    const errorText = services.parseAndTrackXhrErrors(error);

                    toastManager.add(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                });
        }
    }

    updateStudyStates(study_states, do_not_save) {
        const { toastManager } = this.props;

        this.updateBoardStudyStateProperties(study_states);

        if (!do_not_save) {
            services
                .updateStudyStates(this.state.study.id, study_states)
                .then(() => {
                    // saved!
                })
                .catch(error => {
                    const errorText = services.parseAndTrackXhrErrors(error);

                    toastManager.add(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                });
        }
    }

    updateStudyState(segment, filters) {
        const { toastManager } = this.props;

        const study_state = this.state.study.study_states.find(ss => ss.id == segment);
        if (study_state) {
            if (filters) {
                study_state.filters = filters; // this.state.screenerFilters;
            }

            services
                .updateStudyState(this.state.study.id, study_state)
                .then(study_state_response => {
                    // update study states
                    const { study } = this.state;
                    const found_ss = study.study_states.find(ss => ss.id == study_state_response.id);
                    const index = study.study_states.indexOf(found_ss);
                    if (index > -1) {
                        study.study_states[index] = study_state_response;
                        this.setState({ study });
                    }

                    // saved!
                    toastManager.add('Filters saved successfully', {
                        appearance: 'success',
                        autoDismiss: true
                    });
                })
                .catch(error => {
                    const errorText = services.parseAndTrackXhrErrors(error);

                    toastManager.add(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                });
        }
    }

    updateBoardStudyStateProperties(study_states_new) {
        const study_states_old = this.state.study_states;
        console.log('updateBoardStudyStateProperties', study_states_new, study_states_old);

        study_states_new.forEach((ss_new, ss_new_key) => {
            const ss_old_found = study_states_old.find(ss_old => ss_old.id == ss_new.id);
            console.log(ss_old_found);
            if (ss_old_found) {
                // update new with old's study_people and pagination
                study_states_new[ss_new_key].study_people = ss_old_found.study_people;
                study_states_new[ss_new_key].study_people_pagination = ss_old_found.study_people_pagination;
            } else {
                console.log('NOT FOUND', ss_new);
            }
        });

        this.renderNewStudyStates(study_states_new);
    }

    saveStudy(study) {
        // detect change, and clear timeout if exists
        if (this.saveDelay) {
            clearTimeout(this.saveDelay);
        }

        // set timer for 200ms
        this.saveDelay = setTimeout(
            function() {
                // console.log('saving....');
                this.saveStudyXHR(study);
            }.bind(this),
            300
        );
    }

    saveStudyXHR(study) {
        // console.log('SAVing study!!!', study);

        const { toastManager } = this.props;

        /* const bareBoard = {
            columns: study.board.columns.map(column => ({
                id: column.id,
                title: column.title,
                numStudyPeople: column.numStudyPeople,
                cards: column.cards.map(card => ({
                    id: card.id
                }))
            }))
        };

        study.board = bareBoard; */

        services
            .updateStudy(study)
            .then(() => {
                // console.log('yess',response);

                toastManager.add('Saved', {
                    appearance: 'info',
                    autoDismiss: true
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    handleOnboardingClickAddParticipant() {
        // document.querySelector('.react-kanban-card-adder-button').click();
        this.handleOpenModalParticipantCreate();
    }

    keyPress(event) {
        if (event.charCode == 13) {
            event.preventDefault();
        }
    }

    makeShot(angle, originX) {
        this.animationInstance &&
            this.animationInstance({
                particleCount: 4,
                angle,
                decay: 0.94,
                spread: 40,
                origin: { x: originX, y: 1 },
                colors: ['#000000', '#ffffff']
            });
    }

    nextTickAnimation() {
        this.makeShot(65, 0);
        this.makeShot(115, 1);
        if (this.isAnimationEnabled && Date.now() < this.animationEnd) {
            requestAnimationFrame(this.nextTickAnimation);
        } else {
            this.isAnimationEnabled = false;
        }
    }

    runCelebration(durationSeconds) {
        analytics.track('study-mass_email-runCelebration');

        durationSeconds = durationSeconds || 5;
        if (!this.isAnimationEnabled) {
            this.animationEnd = Date.now() + durationSeconds * 1000;
            this.isAnimationEnabled = true;
            this.nextTickAnimation();
        }
    }

    stopAnimation() {
        this.isAnimationEnabled = false;
        this.animationInstance && this.animationInstance.reset();
    }

    getInstance(instance) {
        this.animationInstance = instance;
    }

    onColumnNew(newColumn) {
        const newColumn2 = { id: 321, ...newColumn };
        return newColumn2;
    }

    modalParticipantPayCallbackFunction(action, study) {
        // console.log(action, study);
        if (action == 'done') {
            window.location.reload();
            // this.setState({study});
        }

        this.setState({ showModalParticipantPay: false });
    }

    modalParticipantNotesCallbackFunction(action, study_person) {
        console.log('action', action);
        if (action == 'save') {
            if (this.state.participantsView == 'board') {
                try {
                    const { study_states } = this.state;
                    const ss_found = study_states.find(ss => ss.id == study_person.study_state_id);
                    const ss_index = study_states.indexOf(ss_found);

                    const sp_found = ss_found.study_people.find(sp => sp.id == study_person.id);
                    const sp_index = ss_found.study_people.indexOf(sp_found);
                    study_states[ss_index].study_people[sp_index] = study_person;

                    this.renderNewStudyStates(study_states);
                } catch (e) {
                    helpers.trackError(e);
                }
            } else {
                const dontGetNewStudyPeople = true;
                this.getStudy(dontGetNewStudyPeople);
                // this.getStudyPeople(this.state.segment)
                //console.log('modalParticipantNotesCallbackFunction', study_person);
                // find SP in study
                this.updateStudyPersonInGrid(study_person);
            }
        }
    }

    updateStudyPersonInGrid(study_person) {
        const { study_people } = this.state;
        const sp_found = study_people.data.find(sp => sp.id == study_person.id);
        if (sp_found) {
            const index = study_people.data.indexOf(sp_found);
            if (index > -1) {
                study_people.data[index] = study_person;
                this.setState({ study_people });
            }
        }
    }

    previewIntercept() {
        // this.popupWindow(config.API_URL + '/widget/start?preview=1&token=' + this.state.study.account.token + '&study_id='+this.state.study.id, this.state.study.title, 500, 600);
        /* window.Benny.init({
            token: this.state.study.account.token,
            previewId: this.state.study.id
        }); */
    }

    popupWindow(url, title, w, h) {
        const left = window.screen.width / 2 - w / 2;
        const top = window.screen.height / 2 - h / 2;
        return window.open(
            url,
            title,
            `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${top}, left=${left}`
        );
    }

    onSubmitAssignUserToPerson(user_id) {
        if (!this.state.p_checked.length) {
            alert('Please "checkbox" at least one study participant');
            return;
        }

        let custom_count_number = null;
        let people = [];
        let filters = null;
        let search_keyword = null;
        const { toastManager } = this.props;

        // console.log(this.state.checkAllOrVisibleStatus)
        if (this.state.checkAllOrVisibleStatus === 'all') {
            filters = this.state.screenerFilters;
            search_keyword = this.state.search_keyword;
        } else if (this.state.checkAllOrVisibleStatus === 'custom_count') {
            filters = this.state.screenerFilters;
            search_keyword = this.state.search_keyword;
            custom_count_number = this.state.checkAll_custom_count_number;
        } else {
            people = this.state.p_checked;
        }

        toastManager.add('Starting to assign participants', {
            appearance: 'success',
            autoDismiss: true
        });

        const filtering_options = {
            filters: JSON.stringify(filters),
            search_keyword: search_keyword,
            sort: JSON.stringify(this.state.sort),
            custom_count_number: custom_count_number
        };

        service_studies
            .assignUserToPeopleInStudy(
                this.state.study.id,
                people,
                user_id,
                filtering_options,
                this.state.checkAll_randomize
            )
            .then(assigned => {
                this.setState({ showModalAssignUser: false });
                this.deselectAll();
                this.getStudyPeople(this.state.segment);
                analytics.track('study-assign_user', { study_id: this.state.study.id });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    renderStudyPeopleStatesDropdown() {
        const r = [];
        r.push(<option>All</option>);
        Object.keys(this.state.studyPeopleStates).forEach(key => {
            r.push(<option>{key}</option>);
        });
        return (
            <Select mb={3} style={{ width: '200px' }}>
                {r}
            </Select>
        );
    }

    getSenderProfilesXHR() {
        const { toastManager } = this.props;

        service_accounts
            .getSenderProfiles()
            .then(sender_profiles => {
                this.setState({ sender_profiles });

                // set a default
                if (sender_profiles.length) {
                    const { sendCampaign } = this.state;

                    let default_sender_profile_id = null;
                    sender_profiles.forEach(sp => {
                        if (this.props.auth.user.email == sp.email) {
                            default_sender_profile_id = sp.id;
                        }
                    });
                    if (default_sender_profile_id) {
                        sendCampaign.sender_profile_id = default_sender_profile_id;
                    } else {
                        sendCampaign.sender_profile_id = sender_profiles[0].id;
                    }

                    const sp_found = sender_profiles.find(sp => sp.id == sendCampaign.sender_profile_id);
                    sendCampaign.from_email = sp_found.email;
                    sendCampaign.from_name = sp_found.name;

                    this.setState({ sendCampaign });
                }
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    getPossibleTableColumns() {
        const cols = [
            STUDY_TABLE_COLUMN_ASSIGNED
            /*answered screener N..N2
            spot
            confirmed
            location
            paid
            custom study attributes
            screener answers
            custom variables N..N2
            last contacted at*/
        ];
    }

    handleDisplayedTableColumns(columnId, isChecked) {
        const { toastManager } = this.props;
        //console.log('handleDisplayedTableColumns', columnId, isChecked);
        const { tableColumns_all } = this.state;

        const found_col = tableColumns_all.find(column => column.id === columnId);

        if (found_col) {
            const index = tableColumns_all.indexOf(found_col);
            if (index > -1) {
                tableColumns_all[index].checked = isChecked;
                this.setColumnsShowHide(columnId, isChecked);
                this.setState({ tableColumns_all });

                analytics.track('study-columns-showhide', { columnIdShowHide: columnId + ' - ' + isChecked });
            }
        }
    }

    displaySelectedSenderProfile() {
        if (this.state.sendCampaign.sender_profile_id) {
            const sp_found = this.state.sender_profiles.find(sp => sp.id == this.state.sendCampaign.sender_profile_id);

            if (sp_found) {
                return `${sp_found.name} <${sp_found.email}>`;
            }
        }
        return 'No profile selected';
    }

    renderEmailCampaignPreview() {
        if (!this.state.emailCampaignPreview) {
            return;
        }

        const notificationCardTitle = this.isEmailingFeatureDisabled()
            ? NOTIFICATION_TITLE_DISABLED_EMAILING
            : NOTIFICATION_TITLE_HTML_NOT_ALLOWED;

        const notificationCardContent = this.isEmailingFeatureDisabled()
            ? NOTIFICATION_CONTENT_DISABLED_EMAILING
            : NOTIFICATION_CONTENT_HTML_NOT_ALLOWED;

        return (
            <Box style={{ fontSize: '14px' }}>
                <H1 sx={{ color: '#777 !important' }}>Confirm "{this.state.showModalSendCampaign_type}" automation</H1>
                {this.isEmailSendingDisabled() && (
                    <NotificationCard
                        type="danger"
                        icon={<FiInfo />}
                        title={notificationCardTitle}
                        content={notificationCardContent}
                        style={{ marginBottom: '16px' }}
                    />
                )}
                <Label>From</Label>
                {this.state.sender_profiles.length == 0 ? (
                    <Box
                        style={{
                            // border: '1px solid #ddd',
                            background: '#f7f7f7',
                            borderRadius: '4px',
                            padding: '8px 16px',
                            fontWeight: 500
                        }}
                        mb={3}
                    >
                        {this.state.sendCampaign.from_name}{' '}
                        {this.state.sendCampaign.from_email ? (
                            <span>&lt;{this.state.sendCampaign.from_email}&gt;</span>
                        ) : (
                            ''
                        )}
                    </Box>
                ) : (
                    <Box sx={{ position: 'relative' }} mb={3}>
                        <Button
                            type="button"
                            variant="secondary-gray"
                            onClick={() => this.setState({ showSenderProfilesDropdown: true })}
                        >
                            {this.displaySelectedSenderProfile()}
                            <FiChevronDown
                                style={{
                                    marginRight: 0
                                }}
                            />
                        </Button>
                        {this.state.showSenderProfilesDropdown && (
                            <NiceDropdown
                                onClose={() => this.setState({ showSenderProfilesDropdown: false })}
                                items={this.state.sender_profiles.map(sender_profile => ({
                                    id: sender_profile.id,
                                    title: `${sender_profile.name} <${sender_profile.email}>`
                                }))}
                                onChange={sender_profile_id => {
                                    const sender_profile = this.state.sender_profiles.find(
                                        sp => sp.id == sender_profile_id
                                    );
                                    const { sendCampaign } = this.state;
                                    sendCampaign.sender_profile_id = sender_profile_id;
                                    sendCampaign.from_email = sender_profile.email;
                                    sendCampaign.from_name = sender_profile.name;

                                    this.setState({ sendCampaign });
                                }}
                            />
                        )}
                    </Box>
                )}

                <Label>Subject</Label>
                <Box
                    style={{
                        // border: '1px solid #ddd',
                        background: '#f7f7f7',
                        borderRadius: '4px',
                        padding: '8px 16px',
                        fontWeight: 500,
                        margin: '0 0 16px 0',
                        color: this.state.sendCampaign.subject ? 'inherit' : 'red'
                    }}
                >
                    {this.state.sendCampaign.subject ? this.state.sendCampaign.subject : 'Subject missing'}
                </Box>
                <Label>Body</Label>
                <Box
                    as="iframe"
                    style={{ minHeight: '600px', width: '100%', border: 'none' }}
                    title="template preview"
                    sandbox="allow-same-origin allow-scripts"
                    srcDoc={this.state.emailCampaignPreview}
                />
                <br />
                <br />
                <br />
            </Box>
        );
    }

    async handleCheckAllDropdownChange(checkAllOrVisibleStatus, checkAll_custom_count_number, checkAll_randomize) {
        const checkboxes = document.getElementsByName('person_id');
        const p_checked = [];

        for (let i = 0, n = checkboxes.length; i < n; i++) {
            if (checkAllOrVisibleStatus !== 'none') {
                p_checked.push({ id: checkboxes[i].dataset.personid });
            }
        }

        //console.log('p_checked', p_checked, 'checkAll_randomize', checkAll_randomize);

        this.setState({ checkAllOrVisibleStatus });
        this.setState({ checkAll_custom_count_number });
        this.setState({ checkAll_randomize });
        this.setState({ showCheckAllDropdown: false });
        this.setState({ p_checked });
    }

    deselectAll() {
        this.setState({
            checkAllOrVisibleStatus: 'none',
            p_checked: []
        });
    }

    setSegment(segment_id) {
        if (this.state.study && this.state.study.study_states) {
            const study_state = this.state.study.study_states.find(ss => ss.id == segment_id);
            if (study_state) {
                this.setState(
                    { screenerFilters: study_state.filters, segment: segment_id, study_people_loading: true },
                    () => {
                        this.deselectAll();
                        this.getStudyPeople(segment_id);
                    }
                );
                this.setCachedSegmentChoice(segment_id);
            }
        }
    }

    changeTabOrder(newOrder) {
        const { toastManager } = this.props;
        service_studies
            .updateStudyStatesOrder(this.props.match.params.id, newOrder)
            .then(study_states => {
                // we already saved it in the UI
                console.log(4);
            })
            .catch(error => {
                console.log(error);
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    getNumberPeopleSelected() {
        let count = 0;
        if (this.state.participantsView == 'board') {
            count = this.state.sendCampaign.study_people.length;
            this.state.sendCampaign.study_states.forEach(ss => {
                if (ss.study_people_pagination) {
                    count += ss.study_people_pagination.total;
                }
            });
        } else if (this.state.checkAllOrVisibleStatus == 'all') {
            count = this.state.study_people.total;
        } else if (this.state.checkAllOrVisibleStatus == 'custom_count') {
            count = this.state.checkAll_custom_count_number;
        } else {
            count = this.state.p_checked.length;
        }
        return count;
    }

    generateSendToNumberPeople() {
        if (this.state.isSendingCampaign == true || this.state.isSendingSmsCampaign == true) {
            return `Processing...`;
        } else {
            const count = this.getNumberPeopleSelected();

            return `Send to ${count} ${count > 1 ? 'people' : 'person'}`;
        }
    }

    createFilterQuery() {
        const query = {
            sort: JSON.stringify(this.state.sort),
            randomize: this.state.checkAll_randomize
        };

        if (this.state.checkAllOrVisibleStatus === 'all') {
            query.filters = this.state.screenerFilters;
            query.search_keyword = this.state.search_keyword;
        } else if (this.state.checkAllOrVisibleStatus === 'custom_count') {
            query.filters = this.state.screenerFilters;
            query.search_keyword = this.state.search_keyword;
            query.custom_count_number = this.state.checkAll_custom_count_number;
        } else {
            query.people_as_array = this.state.p_checked;
        }

        return query;
    }

    onProcessBulkEdit(e) {
        e.preventDefault();

        if (!this.state.p_checked.length) {
            alert('Please "checkbox" at least one study participant');
            return;
        }

        let people = [];
        let filters = null;
        let search_keyword = null;
        const { toastManager } = this.props;
        let custom_count_number = null;

        // console.log(this.state.checkAllOrVisibleStatus)
        if (this.state.checkAllOrVisibleStatus === 'all') {
            filters = this.state.screenerFilters;
            search_keyword = this.state.search_keyword;
        } else if (this.state.checkAllOrVisibleStatus === 'custom_count') {
            filters = this.state.screenerFilters;
            search_keyword = this.state.search_keyword;
            custom_count_number = this.state.checkAll_custom_count_number;
        } else {
            people = this.state.p_checked;
        }

        const bulk_edit_field_id = this.state.bulk_edit_field.id;
        const bulk_edit_value = this.state.bulk_edit_value;

        if (!confirm(`Are you sure you want to update these respondents?`)) {
            return;
        }

        service_studies
            .bulkSpEdit(
                this.state.study.id,
                people, // be careful, this sends a Person model, not Study Person :)
                filters,
                search_keyword,
                JSON.stringify(this.state.sort),
                custom_count_number,
                bulk_edit_field_id,
                bulk_edit_value,
                this.state.checkAll_randomize
            )
            .then(people => {
                toastManager.add('Successful bulk edit', {
                    appearance: 'success',
                    autoDismiss: true
                });

                this.setState({
                    showModalBulkEditSp: false,
                    bulk_edit_field: {},
                    bulk_edit_value: null
                });
                this.deselectAll();
                this.getStudyPeople(this.state.segment);
                analytics.track('study-bulk_edit', {
                    study_id: this.state.study.id,
                    bulk_edit_field_id: bulk_edit_field_id
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: false
                });
            });
    }

    onBulkEditParticipantStatusValueChange(e) {
        this.setState({ bulk_edit_value: e.target.value });
    }

    onBulkEditParticipantSessionValueChange(spot) {
        console.log('onBulkEditParticipantSessionValueChange', spot);
        this.setState({ bulk_edit_value: spot });
    }

    customAttributeChanged(e, study_sp_attribute) {
        //let { study_person } = this.state;

        let val;
        if (study_sp_attribute.type && study_sp_attribute.type == 'checkbox') {
            const checked = [];
            const checkboxes = document.getElementsByName(`study_cspa_${study_sp_attribute.id}`);
            for (let i = 0, n = checkboxes.length; i < n; i++) {
                if (checkboxes[i].checked) {
                    checked.push(checkboxes[i].value);
                }
            }
            console.log('checkboxes', checkboxes, checked);
            val = JSON.stringify(checked);
        } else {
            val = e.target.value;
        }

        this.setState({ bulk_edit_value: val });

        /*let found = study_person.custom_attributes.find(sp_ca => sp_ca.sp_attribute_id == study_sp_attribute.id);
        if (found) {
            console.log('found..')
            found.value = val;
        } else {
            console.log('NOT found..')
            const new_sp_ca = {
                study_id: this.state.study.id,
                study_person_id: this.state.study_person.id,
                sp_attribute_id: study_sp_attribute.id,
                value: val
            };
            study_person.custom_attributes.push(new_sp_ca);
        }



        // detect change, and clear timeout if exists
        if (this.saveDelay_sp_attribute) {
            clearTimeout(this.saveDelay_sp_attribute);
        }

        // set timer for 200ms
        this.saveDelay_sp_attribute = setTimeout(
            function() {
                this.saveStudyPersonCustomAttributeXHR(study_sp_attribute.id, val);
            }.bind(this),
            500
        );
        */
    }

    hasMassSmsEnabled() {
        let enabled = false;
        try {
            enabled = helpers.hasFeatureEnabled(this.props.auth.account, PAID_FEATURE.SMS_MASS);
        } catch (e) {
            helpers.trackError(e);
        }
        return enabled;
    }

    renderBulkEditValueChanger() {
        let render = [];
        if (this.state.bulk_edit_field.id) {
            render.push(<Label mt={3}>New value</Label>);

            if (this.state.bulk_edit_field.id === SP_PARTICIPANT_STATUS) {
                render.push(
                    <Select onChange={this.onBulkEditParticipantStatusValueChange} value={this.state.bulk_edit_value}>
                        {Object.keys(SP_STATUS_KEY_TITLE).map(key => {
                            return <option value={key}>{SP_STATUS_KEY_TITLE[key]}</option>;
                        })}
                    </Select>
                );
            }

            if (this.state.bulk_edit_field.id === SP_PARTICIPANT_LOCATION) {
                if (!this.state.study.location_type) {
                    render.push(
                        <Input
                            value={this.state.bulk_edit_value}
                            onChange={e => this.setState({ bulk_edit_value: e.target.value })}
                        />
                    );
                } else {
                    render.push(
                        <Badge type="warn">
                            A global location is set for this study, so individual locations can not be updated. To
                            change this, go to Study &gt; Scheduling &gt; Calendar Event.
                        </Badge>
                    );
                }
            }

            // add Participant Session Options
            if (this.state.bulk_edit_field.id === SP_PARTICIPANT_SESSION) {
                const sessionSpot = this.getSession(this.state.study_availability_array, this.state.spot);

                render.push(
                    <>
                        <Button
                            type="button"
                            variant="secondary-gray"
                            onClick={e => {
                                e.preventDefault();
                                this.setState({ showSchedulingDropdown: true });
                            }}
                        >
                            {sessionSpot ? sessionSpot : 'Unscheduled'}
                            <FiChevronDown
                                style={{
                                    margin: '3px 0 0 4px'
                                }}
                            />
                        </Button>
                        {this.state.showSchedulingDropdown === true && (
                            <div style={{ position: 'relative' }}>
                                <NiceDropdown
                                    width={200}
                                    positionLeft="0px"
                                    positionTop="4px"
                                    adjustHeight
                                    items={[{ title: 'Unscheduled', id: '' }, ...this.state.study_availability_array]}
                                    // onChange={(spot, event) => { this.updateSpotXHR(spot); event.stopPropagation(); this.setState({showSchedulingDropdown: false}) }}
                                    onChange={(spot, event) => {
                                        // this.updateSpotXHR(spot);
                                        event.stopPropagation();
                                        this.onBulkEditParticipantSessionValueChange(spot);
                                        this.setState({ spot: spot, showSchedulingDropdown: false });
                                    }}
                                    onClose={() => this.setState({ showSchedulingDropdown: false })}
                                    style={{ content: { overflow: 'inherit' } }}
                                    renderBottomStickyButton={
                                        <Button
                                            variant="secondary"
                                            type="button"
                                            onClick={() => this.setState({ showModalSessionDatepicker: true })}
                                            mr={0}
                                        >
                                            <FiCalendar /> Set Custom
                                        </Button>
                                    }
                                />
                            </div>
                        )}
                        <SessionDatepickerModal
                            isOpen={this.state.showModalSessionDatepicker}
                            onClose={() => this.setState({ showModalSessionDatepicker: false })}
                            timezone={this.state.study.timezone}
                            onSubmit={spot => {
                                this.onBulkEditParticipantSessionValueChange(spot);
                                this.setState({ spot, showSchedulingDropdown: false });
                            }}
                        />
                    </>
                );
            }

            // contains cspa key
            else if (this.state.bulk_edit_field.id.indexOf('cspa_') > -1) {
                let render_edit_cspa;
                const cspa_id = this.state.bulk_edit_field.id.substring(5);
                console.log('cspa_id', cspa_id);

                if (this.state.study && this.state.study.custom_sp_attributes) {
                    const found_cspa = this.state.study.custom_sp_attributes.find(
                        study_cspa => study_cspa.id == cspa_id
                    );

                    if (found_cspa) {
                        render_edit_cspa = helpersStudy.renderCspaEdit(
                            found_cspa,
                            this.state.bulk_edit_value,
                            this.customAttributeChanged
                        );
                    }
                }

                render.push(render_edit_cspa);
            }
        } else {
            render.push(
                <Box mt={3} fontSize={1}>
                    Please select a column
                </Box>
            );
        }

        return render;
    }

    getBulkEditableFields() {
        let fields = [];

        // Add Participant Status Field
        fields.push({
            label: true,
            title: 'Study participation'
        });
        fields.push({ id: SP_PARTICIPANT_STATUS, title: 'Participant Status' });

        // Add Participant Session Field
        fields.push({ id: SP_PARTICIPANT_SESSION, title: 'Participant Session' });

        // Add Participant Location Field
        fields.push({ id: SP_PARTICIPANT_LOCATION, title: 'Participant Location' });

        // Add Custom Study Attribute Field
        if (this.state.study && this.state.study.custom_sp_attributes && this.state.study.custom_sp_attributes.length) {
            fields.push({
                label: true,
                title: 'Custom study attributes'
            });
            this.state.study.custom_sp_attributes.forEach(cspa => {
                fields.push({
                    id: `cspa_${cspa.id}`,
                    title: cspa.title
                });
            });
        }

        return fields;
    }

    emailAttachmentUploadButton() {
        const { toastManager } = this.props;
        document.getElementById('email_attachment_upload').click();
        document.getElementById('email_attachment_upload').onchange = e => {
            const formData = new FormData();
            const file = e.target.files[0];
            const current_file_size = file.size;

            console.log('filesize', current_file_size);

            // sum up attachment file sizes, including this new one
            let sum_attachments_file_sizes = 0;
            sum_attachments_file_sizes += current_file_size;

            this.state.email_attachments.forEach(a => {
                if (a && a.filesize) {
                    sum_attachments_file_sizes += a.filesize;
                }
            });

            console.log('sum_attachments_file_sizes', sum_attachments_file_sizes);

            if (sum_attachments_file_sizes > MAX_EMAIL_ATTACHMENTS_SIZE) {
                analytics.track('study-mass_email-attachment-TOO_BIG');
                alert('Sum of all attachments can not exceed ' + helpers.humanFileSize(MAX_EMAIL_ATTACHMENTS_SIZE));
                return;
            }
            formData.append('attachment', file);

            this.setState({ email_attachment_isUploading: true });

            services
                .uploadEmailAttachment(formData)
                .then(file_object => {
                    //console.log(file_object);
                    let { email_attachments } = this.state;

                    // add file size to display
                    file_object.filesize = current_file_size;

                    email_attachments.push(file_object);
                    //console.log(email_attachments);
                    /*{
                        'filename': 'awhattt'+email_attachments.length+'.jpeg',
                        'filepath': 'https://google.com'
                    }*/
                    this.setState({
                        email_attachments,
                        email_attachment_isUploading: false
                    });

                    analytics.track('study-mass_email-attachment-add');
                })
                .catch(error => {
                    this.setState({
                        email_attachment_isUploading: false
                    });

                    const errorText = services.parseAndTrackXhrErrors(error);

                    toastManager.add(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                });
        };
    }

    tableColumnsOrdered() {
        const { tableColumns_all, tableColumns_order } = this.state;

        tableColumns_all.forEach(tc => {
            // see if there are any ordered columns
            if (tableColumns_order && tableColumns_order[tc.id] !== undefined) {
                // set order, otherwise null
                tc.order = tableColumns_order[tc.id];
            } else {
                tc.order = undefined;
            }
        });

        tableColumns_all.sort(function(a, b) {
            if (a.order === b.order) {
                return 0;
            } else {
                return a.order - b.order;
            }
        });

        //console.log('tableColumnsOrdered', tableColumns_all);

        return tableColumns_all;
    }

    resetTableColumns() {
        this.saveColumnsOrder(DEFAULT_COLUMNS_ORDER);
        this.saveColumnsShowHide(DEFAULT_COLUMNS_HIDDEN);
        location.reload();
    }

    renderIntegrations() {
        let render;
        let missionInfo = {};

        try {
            if (this.state.study.integrations && this.state.study.integrations.length) {
                missionInfo.projectName = this.state.study.integrations[0].metadata.projectName;
                missionInfo.missionName = this.state.study.integrations[0].metadata.name;
            }
            render = missionInfo.missionName ? (
                <a href={config.DSCOUT_APP_URL} target="_blank">
                    <Flex
                        className="border rounded-12 border-clickable"
                        ml={3}
                        sx={{ 'align-items': 'center', padding: '8px 20px' }}
                    >
                        <Box>
                            <img src="/dscout.png" style={{ width: '26px' }} />
                        </Box>
                        <Box flexShrink={0} ml={'16px'}>
                            <Box className="text-secondary-dark fs-12 ellipsis" sx={{ maxWidth: '250px' }}>
                                {missionInfo.projectName ? missionInfo.projectName : 'Unknown project'}
                            </Box>
                            <Box
                                className="medium text-primary ellipsis"
                                sx={{ margin: '2px 0 0 0', maxWidth: '250px' }}
                            >
                                {missionInfo.missionName}
                            </Box>
                            {/*<Box className="fs-12 medium"><a href={config.DSCOUT_APP_URL} target="_blank"><FiExternalLink style={{margin: '-4px 8px 0 0'}}/>View details in dscout</a></Box>*/}
                        </Box>
                    </Flex>
                </a>
            ) : null;
        } catch (e) {
            helpers.trackError(e);
        }

        return render;
    }

    renderViewingUsers() {
        let render = [];

        let users_viewing = cloneDeep(this.state.users_viewing);

        if (env == 'development') {
            users_viewing.push({
                user_id: 999999999,
                name: 'Manually added for testing locally.'
            });
        }

        try {
            const avatar_style = { width: '24px', height: '24px', margin: '0 0 0 4px' };
            if (users_viewing) {
                users_viewing.map(uv => {
                    if (uv && uv.user_id && uv.user_id != this.props.auth.user.id) {
                        // @todo add initials not FiUser
                        // add tooltip

                        render.push(
                            <Box>
                                {uv.avatar ? (
                                    <img
                                        src={uv.avatar}
                                        data-tip
                                        data-for={`user-viewing-${uv.user_id}`}
                                        style={avatar_style}
                                        alt={uv.name}
                                        className="basic-avatar"
                                    />
                                ) : (
                                    <span data-tip data-for={`user-viewing-${uv.user_id}`} className="basic-avatar">
                                        {helpers.getUserInitials(uv)}
                                    </span>
                                )}
                                <ReactTooltip id={`user-viewing-${uv.user_id}`} effect="solid" place="top">
                                    <Box>{uv.name}</Box>
                                </ReactTooltip>
                            </Box>
                        );
                    }
                });
            }
        } catch (e) {
            helpers.trackError(e);
        }

        // we need to move this container a bit up if we are rendering the "integrations" container
        let positionTop = '22px';
        if (this.state.study.integrations && this.state.study.integrations.length) {
            positionTop = '12px';
        }

        return <Flex sx={{ gridGap: '4px' }}>{render}</Flex>;
    }

    // to show the selected session
    getSession(availabilities, spot) {
        try {
            if (spot) {
                //console.log('sess', spot ,this.state.study.timezone)
                return moment.tz(spot, this.state.study.timezone).format(MOMENT_HUMAN_DATE);
            }
        } catch (e) {
            helpers.trackError(e);
        }
    }

    bulkDownloadFiles() {
        const { toastManager } = this.props;

        /*
            bulkFileDownloadStatus
            0/false = not doing anything
            1 = generating
            2 = ready for download
        */
        this.setState({ bulkFileDownloadStatus: 1 });

        service_studies
            .bulkDownloadFile(this.state.study.id)
            .then(download_link => {
                toastManager.add('Your bulk file download is ready', {
                    appearance: 'success',
                    autoDismiss: true
                });
                this.setState({
                    bulkFileDownloadStatus: 2,
                    bulkFileDownloadLink: download_link
                });
            })
            .catch(error => {
                this.setState({ bulkFileDownloadStatus: 0 });
                const errorText = services.parseAndTrackXhrErrors(error);
                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    renderBulkDownload() {
        if (this.state.bulkFileDownloadStatus == 2) {
            return (
                <a href={this.state.bulkFileDownloadLink} target="_blank">
                    <Button type="button" variant="secondary" className="success">
                        <FiDownloadCloud /> Download Files (.zip)
                    </Button>
                </a>
            );
        } else {
            return (
                <Button
                    type="button"
                    variant="secondary"
                    onClick={this.bulkDownloadFiles}
                    disabled={this.state.bulkFileDownloadStatus == 1}
                >
                    {this.state.bulkFileDownloadStatus == 1 ? 'Processing...' : 'Export Bulk Files'}
                </Button>
            );
        }
    }

    getDscoutMissions() {
        if (!helpers.hasFeatureEnabled(this.props.auth.account, PAID_FEATURE.DSCOUT_INTEGRATION)) {
            return;
        }

        const { toastManager } = this.props;

        services
            .getDscoutMissions()
            .then(missions => {
                this.setState({
                    dscoutMissions: missions
                });
            })
            .catch(error => {
                console.error(error);
                const errorText = services.parseAndTrackXhrErrors(error);
                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    renderDscoutMissions() {
        // check if study already has 1 dscout mission linked
        let integrated_dscout_mission = null;
        if (this.state.study.integrations.length) {
            integrated_dscout_mission = this.state.study.integrations.find(
                si => si.integration_id == INTEGRATION_DSCOUT
            );
        }
        //console.log('integrated_dscout_mission', integrated_dscout_mission)

        let options = [<option></option>]; //add one blank option
        Object.keys(this.state.dscoutMissions).forEach(mission_type => {
            options.push(
                this.state.dscoutMissions[mission_type].map(dsm => {
                    let should_render = true;
                    if (integrated_dscout_mission && integrated_dscout_mission.external_id != dsm.id) {
                        should_render = false;
                    }

                    const projectName = dsm.project ? dsm.project.name : 'Unknown project';

                    return should_render ? (
                        <option value={`${mission_type}${dscout_mission_delimiter}${dsm.id}`}>
                            [{projectName}] {dsm.name} ({mission_type})
                        </option>
                    ) : null;
                })
            );
        });

        return (
            <>
                {integrated_dscout_mission ? (
                    <Box mb={3}>
                        You have the <b>{integrated_dscout_mission.metadata.name}</b> mission linked.
                    </Box>
                ) : (
                    <Box mb={3} className="text-secondary-dark">
                        You will only see <b>Launched</b> missions, which <b>you have access to</b>. Only <b>one</b>{' '}
                        dscout mission can be linked to a Panelfox study.
                    </Box>
                )}
                <Label>Missions</Label>
                <Select id="dscout-missions">{options}</Select>
            </>
        );
    }

    getStudyStateCountBadge(ss, isActive) {
        return (
            <span className={`label-highlight-number ${isActive ? 'active' : ''}`} style={{ marginLeft: '8px' }}>
                {helpers.numberFormat(ss.count)}
            </span>
        );
    }

    getStudyStateTitleWithCount(ss, isActive) {
        return (
            <>
                <span style={{ maxWidth: '200px' }} className="ellipsis va-top inline-block">
                    {ss.title}
                </span>
                {this.getStudyStateCountBadge(ss, isActive)}
            </>
        );
    }

    getStudyStatesList() {
        return this.state.study.study_states.map(ss => {
            return {
                id: ss.id,
                title: ss.title,
                suffix: this.getStudyStateCountBadge(ss),
                order: ss.order
            };
        });
    }

    renderSavedTabsLabel() {
        let segmentTitle = '';
        if (this.state.segment) {
            let ssFound = this.state.study.study_states.find(ss => ss.id == this.state.segment);
            //console.log(ssFound, this.state.segment, this.state.study.study_states);
            if (ssFound) {
                segmentTitle = this.getStudyStateTitleWithCount(ssFound, true);
            } else {
                segmentTitle = 'Segment not found';
            }
        }
        return segmentTitle;
    }

    renderSavedTabs() {
        return (
            <Box sx={{ display: 'inline-block' }}>
                <span className="vertical-divider"></span>
                <Box sx={{ position: 'relative', display: 'inline-block' }}>
                    <Button variant="transparent" onClick={() => this.setState({ showTabsDropdown: true })}>
                        <span className="bold">Segment:</span> {this.renderSavedTabsLabel()}
                        <FiChevronDown style={{ margin: '4px 0 0 8px' }} />
                    </Button>
                    {this.state.showTabsDropdown && (
                        <NiceDropdown
                            defaultLabel="Show"
                            showSearch
                            adjustHeight
                            height="320px"
                            width="320px"
                            value={this.state.segment}
                            canReorder={true}
                            revealEllipsis={true}
                            positionLeft="0px"
                            onReorder={newOrder_byId => {
                                let { study } = this.state;
                                let newSSOrder = [];
                                newOrder_byId.forEach((id, index) => {
                                    let found_ss = study.study_states.find(ss => ss.id == id);
                                    if (found_ss) {
                                        // set new order
                                        found_ss.order = index;
                                        newSSOrder.push(found_ss);
                                    }
                                });
                                //console.log('newSSOrder', newSSOrder)
                                study.study_states = newSSOrder;
                                this.setState({ study }, () => {
                                    this.changeTabOrder(newSSOrder);
                                });
                            }}
                            onClose={() => {
                                this.setState({ showTabsDropdown: false });
                            }}
                            items={this.getStudyStatesList()}
                            onChange={(segmentId, b, c) => {
                                //this.handleDisplayedTableColumns(columnId, isChecked);
                                console.log('segments onChange', segmentId, b, c);
                                this.setSegment(segmentId);
                            }}
                            renderBottomStickyButton={
                                <Button
                                    variant="secondary"
                                    onClick={() => this.onBoardColumnNew(this.state.screenerFilters)}
                                    mr={0}
                                >
                                    + Add Segment
                                </Button>
                            }
                        />
                    )}
                </Box>
                <span className="vertical-divider"></span>
            </Box>
        );
    }

    render() {
        const avatar_style = { width: '16px', height: '16px', margin: '2px 4px 0 0' };
        let select_modal_assign_users_list = this.state.account_users.map(account_user => {
            return {
                id: account_user.user.id,
                title: account_user.user.name
            };
        });
        select_modal_assign_users_list.unshift({ id: -1, title: 'Un-assign' });

        const ssDropdownOptions = [];
        if (this.state.study_states) {
            this.state.study_states.forEach(ss => {
                if ((ss.lock && ss.toggle) || ss.type == 'basic' || ss.type == 'candidates') {
                    ssDropdownOptions.push({ id: ss.id, title: ss.title });
                }
            });
        }

        const SPGroupHeader = styled(Flex)`
            background: #fff;
            border-bottom: 1px solid #eee;
            flex-direction: column;
            width: 100%;
            font-weight: 500;
            color: #666;
            font-size: 14px;
            padding: 8px 0;
            position: sticky;
            position: -webkit-sticky;
            top: 0;
            z-index: 1;
        `;

        const SPGroupBody = styled(Flex)`
            flex-direction: column;
            //width: 100%;
            //padding-bottom: 32px;
            font-size: 14px;
        `;

        // check if screener has any data
        let screener_has_data = false;
        this.state.study.screener.forEach(page => {
            page.forEach(question => {
                screener_has_data = true;
            });
        });

        let mass_message_items = [];

        if (screener_has_data) {
            mass_message_items.push({
                title: 'Send screener',
                onClick: () => {
                    this.clickSendEmail('screen');
                }
            });
        }

        this.state.study.screeners.forEach(screener => {
            mass_message_items.push({
                icon: helpersScreener.getScreenerIcon(screener),
                title: `Send screener "${screener.title}"`,
                onClick: () => {
                    this.clickSendEmail('screen', { screener_id: screener.id });
                }
            });
        });

        mass_message_items = mass_message_items.concat([
            {
                title: 'Send scheduling request',
                onClick: () => {
                    this.clickSendEmail('schedule');
                }
            },
            {
                title: 'Send scheduling confirmation',
                onClick: () => {
                    this.clickSendEmail('confirmation');
                }
            },
            {
                title: 'Send custom email',
                onClick: () => {
                    this.clickSendEmail('custom');
                }
            },
            {
                id: 'divider',
                divider: true
            }
        ]);

        const smsMessageItem = {
            title: 'Send SMS',
            disabled: true,
            suffix: <UpgradeButton type="sms-mass" />,
            onClick: () => {
                this.setState({ showModalSMSMessage: true });
            }
        };

        // if user has sms-mass feature enabled, then enable the button
        if (this.hasMassSmsEnabled()) {
            smsMessageItem.disabled = false;
            smsMessageItem.suffix = undefined;
        }

        mass_message_items = mass_message_items.concat([smsMessageItem]);

        let mass_email_actions = [
            {
                title: `Send test email`,
                icon: <FiSend />,
                onClick: e => {
                    const email_action = 'test';
                    this.handleModalSendCampaign_review(email_action);
                }
            },
            {
                title: `Schedule to send`,
                icon: <FiCalendar />,
                onClick: e => {
                    this.setState({ showModalScheduleSend: true });
                }
            },
            {
                title: `Edit email`,
                icon: <FiEdit />,
                onClick: e => {
                    this.setState({ showModalSendCampaign_editMode: true });
                }
            }
        ];
        if (helpersStudy.shouldShowEmailAttachmentButton(this.props.auth.account)) {
            mass_email_actions.push({
                title: `Add attachment`,
                disabled: this.state.email_attachment_isUploading,
                icon: <FiPaperclip />,
                onClick: e => {
                    //this.setState({ showModalSendCampaign_editMode: true });
                    this.emailAttachmentUploadButton();
                }
            });
        }

        return (
            <Flex style={{ width: '100%' }}>
                <AppPageWrapper>
                    <Helmet>
                        <title>{this.state.study.title}</title>
                    </Helmet>

                    <AppPageWrapperSectionHeader className="experience experience-no-subnav">
                        <Box>
                            <BreadcrumbBack to="/studies">Back to all studies</BreadcrumbBack>
                            <BreadcrumbTitle isLoading={this.state.study_loading} title={this.state.study.title} />
                        </Box>
                        <Flex sx={{ position: 'absolute', top: '12px', right: '32px' }}>
                            {this.renderViewingUsers()}
                            {this.renderIntegrations()}
                        </Flex>
                    </AppPageWrapperSectionHeader>

                    <Flex sx={{ width: '100%', justifyContent: 'space-between' }}>
                        <StudyPageSubNav
                            study_id={this.props.match.params.id}
                            study={this.state.study}
                            onClickParticipant={sp => {
                                this.handleCardClick(sp.id);
                            }}
                        />
                    </Flex>
                    <AppPageWrapperSectionSubHeader
                        style={{ background: '#fff', flexDirection: 'column', paddingBottom: '4px' }}
                    >
                        {/*<Flex sx={{ justifyContent: 'space-between', gridGap: 4, width: '100%' }}>
                            <Box style={{ width: '100%' }}>
                                <Flex
                                    sx={{ flexDirection: 'row', overflowX: 'scroll', marginTop: '32px' }}
                                    className={'tertiary-nav'}
                                >
                                    {this.state.study &&
                                        this.state.study.study_states &&
                                        this.state.study.study_states.map(ss => (
                                            <HeaderSubnavButton
                                                className={this.state.segment == ss.id && 'active'}
                                                onClick={() => this.setSegment(ss.id)}
                                            >
                                                <div className="subnav-label">{ss.title}</div>
                                                <div className="subnav-number">{helpers.numberFormat(ss.count)}</div>
                                            </HeaderSubnavButton>
                                        ))}

                                    <HeaderSubnavButton>
                                        <div className="black relative">
                                            <Button
                                                type="button"
                                                variant="link"
                                                className="va-top"
                                                sx={{ height: '20px', minHeight: 'auto' }}
                                                onClick={() => {
                                                    this.onBoardColumnNew(this.state.screenerFilters);
                                                }}
                                            >
                                                <FiPlus /> Add tab
                                            </Button>
                                        </div>
                                    </HeaderSubnavButton>
                                </Flex>
                            </Box>
                        </Flex>*/}

                        <Flex mt={'16px'} mb={0} justifyContent="space-between">
                            {/* <span style={{color:'red'}}>still working on this page</span> */}
                            <Box>
                                <Flex flexDirection="column">
                                    <Box mb={2}>
                                        <Box
                                            style={{
                                                color: 'black',
                                                position: 'relative',
                                                display: 'inline-block',
                                                verticalAlign: 'top',
                                                marginTop: '5px'
                                            }}
                                        >
                                            <span className="bold">
                                                Found {helpers.numberFormat(this.state.study_people.total)}{' '}
                                                {this.state.study_people.total == 1 ? 'person' : 'people'}{' '}
                                                {/*this.state.screenerFilters.length > 0 ? (
                                                    <span style={{ color: '#999' }}>
                                                        {' '}
                                                        with {this.state.screenerFilters.length} filter
                                                        {this.state.screenerFilters.length == 1 ? '' : 's'}
                                                    </span>
                                                ) : (
                                                    ''
                                                )*/}
                                            </span>
                                        </Box>

                                        {this.renderSavedTabs()}

                                        {this.state.study_people.data.length > 0 && (
                                            <>
                                                <Box sx={{ position: 'relative', display: 'inline-block' }}>
                                                    <Button
                                                        variant="secondary-gray"
                                                        //ml={0}
                                                        mr={3}
                                                        onClick={() => {
                                                            this.setState({
                                                                showEmailMoreDropdown: true
                                                            });
                                                        }}
                                                    >
                                                        <FiSend /> Send message{' '}
                                                        <FiChevronDown
                                                            style={{
                                                                marginRight: 0
                                                            }}
                                                        />
                                                    </Button>
                                                    {this.state.showEmailMoreDropdown == true && (
                                                        <NiceDropdown
                                                            positionLeft="0px"
                                                            width="240px"
                                                            onClose={() => {
                                                                this.setState({
                                                                    showEmailMoreDropdown: false
                                                                });
                                                            }}
                                                            items={mass_message_items}
                                                        />
                                                    )}
                                                </Box>
                                                <Box style={{ display: 'inline-block', position: 'relative' }}>
                                                    <Button
                                                        variant="secondary-gray"
                                                        mr={0}
                                                        onClick={() => {
                                                            this.setState({
                                                                showFiltersMoreDropdown: true
                                                            });
                                                        }}
                                                    >
                                                        Bulk Actions{' '}
                                                        <FiChevronDown
                                                            style={{
                                                                marginRight: 0
                                                            }}
                                                        />
                                                    </Button>
                                                    {this.state.showFiltersMoreDropdown == true && (
                                                        <NiceDropdown
                                                            onClose={() => {
                                                                this.setState({
                                                                    showFiltersMoreDropdown: false
                                                                });
                                                            }}
                                                            hasSubmenu
                                                            width={'300px'}
                                                            items={[
                                                                {
                                                                    id: 'invite-to-dscout',
                                                                    title: `Invite to dscout`,
                                                                    icon: <FiExternalLink />,
                                                                    hide: !helpers.hasFeatureEnabled(
                                                                        this.props.auth.account,
                                                                        PAID_FEATURE.DSCOUT_INTEGRATION
                                                                    ),
                                                                    disabled: this.props.auth.user.dscout_token
                                                                        ? false
                                                                        : true,
                                                                    tooltip: this.props.auth.user.dscout_token ? (
                                                                        undefined
                                                                    ) : (
                                                                        <div>
                                                                            Missing dscout token, please reach out to
                                                                            support@panelfox.io to set this up.
                                                                        </div>
                                                                    ),
                                                                    onClick: () => {
                                                                        this.setState({
                                                                            showModalDscoutMissions: true
                                                                        });
                                                                    }
                                                                },
                                                                {
                                                                    title: `Edit respondents`,
                                                                    icon: <FiEdit />,
                                                                    onClick: () => {
                                                                        if (this.checkIfAnyoneChecked()) {
                                                                            this.setState({
                                                                                showModalBulkEditSp: true
                                                                            });
                                                                        }
                                                                    }
                                                                },
                                                                {
                                                                    title: `Pay respondents`,
                                                                    icon: <FiDollarSign />,
                                                                    onClick: () => {
                                                                        //this.setState({ showModalAssignUser: true })
                                                                        //if (!this.state.p_checked.length) {
                                                                        // mimic "check all"
                                                                        //}
                                                                        this.bulkPayXHR();
                                                                    }
                                                                },
                                                                {
                                                                    title: 'Add to another study',
                                                                    icon: <FiLayers />,
                                                                    onClick: () => this.openShareStudyPeopleModal()
                                                                },
                                                                {
                                                                    title: `Upload respondents`,
                                                                    icon: <FiUploadCloud />,
                                                                    onClick: () => {
                                                                        this.handleOpenModalParticipantImport();
                                                                    }
                                                                },
                                                                {
                                                                    title: `Assign to user`,
                                                                    icon: <FiUserPlus />,
                                                                    onClick: () => {
                                                                        this.setState({ showModalAssignUser: true });
                                                                    }
                                                                },

                                                                {
                                                                    title: `Delete responses`,
                                                                    icon: <FiFileMinus />,
                                                                    feature_launched_at: moment(
                                                                        '10-25-2022',
                                                                        'MM-DD-YYYY'
                                                                    ),
                                                                    submenu: this.state.study.screeners.map(
                                                                        screener => {
                                                                            return {
                                                                                title: screener.title,
                                                                                onClick: () => {
                                                                                    this.handleRemoveScreenerResponse(
                                                                                        screener
                                                                                    );
                                                                                }
                                                                            };
                                                                        }
                                                                    )
                                                                },
                                                                {
                                                                    title: `Visualize data`,
                                                                    icon: <FiPieChart />,
                                                                    feature_launched_at: moment(
                                                                        '04-01-2022',
                                                                        'MM-DD-YYYY'
                                                                    ),
                                                                    //hide: true | false,
                                                                    submenu: this.state.study.screeners.map(
                                                                        screener => {
                                                                            return {
                                                                                title: screener.title,
                                                                                onClick: () => {
                                                                                    this.setState({
                                                                                        showModalDataViz: true,
                                                                                        datavizScreenerId: screener.id
                                                                                    });
                                                                                }
                                                                            };
                                                                        }
                                                                    )
                                                                },

                                                                {
                                                                    title: `Export`,
                                                                    icon: <FiDownloadCloud />,
                                                                    onClick: () => {
                                                                        this.setState({
                                                                            showModalExportCsv: true,
                                                                            bulkFileDownloadStatus: 0
                                                                        });
                                                                    }
                                                                },
                                                                { id: 'divider', divider: true },
                                                                {
                                                                    title: `Delete from study`,
                                                                    icon: <FiXCircle />,
                                                                    color: 'red',
                                                                    onClick: () => {
                                                                        //alert('del');
                                                                        this.handleRemoveStudyPeople();
                                                                    }
                                                                }
                                                            ]}
                                                        />
                                                    )}
                                                </Box>
                                            </>
                                        )}

                                        {!this.state.study_people_loading && !this.state.study_people.data.length && (
                                            <Button
                                                type="button"
                                                variant="secondary-gray"
                                                ml={3}
                                                onClick={() => this.handleOpenModalParticipantImport()}
                                            >
                                                <FiUploadCloud /> Upload respondents
                                            </Button>
                                        )}
                                    </Box>
                                </Flex>
                            </Box>
                            <Box sx={{ position: 'relative' }}>
                                {/* <Switch
                                    className="switch-small"
                                    checked={this.state.showScreenerAnswersColumns}
                                    onClick={this.toggleShowScreenerAnswers}
                                    id="show-screener-answers"
                                    //name="show-screener-answers"
                                />
                                <Label for="show-screener-answers">Show Full Screener Answers</Label> */}
                                <Flex flexDirection="row" fontSize={1}>
                                    <Box>
                                        <InputSearch
                                            mr={3}
                                            type="text"
                                            value={this.state.search_keyword}
                                            onChange={this.onSearchKeywordChange}
                                            placeholder="Search Name, Email, or Phone"
                                            sx={{ width: '250px' }}
                                        />
                                    </Box>

                                    <Box>
                                        <Button
                                            variant="secondary-gray"
                                            mr={0}
                                            onClick={() => {
                                                this.setState({
                                                    showScreenerFilters: true
                                                });
                                            }}
                                        >
                                            <FiFilter /> Filters{' '}
                                            {this.state.screenerFilters.length > 0 ? (
                                                <span
                                                    className="label-highlight-number active"
                                                    style={{ marginLeft: '4px' }}
                                                >
                                                    {this.state.screenerFilters.length}
                                                </span>
                                            ) : (
                                                ''
                                            )}
                                        </Button>
                                    </Box>

                                    <Box ml={3} sx={{ position: 'relative' }}>
                                        <Button
                                            variant="secondary-gray"
                                            className="secondary-icon"
                                            onClick={() => this.setState({ showDisplayedCustomColumns: true })}
                                        >
                                            <FiColumns />
                                            <FiChevronDown style={{ marginRight: 0 }} />
                                            {/*<span className="arrow-down-dark" style={{ margin: '-2px 0 0 2px' }} />*/}
                                        </Button>
                                        {this.state.showDisplayedCustomColumns && (
                                            <NiceDropdown
                                                showSearch
                                                adjustHeight
                                                height="320px"
                                                width="320px"
                                                listType="toggle"
                                                canReorder={true}
                                                revealEllipsis={true}
                                                positionRight="0px"
                                                onReorder={newOrder => {
                                                    console.log('reorder', newOrder);
                                                    //this.setState({tableColumns_all: newItems})
                                                    let newOrderById = {};
                                                    newOrder.forEach((item, index) => {
                                                        newOrderById[item.id] = index;
                                                    });
                                                    console.log('newOrderById', newOrderById);
                                                    this.saveColumnsOrder(newOrderById);
                                                }}
                                                onClose={() => {
                                                    this.setState({
                                                        showDisplayedCustomColumns: false
                                                    });
                                                }}
                                                items={this.tableColumnsOrdered()}
                                                onChange={(columnId, isChecked) => {
                                                    this.handleDisplayedTableColumns(columnId, isChecked);
                                                }}
                                                renderBottomStickyButton={
                                                    <Button variant="secondary" onClick={this.resetTableColumns} mr={0}>
                                                        Reset Columns
                                                    </Button>
                                                }
                                            />
                                        )}
                                    </Box>
                                    {/* <Box>
                                        <Box sx={{position: 'relative', display: 'inline-block'}}>
                                            <Button
                                                variant="tertiary"
                                                mr={0}
                                                onClick={() => {
                                                    this.setState({
                                                        showDisplayDropdown: true
                                                    });
                                                }}
                                            >
                                                Display
                                                <FiChevronDown
                                                    style={{
                                                        marginRight: 0
                                                    }}
                                                />
                                            </Button>

                                            {this.state.showDisplayDropdown == true && (
                                                <NiceDropdown
                                                    positionRight="0"
                                                    width={'240px'}
                                                    onClose={() => {
                                                        this.setState({
                                                            showDisplayDropdown: false
                                                        });
                                                    }}
                                                    items={[

                                                    ]}
                                                />
                                            )}
                                        </Box>
                                    </Box> */}
                                </Flex>
                            </Box>
                        </Flex>
                    </AppPageWrapperSectionSubHeader>

                    <AppPageWrapperSectionBody style={{ margin: '0 32px', width: 'calc(100% - 64px)' }}>
                        {this.state.massActionChecked && (
                            <ActionPopup>
                                <Flex justifyContent="space-between" style={{ position: 'relative', width: '400px' }}>
                                    <Box>
                                        <Button
                                            variant="transparent"
                                            color="white"
                                            onClick={this.onBoardDeselectAllCards}
                                        >
                                            <FiX /> Deselect
                                        </Button>
                                    </Box>
                                    <Box style={{ textAlign: 'left' }}>
                                        <Button
                                            variant="transparent"
                                            color="white"
                                            onClick={() => this.setState({ massActionMoveDropdown: true })}
                                        >
                                            <FiArrowRight /> Move to column
                                        </Button>
                                        {this.state.massActionMoveDropdown && (
                                            <NiceDropdown
                                                width={120}
                                                positionRight="0px"
                                                positionTop="auto"
                                                positionBottom="38px"
                                                onClose={() => this.setState({ massActionMoveDropdown: false })}
                                                items={ssDropdownOptions}
                                                onChange={columnId => this.handleMassActionClick(columnId)}
                                            />
                                        )}
                                    </Box>
                                </Flex>
                            </ActionPopup>
                        )}
                        <StudyParticipantsList
                            adminFacing
                            isLoading={this.state.study_people_loading && !this.state.study_people_loading_more}
                            auth={this.props.auth}
                            study={this.state.study}
                            study_people={this.state.study_people}
                            onClickParticipant={sp => {
                                this.handleCardClick(sp.id);
                            }}
                            p_checked={this.state.p_checked}
                            checkAllOrVisibleStatus={this.state.checkAllOrVisibleStatus}
                            onCheckParticipant={this.toggleCheckbox}
                            handleCheckAllDropdownChange={this.handleCheckAllDropdownChange}
                            theme={this.props.theme}
                            deselectAll={this.deselectAll}
                            loadMoreStudyPeople={this.loadMoreStudyPeople}
                            onChangePaginationLimit={this.onChangePaginationLimit}
                            pagination_limit={this.state.pagination_limit}
                            study_people_loading_more={this.state.study_people_loading_more}
                            sort={this.state.sort}
                            onChangeSort={sort => {
                                //console.log('NEW SORT DIR', sort);
                                this.setState({ sort }, () => {
                                    this.getStudyPeople(this.state.segment);
                                });
                            }}
                            showHideColumns={this.tableColumnsOrdered()}
                            onSearchKeywordChange={this.onSearchKeywordChange}
                            onParticipantUnmask={this.onParticipantUnmask}
                        />
                    </AppPageWrapperSectionBody>

                    <NiceModal
                        title={`Upload panelists into study`}
                        isOpen={
                            this.state.panels && this.state.panels.length > 0 && this.state.showModalParticipantImport
                        }
                        shouldCloseOnOverlayClick={false}
                        shouldCloseOnEsc={false}
                        onRequestClose={this.handleCloseModalParticipantImport}
                        style={{ content: { width: '980px' } }}
                    >
                        <UploadParticipants
                            study_id={this.state.study.id}
                            onClose={this.handleCloseModalParticipantImport}
                            onDone={() => {
                                this.handleCloseModalParticipantImport();
                                // its an async job so no need to refresh yet
                                // this.getStudy();
                            }}
                            onUpdatePanels={this.onUpdatePanels}
                            panels={this.state.panels}
                        />
                    </NiceModal>

                    <ReactModal
                        isOpen={this.state.showModalStudyIntercept}
                        shouldCloseOnOverlayClick
                        onRequestClose={this.handleCloseModalStudyIntercept}
                    >
                        <H1>Install</H1>
                        <StudyScript study={this.state.study} auth={this.props.auth} />
                    </ReactModal>

                    <ReactModal
                        isOpen={this.state.showModalStudyParticipant}
                        shouldCloseOnOverlayClick
                        onRequestClose={this.handleCloseModalStudyParticipant}
                        style={{ content: { width: '1140px', overflow: 'inherit', height: '100%' } }}
                    >
                        {this.state.selectedStudyPerson && this.state.selectedStudyPerson.id ? (
                            <ParticipantNotes
                                parentCallback={this.modalParticipantNotesCallbackFunction}
                                study_person={this.state.selectedStudyPerson}
                                study={this.state.study}
                                auth={this.props.auth}
                                onSearchKeywordChange={e => {
                                    this.onSearchKeywordChange(e);
                                    this.handleCloseModalStudyParticipant();
                                }}
                            />
                        ) : (
                            <Box style={{ width: '100%', height: '120px' }}>
                                <LoadingWrapper>
                                    <LoadingIndicator />
                                </LoadingWrapper>
                            </Box>
                        )}
                    </ReactModal>

                    <ReactModal
                        isOpen={this.state.showModalParticipantPay}
                        shouldCloseOnOverlayClick
                        onRequestClose={this.handleCloseModalParticipantPay}
                    >
                        <ParticipantPay
                            paymentMethod={INCENTIVE_PAYMENT_METHOD.TREMENDOUS}
                            parentCallback={this.modalParticipantPayCallbackFunction}
                            study_person={this.state.selectedStudyPerson}
                            study={this.state.study}
                            auth={this.props.auth}
                        />
                    </ReactModal>

                    <ReactModal
                        isOpen={this.state.showModalSendCampaign}
                        shouldCloseOnOverlayClick
                        onRequestClose={() => this.handleCloseModalSendCampaign(true)}
                        style={{ content: { width: '100%', maxWidth: '1100px' } }}
                        // style={{ content: { width: '100%', maxWidth: '1400px' } }}
                    >
                        {this.state.showModalSendCampaign_editMode && (
                            <Box p={4}>
                                <H1>Edit Mass Email</H1>

                                <Flex className="form-wrapper2" width="100%" flexDirection="column">
                                    <Flex sx={{ gridGap: '32px' }} my="24px">
                                        <Box flex="2">
                                            <Box className="form-label">Email Subject</Box>
                                            <Box>
                                                <Input
                                                    type="text"
                                                    name="email_custom_subject"
                                                    value={this.state.sendCampaign.subject}
                                                    onChange={e => {
                                                        this.handleModalSendCampaign_stateUpdate(
                                                            'subject',
                                                            e.target.value
                                                        );
                                                    }}
                                                    placeholder="Email Subject"
                                                />
                                            </Box>
                                        </Box>
                                        <VariablesHelper
                                            study={this.state.study}
                                            templateType={this.state.sendCampaign.templateType}
                                            elementId="email_custom_body"
                                            content={this.state.sendCampaign.content}
                                            onChange={val => {
                                                this.handleModalSendCampaign_stateUpdate('content', val);
                                            }}
                                        />
                                    </Flex>
                                </Flex>

                                {/* <Textarea
                                    id="email_screener_body"
                                    name="email_screener_body"
                                    placeholder="Email Body"
                                    value={this.state.study.email_screener_body}
                                    onChange={this.onFormChange}
                                    height="200px"
                                /> */}

                                <EmailEditor
                                    type={this.state.sendCampaign.templateType}
                                    editorData={this.state.sendCampaign.content}
                                    onChange={this.onChangeEditor}
                                    allowDscoutButton
                                    allowScreenerButton
                                    onTemplate={template => this.onTemplate(template)}
                                />

                                <br />
                                <br />
                                <br />

                                {/* <Textarea
                                    sx={{ height: '200px' }}
                                    id="sendCampaign-content"
                                    value={this.state.sendCampaign.content}
                                    onChange={e => {
                                        this.handleModalSendCampaign_stateUpdate('content', e.target.value);
                                    }}
                                ></Textarea> */}

                                {/* <Label mt={3}>From Name</Label>
                                <Input
                                    width={300}
                                    value={this.state.sendCampaign.from_name}
                                    onChange={e => {
                                        this.handleModalSendCampaign_stateUpdate('from_name', e.target.value);
                                    }}
                                />

                                {this.state.allowSenderSignatureFromEmail ? (
                                    <>
                                        <Label mt={3}>From Email</Label>
                                        <Input
                                            width={300}
                                            value={this.state.sendCampaign.from_email}
                                            onChange={e => {
                                                this.handleModalSendCampaign_stateUpdate('from_email', e.target.value);
                                            }}
                                        />
                                    </>
                                ) : (
                                    ''
                                )} */}
                            </Box>
                        )}
                        {!this.state.showModalSendCampaign_editMode &&
                            (this.state.emailCampaignPreview_loading ? (
                                <Box mb={3} style={{ width: '100%', height: '120px' }}>
                                    <LoadingWrapper>
                                        <LoadingIndicator />
                                    </LoadingWrapper>
                                </Box>
                            ) : (
                                <Box p={4} background="#f7f7f7">
                                    {this.state.emailCampaignPreview ? (
                                        this.renderEmailCampaignPreview(this.state.emailCampaignPreview)
                                    ) : (
                                        <div>Email preview did not load. Likely the candidate chose to "Opt Out".</div>
                                    )}
                                </Box>
                            ))}

                        <Flex
                            justifyContent="space-between"
                            px={4}
                            py={3}
                            style={{
                                position: 'sticky',
                                bottom: 0,
                                background: '#f7f7f7',
                                borderTop: '1px solid #eee',
                                zIndex: 100
                            }}
                        >
                            {!this.state.showModalSendCampaign_editMode && (
                                <Flex flexDirection="column" sx={{ width: '100%', textAlign: 'right' }}>
                                    <input type="file" id="email_attachment_upload" hidden />

                                    {(this.state.email_attachments.length > 0 ||
                                        this.state.email_attachment_isUploading == true) && (
                                        <Box mb={3} sx={{ width: '100%' }}>
                                            <Box fontSize={1} sx={{ float: 'right', width: '100%' }}>
                                                {this.state.email_attachments.map((a, index) => {
                                                    return (
                                                        <Flex className="fs-13" justifyContent="end">
                                                            <Flex
                                                                className="va-top"
                                                                style={{ marginTop: '8px', maxWidth: '75%' }}
                                                            >
                                                                <Box className="ellipsis">{a.filename}</Box>
                                                                <Box ml={1} style={{ color: '#999', flexShrink: 0 }}>
                                                                    ({helpers.humanFileSize(a.filesize)})
                                                                </Box>
                                                            </Flex>
                                                            <Box sx={{ flexShrink: 0 }}>
                                                                <Button
                                                                    type="button"
                                                                    variant="transparent-icon"
                                                                    ml={2}
                                                                    onClick={() => {
                                                                        let { email_attachments } = this.state;
                                                                        email_attachments.splice(index, 1);
                                                                        this.setState({ email_attachments });
                                                                    }}
                                                                >
                                                                    <FiXCircle />
                                                                </Button>
                                                            </Box>
                                                        </Flex>
                                                    );
                                                })}
                                            </Box>
                                            {this.state.email_attachment_isUploading == true && (
                                                <Box mr={2}>
                                                    <LoadingIndicator relativePosition={true} />
                                                </Box>
                                            )}
                                        </Box>
                                    )}

                                    <Box sx={{ width: '100%', textAlign: 'right' }}>
                                        <Button
                                            variant="secondary-gray"
                                            mr={3}
                                            type="button"
                                            onClick={() => this.handleCloseModalSendCampaign(true)}
                                        >
                                            Cancel
                                        </Button>
                                        {/*<Button
                                            type="button"
                                            variant="secondary-gray"
                                            mr={2}
                                            onClick={() => {
                                                const email_action = 'test';
                                                this.handleModalSendCampaign_review(email_action);
                                            }}
                                        >
                                            <FiSend /> Send test email
                                        </Button>*/}

                                        <Box sx={{ position: 'relative', display: 'inline-block' }}>
                                            <Button
                                                variant="secondary-gray"
                                                mr={3}
                                                onClick={() => {
                                                    this.setState({ showEmailCampaignMoreDropdown: true });
                                                }}
                                            >
                                                More <FiChevronDown style={{ marginRight: 0 }} />
                                            </Button>
                                            {this.state.showEmailCampaignMoreDropdown == true && (
                                                <NiceDropdown
                                                    positionTop="auto"
                                                    positionBottom={'38px'}
                                                    positionLeft={'-1px'}
                                                    onClose={() => {
                                                        this.setState({ showEmailCampaignMoreDropdown: false });
                                                    }}
                                                    items={mass_email_actions}
                                                />
                                            )}
                                        </Box>
                                        <Button
                                            type="submit"
                                            sx={{ marginRight: 0 }}
                                            onClick={() => {
                                                const email_action = 'send';
                                                this.handleModalSendCampaign_review(email_action);
                                            }}
                                            disabled={this.state.isSendingCampaign || this.isEmailSendingDisabled()}
                                        >
                                            <FiSend /> {this.generateSendToNumberPeople()}
                                        </Button>
                                    </Box>
                                </Flex>
                            )}

                            {this.state.showModalSendCampaign_editMode && (
                                <div style={{ textAlign: 'right', width: '100%' }}>
                                    <Button
                                        type="button"
                                        variant="secondary-gray"
                                        mr={3}
                                        onClick={() => {
                                            this.setState({ showModalSendCampaign_editMode: false });
                                        }}
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        type="submit"
                                        sx={{ marginRight: 0 }}
                                        onClick={() => {
                                            const email_action = 'preview';
                                            this.handleModalSendCampaign_review(email_action);
                                            this.setState({ showModalSendCampaign_editMode: false });
                                        }}
                                    >
                                        <FiSave /> Save and continue
                                    </Button>
                                </div>
                            )}
                        </Flex>
                    </ReactModal>
                    <ReactModal
                        isOpen={this.state.showModalParticipantCreate}
                        shouldCloseOnOverlayClick
                        onRequestClose={this.handleCloseModalParticipantCreate}
                        style={{ content: { width: '510px' } }}
                        overflow="visible"
                    >
                        <CreateParticipant
                            study_id={this.state.study.id}
                            onCreate={this.onNewPerson}
                            onClose={this.handleCloseModalParticipantCreate}
                            onUpdatePanels={this.onUpdatePanels}
                            panels={this.state.panels}
                        />
                    </ReactModal>

                    <NiceModal
                        isOpen={this.state.showModalScheduleSend}
                        onRequestClose={() => this.setState({ showModalScheduleSend: false })}
                        style={{ content: { width: 'auto', maxWidth: '1100px' } }}
                        title="Schedule to send"
                    >
                        <Flex>
                            <Box>
                                <DatePicker
                                    inline
                                    className={`theme-input`}
                                    style={{ width: '100%' }}
                                    showTimeInput
                                    onChange={date => {
                                        let { sendCampaign } = this.state;
                                        sendCampaign.send_when = date;
                                        this.setState({ sendCampaign });
                                    }}
                                    selected={this.state.sendCampaign.send_when}
                                />
                            </Box>
                        </Flex>
                        <Box className="modal-actions">
                            <Button
                                type="button"
                                variant="secondary-gray"
                                mr={3}
                                onClick={() => {
                                    let { sendCampaign } = this.state;
                                    sendCampaign.send_when = null;
                                    this.setState({ sendCampaign });

                                    this.setState({ showModalScheduleSend: false });
                                }}
                            >
                                Cancel
                            </Button>

                            <Button
                                type="submit"
                                variant="primary"
                                sx={{ marginRight: 0 }}
                                disabled={this.state.isSendingCampaign == true || this.isEmailSendingDisabled()}
                                onClick={() => {
                                    const email_action = 'send';
                                    this.handleModalSendCampaign_review(email_action);
                                }}
                            >
                                <FiCalendar /> {this.state.isSendingCampaign == true ? 'Scheduling...' : 'Schedule'}
                            </Button>
                        </Box>
                    </NiceModal>

                    {this.state.study.incentive != null && (
                        <PaymentModal
                            title="Bulk Pay"
                            isOpen={this.state.showModalBulkPay}
                            auth={this.props.auth}
                            onClose={() => this.setState({ showModalBulkPay: false })}
                            onPay={data => this.onBulkPayConfirm(data)}
                            payees={this.state.bulk_pay_people}
                            study={this.state.study}
                            defaultValues={{
                                amount: this.state.study.incentive
                            }}
                        />
                    )}

                    <NiceModal
                        isOpen={this.state.showModalBulkEditSp}
                        shouldCloseOnOverlayClick
                        onRequestClose={() => this.setState({ showModalBulkEditSp: false })}
                        title="Bulk edit respondents"
                        overflow="visible"
                    >
                        <form onSubmit={this.onProcessBulkEdit}>
                            <Box>
                                <Label>Field to update</Label>

                                <Box sx={{ position: 'relative' }}>
                                    <Button
                                        type="button"
                                        variant="secondary-gray"
                                        onClick={() => this.setState({ showBulkEditFieldsDropdown: true })}
                                    >
                                        {this.state.bulk_edit_field.id
                                            ? this.state.bulk_edit_field.title
                                            : 'Select a field'}{' '}
                                        <FiChevronDown style={{ marginRight: 0 }} />
                                    </Button>
                                    {this.state.showBulkEditFieldsDropdown && (
                                        <NiceDropdown
                                            showSearch
                                            onClose={() => this.setState({ showBulkEditFieldsDropdown: false })}
                                            items={this.getBulkEditableFields()}
                                            onChange={id => {
                                                const chosen_bulk_edit_field = this.getBulkEditableFields().find(
                                                    ac => ac.id == id
                                                );
                                                //console.log('chosen_bulk_edit_field', chosen_bulk_edit_field)
                                                this.setState({ bulk_edit_field: chosen_bulk_edit_field });
                                            }}
                                        />
                                    )}
                                </Box>

                                {this.renderBulkEditValueChanger()}
                            </Box>
                            <Box className="modal-actions">
                                <Button
                                    type="button"
                                    variant="secondary-gray"
                                    mr={3}
                                    onClick={() => {
                                        this.setState({
                                            showModalBulkEditSp: false
                                        });
                                    }}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    type="submit"
                                    variant="primary"
                                    sx={{ marginRight: 0 }}
                                    disabled={!this.state.bulk_edit_field.id}
                                >
                                    Update respondents
                                </Button>
                            </Box>
                        </form>
                    </NiceModal>

                    <NiceModal
                        isOpen={this.state.showModalDscoutMissions}
                        shouldCloseOnOverlayClick
                        onRequestClose={() => this.setState({ showModalDscoutMissions: false })}
                        title="Invite to a dscout mission"
                        overflow="visible"
                    >
                        <Box>{this.renderDscoutMissions()}</Box>
                        <Box className="modal-actions">
                            <Button
                                type="button"
                                variant="secondary-gray"
                                mr={3}
                                onClick={() => {
                                    this.setState({
                                        showModalDscoutMissions: false
                                    });
                                }}
                            >
                                Cancel
                            </Button>

                            <Button
                                type="submit"
                                variant="primary"
                                sx={{ marginRight: 0 }}
                                onClick={() => {
                                    const missionIdentifier = document.getElementById('dscout-missions').value;
                                    if (!missionIdentifier) {
                                        alert('Please select a mission');
                                        return;
                                    }
                                    const [missionType, missionId] = missionIdentifier.split(dscout_mission_delimiter);
                                    console.log(missionType, missionId);

                                    let mission = this.state.dscoutMissions[missionType].find(
                                        mission => missionId == mission.id
                                    );
                                    if (!mission) {
                                        alert('Mission not found');
                                        return;
                                    }

                                    const missionLink = mission.splashUrl;
                                    const missionName = mission.name;

                                    if (!missionLink) {
                                        alert(
                                            'Link to the mission link not found. Please reach out to support@panelfox.io'
                                        );
                                        return;
                                    }

                                    this.updateStudyIntegrations(
                                        {
                                            external_id: mission.id,
                                            integration_id: INTEGRATION_DSCOUT,
                                            metadata: {
                                                mission_type: missionType,
                                                url: missionLink,
                                                name: missionName,
                                                projectName: mission.project ? mission.project.name : ''
                                            }
                                        },
                                        study_integration_id => {
                                            this.clickSendEmail('dscout', {
                                                study_integration_id: study_integration_id,
                                                subject: 'Invite to dscout mission',
                                                templateType: EMAIL_TEMPLATE_TYPE.BLOCKS,
                                                content: {
                                                    time: 1690835481429,
                                                    blocks: [
                                                        {
                                                            id: 'O4u99TNfJ0',
                                                            type: 'paragraph',
                                                            data: { text: 'Hello {First Name},' }
                                                        },
                                                        {
                                                            id: 'XeyyY4ynrD',
                                                            type: 'paragraph',
                                                            data: { text: 'We have a new research study:<br>' }
                                                        },
                                                        {
                                                            id: 'OkTVrLtn6A',
                                                            type: 'dscoutMissionButton',
                                                            data: { buttonText: 'Go to activity' }
                                                        },
                                                        { id: '49khf1fXlx', type: 'paragraph', data: { text: '' } },
                                                        {
                                                            id: '9Uvk98aARS',
                                                            type: 'paragraph',
                                                            data: { text: 'Thank you!<br>' }
                                                        }
                                                    ],
                                                    version: '2.25.0'
                                                }
                                            });
                                        }
                                    );
                                    this.setState({ showModalDscoutMissions: false });
                                }}
                            >
                                Continue
                            </Button>
                        </Box>
                    </NiceModal>

                    <ReactModal
                        isOpen={this.state.showModalEditTabs}
                        shouldCloseOnOverlayClick
                        onRequestClose={() => this.setState({ showModalEditTabs: false })}
                    >
                        <Box m={4} fontSize={1}>
                            <H1 style={{ marginBottom: '16px' }}>Edit Tabs</H1>
                            <Flex mb={3} sx={{ gridGap: 3 }}>
                                <Box width={7 / 10}>Title</Box>
                                <Box width={2 / 10}>Order</Box>
                                <Box width={1 / 10}>Delete</Box>
                            </Flex>
                            {this.state.study.study_states &&
                                this.state.study.study_states
                                    .sort((a, b) => a.order - b.order)
                                    .map(ss => {
                                        const num_tot_ss = this.state.study.study_states.length;
                                        let order_options = [];
                                        for (let i = 1; i <= num_tot_ss; i++) {
                                            order_options.push(i);
                                        }

                                        return (
                                            <Flex mb={3} sx={{ gridGap: 3 }}>
                                                <Box width={7 / 10}>
                                                    <Input
                                                        type="text"
                                                        mr={2}
                                                        value={ss.title}
                                                        name="tab_title"
                                                        onChange={e => this.onTabChange(e, ss)}
                                                    />
                                                </Box>
                                                <Box width={2 / 10}>
                                                    <Select
                                                        value={ss.order}
                                                        name="tab_order"
                                                        onChange={e => this.onTabChange(e, ss)}
                                                    >
                                                        {order_options.map(oo => {
                                                            return <option value={oo}>{oo}</option>;
                                                        })}
                                                    </Select>
                                                </Box>
                                                <Box width={1 / 10}>
                                                    {ss.type != 'candidates' && (
                                                        <Button
                                                            type="button"
                                                            variant="tertiary-icon"
                                                            onClick={e => this.onTabDelete(ss)}
                                                        >
                                                            <FiMinusCircle />
                                                        </Button>
                                                    )}
                                                </Box>
                                            </Flex>
                                        );
                                    })}
                        </Box>
                    </ReactModal>

                    <NiceModal
                        isOpen={this.state.showModalExportCsv}
                        shouldCloseOnOverlayClick
                        onRequestClose={e => {
                            this.setState({ showModalExportCsv: false });
                        }}
                        title="Which columns would you like to export?"
                        style={{ content: { width: '600px' } }}
                    >
                        <Box>
                            <Box mb={4}>
                                {this.state.study.screeners.map(screener => {
                                    let screener_q_list = [];
                                    screener_q_list.push(
                                        <Box mb={'4px'}>
                                            <Label className="bold text-primary" mb={0}>
                                                Screener Title: {screener.title}
                                            </Label>
                                            <Button
                                                variant="link"
                                                type="button"
                                                className="bold"
                                                mr={3}
                                                onClick={e => {
                                                    // remove all checkboxes here from the exclude
                                                    let { export_csv_checkboxes_exclude } = this.state;
                                                    const els = document.getElementsByClassName(
                                                        `screener_id_question_${screener.id}`
                                                    );
                                                    Array.prototype.forEach.call(els, function(el) {
                                                        delete export_csv_checkboxes_exclude[
                                                            el.dataset.question_field_name
                                                        ];
                                                    });
                                                    this.setState({ export_csv_checkboxes_exclude });
                                                    this.setCachedCsvExportScreenerColumns(
                                                        export_csv_checkboxes_exclude
                                                    );
                                                }}
                                            >
                                                Select All
                                            </Button>
                                            <Button
                                                variant="link"
                                                type="button"
                                                className="red bold"
                                                onClick={e => {
                                                    // add all checkboxes here to the exclude
                                                    let { export_csv_checkboxes_exclude } = this.state;
                                                    const els = document.getElementsByClassName(
                                                        `screener_id_question_${screener.id}`
                                                    );
                                                    Array.prototype.forEach.call(els, function(el) {
                                                        export_csv_checkboxes_exclude[
                                                            el.dataset.question_field_name
                                                        ] = true;
                                                    });
                                                    this.setState({ export_csv_checkboxes_exclude });
                                                    this.setCachedCsvExportScreenerColumns(
                                                        export_csv_checkboxes_exclude
                                                    );
                                                }}
                                            >
                                                Deselect All
                                            </Button>
                                        </Box>
                                    );

                                    helpersStudy.getScreenerQuestions(screener).map(q => {
                                        if (!q.static) {
                                            screener_q_list.push(
                                                <Box mb={1} style={{ display: 'block' }}>
                                                    <Label
                                                        className="for-checkbox ellipsis"
                                                        style={{ display: 'block' }}
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            className={`screener_id_question_${screener.id}`}
                                                            name="export_csv_checkboxes_exclude"
                                                            data-question_field_name={q.field_name}
                                                            style={{ margin: '4px 2px 0 0' }}
                                                            checked={
                                                                !this.state.export_csv_checkboxes_exclude.hasOwnProperty(
                                                                    q.field_name
                                                                )
                                                            }
                                                            onClick={e => {
                                                                let { export_csv_checkboxes_exclude } = this.state;
                                                                if (e.target.checked) {
                                                                    delete export_csv_checkboxes_exclude[q.field_name];
                                                                } else {
                                                                    export_csv_checkboxes_exclude[q.field_name] = true;
                                                                }
                                                                this.setState({ export_csv_checkboxes_exclude });
                                                                this.setCachedCsvExportScreenerColumns(
                                                                    export_csv_checkboxes_exclude
                                                                );
                                                            }}
                                                        />{' '}
                                                        {helpers.removeHtml(q.label)}
                                                    </Label>
                                                </Box>
                                            );
                                        }
                                    });

                                    return (
                                        <Box>
                                            {screener_q_list}

                                            <br />
                                            <hr />
                                            <br />
                                        </Box>
                                    );
                                })}
                                <Box>
                                    <Box mb={'4px'}>
                                        <Label className="bold text-primary" mb={0}>
                                            Panelist Properties{' '}
                                            {helpers.newFeatureTag(moment('10-28-2021', 'MM-DD-YYYY'), {
                                                margin: '1px 0 0 8px'
                                            })}
                                        </Label>
                                        <Button
                                            variant="link"
                                            type="button"
                                            className="bold"
                                            mr={3}
                                            onClick={e => {
                                                // remove all checkboxes here from the exclude
                                                let { export_csv_custom_columns_include } = this.state;
                                                const els = document.getElementsByClassName(
                                                    `csv_export_custom_columns`
                                                );
                                                Array.prototype.forEach.call(els, function(el) {
                                                    export_csv_custom_columns_include[
                                                        el.dataset.custom_column_id
                                                    ] = true;
                                                });
                                                this.setState({ export_csv_custom_columns_include });
                                                this.setCachedCsvExportPanelColumns(export_csv_custom_columns_include);
                                            }}
                                        >
                                            Select All
                                        </Button>
                                        <Button
                                            variant="link"
                                            type="button"
                                            className="red bold"
                                            onClick={e => {
                                                // add all checkboxes here to the exclude
                                                let { export_csv_custom_columns_include } = this.state;
                                                const els = document.getElementsByClassName(
                                                    `csv_export_custom_columns`
                                                );
                                                Array.prototype.forEach.call(els, function(el) {
                                                    delete export_csv_custom_columns_include[
                                                        el.dataset.custom_column_id
                                                    ];
                                                });
                                                this.setState({ export_csv_custom_columns_include });
                                                this.setCachedCsvExportPanelColumns(export_csv_custom_columns_include);
                                            }}
                                        >
                                            Deselect All
                                        </Button>
                                    </Box>
                                    <Box>
                                        {this.state.panel_columns.map(pc => {
                                            return (
                                                <Box mb={1} style={{ display: 'block' }}>
                                                    <Label
                                                        className="for-checkbox ellipsis"
                                                        style={{ display: 'block' }}
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            className={`csv_export_custom_columns`}
                                                            name="export_csv_custom_columns_include"
                                                            data-custom_column_id={pc.id}
                                                            style={{ margin: '4px 2px 0 0' }}
                                                            checked={this.state.export_csv_custom_columns_include.hasOwnProperty(
                                                                pc.id
                                                            )}
                                                            onClick={e => {
                                                                let { export_csv_custom_columns_include } = this.state;
                                                                if (e.target.checked) {
                                                                    export_csv_custom_columns_include[pc.id] = true;
                                                                } else {
                                                                    delete export_csv_custom_columns_include[pc.id];
                                                                }
                                                                console.log(export_csv_custom_columns_include);
                                                                this.setState({ export_csv_custom_columns_include });
                                                                this.setCachedCsvExportPanelColumns(
                                                                    export_csv_custom_columns_include
                                                                );
                                                            }}
                                                        />{' '}
                                                        {pc.title}
                                                    </Label>
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Flex className="modal-actions modal-actions-sticky" sx={{ justifyContent: 'space-between' }}>
                            <Box>{this.renderBulkDownload()}</Box>
                            <Box>
                                <Button
                                    type="button"
                                    variant="secondary-gray"
                                    mr={3}
                                    onClick={() => {
                                        this.setState({ showModalExportCsv: false });
                                    }}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    type="submit"
                                    variant="primary"
                                    sx={{ marginRight: 0 }}
                                    onClick={() => {
                                        const export_csv = true;
                                        this.getStudyPeople(this.state.segment, 1, export_csv);
                                        this.setState({ showModalExportCsv: false });
                                    }}
                                >
                                    Export CSV
                                </Button>
                            </Box>
                        </Flex>
                    </NiceModal>

                    <ReactModal
                        isOpen={this.state.showModalStudyAttributeIdSettings}
                        shouldCloseOnOverlayClick
                        onRequestClose={this.handleCloseModalStudyAttributeSettings}
                    >
                        <Box m={4} fontSize={1}>
                            <H1 style={{ marginBottom: '16px' }}>Edit Custom Attribute</H1>
                            something
                        </Box>
                    </ReactModal>

                    <NiceModal
                        isOpen={this.state.showModalSMSMessage}
                        shouldCloseOnOverlayClick
                        onRequestClose={this.handleCloseModalSMSMessage}
                        title="Send SMS"
                    >
                        <form
                            onSubmit={e => {
                                e.preventDefault();
                                this.handleModalSendSms('send');
                            }}
                        >
                            <Box>
                                <VariablesHelper
                                    study={this.state.study}
                                    templateType={this.state.sendCampaign.templateType}
                                />
                                <Label mt={3}>Message</Label>
                                <TextAreaAutosize
                                    name="sms-campaign-content"
                                    value={this.state.smsCampaign_content}
                                    onChange={e => {
                                        this.setState({ smsCampaign_content: e.target.value });
                                    }}
                                    style={{ width: '100%', height: '200px' }}
                                    className="theme-input"
                                    minRows={4}
                                    autoFocus
                                ></TextAreaAutosize>
                            </Box>
                            <Box className="modal-actions">
                                <Button
                                    type="button"
                                    variant="secondary-gray"
                                    mr={3}
                                    onClick={this.handleCloseModalSMSMessage}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    type="submit"
                                    variant="primary"
                                    mr={0}
                                    disabled={this.state.isSendingSmsCampaign}
                                >
                                    <FiSend /> {this.generateSendToNumberPeople()}
                                </Button>
                            </Box>
                        </form>
                    </NiceModal>

                    <SelectModal
                        type="study"
                        title={`Assign to user`}
                        //icon={<FiLayers />}
                        items={select_modal_assign_users_list}
                        isOpen={this.state.showModalAssignUser}
                        isLoading={false}
                        onRequestClose={() => {
                            this.setState({ showModalAssignUser: false });
                        }}
                        onSelect={this.onSubmitAssignUserToPerson.bind(this)}
                        //onCreate={this.onSubmitCreateStudyAddPeople.bind(this)}
                    />

                    <ReactModal
                        isOpen={this.state.showModalDataViz}
                        shouldCloseOnOverlayClick
                        onRequestClose={() => {
                            this.setState({ showModalDataViz: false });
                        }}
                        //style={{ content: { maxWidth: '320px' } }}
                    >
                        <Box m={4}>
                            <DataViz study_id={this.props.match.params.id} screener_id={this.state.datavizScreenerId} />
                        </Box>
                    </ReactModal>

                    <ReactModal
                        isOpen={this.state.showModalEmailCampaignSuccess}
                        shouldCloseOnOverlayClick
                        onRequestClose={() => {
                            this.setState({ showModalEmailCampaignSuccess: false });
                        }}
                        style={{ content: { maxWidth: '320px' } }}
                    >
                        <Box
                            p={4}
                            fontSize={1}
                            sx={{
                                overflow: 'hidden',
                                position: 'relative',
                                textAlign: 'center',
                                background: 'rgba(98,0,255,0.1)'
                            }}
                        >
                            <img
                                className="shaking"
                                src={'/paper-plane-2563.svg'}
                                style={{ fill: 'red', width: '90px', margin: '28px 0px 36px' }}
                            />
                            <div className="clouds"></div>
                        </Box>

                        <Box m={4} style={{ textAlign: 'center' }}>
                            <Box mt={0} style={{ fontSize: '24px', fontWeight: 500 }}>
                                Woo hoo!
                            </Box>
                            <Box fontSize={1} sx={{ color: '#777' }}>
                                Your emails are flying into inboxes.
                            </Box>
                            <Box mb={4}>
                                {this.state.showModalEmailCampaignSuccess_showlink == true ? (
                                    this.state.showModalEmailCampaignSuccess_message
                                ) : (
                                    <Button
                                        type="button"
                                        variant="tertiary"
                                        mr={0}
                                        mt={3}
                                        onClick={e => {
                                            this.setState({ showModalEmailCampaignSuccess_showlink: true });
                                            analytics.track('study-mass_email-getSocialLink');
                                        }}
                                    >
                                        Get e-mail link
                                    </Button>
                                )}
                            </Box>
                            <Flex style={{ gap: '12px' }}>
                                <Box flex={1}>
                                    <a href={`/studies/${this.state.study.id}/emails`}>
                                        <Button type="button" variant="secondary-gray" height="40px" width={1} px={0}>
                                            View Reports
                                        </Button>
                                    </a>
                                </Box>
                                <Box flex={1}>
                                    <Button
                                        type="button"
                                        variant="primary"
                                        height="40px"
                                        width={1}
                                        onClick={() => {
                                            this.setState({ showModalEmailCampaignSuccess: false });
                                        }}
                                    >
                                        Done
                                    </Button>
                                </Box>
                            </Flex>
                        </Box>
                    </ReactModal>

                    {this.state.study && this.state.screenerFilters && this.state.showScreenerFilters && (
                        <SideOverlay
                            onClose={() => {
                                this.setState({ showScreenerFilters: false });
                            }}
                        >
                            <StudyFilters
                                filtersOnStudyPage={true}
                                study={this.state.study}
                                panel_columns={this.state.panel_columns}
                                users={this.state.account_users.map(au => au.user)}
                                availability={this.state.study_availability_array}
                                segment={this.state.segment}
                                screenerFilters={this.state.screenerFilters}
                                onScreenerFiltersChange={sf => {
                                    this.setState({ screenerFilters: sf }, () => {
                                        this.getStudyPeople(this.state.segment);
                                    });
                                }}
                                onScreenerFiltersSave={sf => {
                                    this.updateStudyState(this.state.segment, sf);
                                }}
                                onScreenerFiltersSaveAsNew={sf => {
                                    this.onBoardColumnNew(sf);
                                }}
                                onClose={() => {}}
                                onScreenerFiltersDelete={() => {
                                    this.onTabDelete(this.state.segment);
                                }}
                                onScreenerFiltersRename={() => {
                                    const ss_id = this.state.segment;

                                    const study_state = this.state.study.study_states.find(ss => ss.id == ss_id);
                                    if (study_state) {
                                        const new_tab_title = prompt('What is the name?', study_state.title);
                                        if (new_tab_title) {
                                            this.onTabChange(
                                                { target: { name: 'tab_title', value: new_tab_title } },
                                                study_state
                                            );
                                        }
                                    }
                                }}
                            />
                        </SideOverlay>
                    )}
                    <ReactCanvasConfetti
                        refConfetti={this.getInstance}
                        style={{
                            position: 'fixed',
                            pointerEvents: 'none',
                            width: '100%',
                            height: '100%',
                            top: 0,
                            left: 0,
                            zIndex: 99999
                        }}
                    />
                </AppPageWrapper>
                <ShareStudyPeopleModal
                    sourceStudyId={this.state.study.id}
                    isOpen={!!this.state.shareStudyPeopleFilterObject}
                    onClose={() => this.setState({ shareStudyPeopleFilterObject: null })}
                    filterObject={this.state.shareStudyPeopleFilterObject}
                    title={this.state.shareStudyPeopleTitle}
                />
            </Flex>
        );
    }
}

export default withRouter(withTheme(withToastManager(StudyPage)));

/* this.state.showStudyOnboarding && (
                                    <NiceWrapper
                                        style={{
                                            padding: '32px',
                                            margin: '12px 0 32px 32px',
                                            minWidth: 'min-content',
                                            overflow: 'scroll'
                                        }}
                                    >
                                        <Flex
                                            style={{
                                                height: '100%',
                                                justifyContent: 'space-between',
                                                flexDirection: 'column'
                                            }}
                                        >
                                            <Box>
                                                <H1>
                                                    <FiSun
                                                        style={{
                                                            color: 'rgba(98,0,255,1)',
                                                            fontSize: '22px'
                                                        }}
                                                    />{' '}
                                                    Next steps
                                                </H1>

                                                <Box
                                                    sx={{
                                                        width: '240px',
                                                        color: 'black',
                                                        fontWeight: 500,
                                                        fontSize: '15px'
                                                    }}
                                                    p={3}
                                                >
                                                    <ol className="onboarding">
                                                        <li
                                                            className={
                                                                this.state.study.study_people_count
                                                                    ? 'onboarding-item-done'
                                                                    : 'onboarding-item-todo'
                                                            }
                                                        >
                                                            Add candidates in the first column
                                                        </li>
                                                        {this.checkIfStudyStateToggled('screen') && (
                                                            <li
                                                                className={
                                                                    screener_has_data
                                                                        ? 'onboarding-item-done'
                                                                        : 'onboarding-item-todo'
                                                                }
                                                            >
                                                                Add questions to the{' '}
                                                                <Link to={`/studies/${this.state.study.id}/recruit`}>
                                                                    Screener
                                                                </Link>
                                                            </li>
                                                        )}
                                                        <li
                                                            className={
                                                                helpers.isOnboardingTaskComplete(
                                                                    this.props.auth.user,
                                                                    `study-scheduling-${this.state.study.id}`
                                                                )
                                                                    ? 'onboarding-item-done'
                                                                    : 'onboarding-item-todo'
                                                            }
                                                        >
                                                            Verify your calendar availability in{' '}
                                                            <Link to={`/studies/${this.state.study.id}/scheduling`}>
                                                                Scheduling
                                                            </Link>
                                                        </li>
                                                        <li
                                                            className={
                                                                helpers.isOnboardingTaskComplete(
                                                                    this.props.auth.user,
                                                                    `study-automation-${this.state.study.id}`
                                                                )
                                                                    ? 'onboarding-item-done'
                                                                    : 'onboarding-item-todo'
                                                            }
                                                        >
                                                            Move cards into an Automation column
                                                        </li>
                                                    </ol>

                                                    {this.state.study.study_people_count &&
                                                    (!this.checkIfStudyStateToggled('screen') ||
                                                        (this.checkIfStudyStateToggled('screen') &&
                                                            screener_has_data)) &&
                                                    helpers.isOnboardingTaskComplete(
                                                        this.props.auth.user,
                                                        `study-scheduling-${this.state.study.id}`
                                                    ) &&
                                                    helpers.isOnboardingTaskComplete(
                                                        this.props.auth.user,
                                                        `study-automation-${this.state.study.id}`
                                                    ) ? (
                                                        <div style={{ textAlign: 'center', marginTop: '32px' }}>
                                                            <H1>
                                                                <FiCheckCircle
                                                                    style={{
                                                                        color: 'rgb(98,0,255)',
                                                                        fontSize: '22px'
                                                                    }}
                                                                />{' '}
                                                                <span style={{ color: 'rgb(98,0,255)' }}>Done</span>
                                                            </H1>
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}
                                                </Box>
                                            </Box>
                                            <Box>
                                                <Flex justifyContent="space-between">
                                                    <Box>
                                                        <Button
                                                            variant="tertiary"
                                                            mt={4}
                                                            width="100%"
                                                            onClick={() =>
                                                                this.setState({ showStudyOnboarding: false })
                                                            }
                                                        >
                                                            Hide
                                                        </Button>
                                                    </Box>
                                                    <Box>
                                                        <Button
                                                            variant="transparent"
                                                            mt={4}
                                                            width="100%"
                                                            onClick={() => {
                                                                services.completeOnboardingTask('study');
                                                                this.setState({ showStudyOnboarding: false });
                                                            }}
                                                        >
                                                            Do not show again
                                                        </Button>
                                                    </Box>
                                                </Flex>
                                            </Box>
                                        </Flex>
                                    </NiceWrapper>
                                ) */
